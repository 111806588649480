/* eslint max-lines: "off" */
import React, {useEffect, useState, useMemo} from 'react';
import Cookies from 'js-cookie';
import {shallowEqual, useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Translate from 'i18n-react';
import {Helmet} from 'react-helmet';
import {translate} from '../../services';
import {pageUpdate} from '../../actions/pageSetup';
import {isEmpty, isNotUndefined, objectIsEmpty, isUndefined} from '../../utils/validation';
import {
  deriveHomeGoodTypeGeneric, getAfterpayAvailability, productSizeHomeGood, pdpSelectedHomeGoodSize,
  selectedHomeGoodFabric
} from '../../utils/stateToPropsHelpers';
import {ecommValueUpdatedEvent, cartContentEvent} from '../../utils/dataLayer';
import {getHomeGoodDepartment} from '../../utils/orderItemData';
import {removeDuplicateNonHelmetElements} from '../../utils/headHelpers';
import {designDisplayTitle, homeGoodDesignTitle} from '../../utils/tags';
import {getWallpaperThumbnail, isOutOfStock} from '../../utils/wallpaper';
import {productTypeAbbreviatedShape} from '../../shapes/products';
import {MAX_NUMBER_OF_TAGS} from '../../constants/Design';
import {DATA_ERROR_NO_SKU, DATA_HOME_GOOD} from '../../constants/DataLayer';
import {GUEST, REGISTERED, UNAUTHENTICATED, registeredUser} from '../../constants/userTypes';
import {INSERT, SIZE_STANDARD, STYLE_STANDARD} from '../../constants/Codes';
import {SPOONFLOWER} from '../../constants/Common';
import {HOME_GOOD_ADDITION_COVER_ONLY} from '../../constants/Parameters';
import ProductForm from '../../components/Product/ProductForm/ProductForm';
import Loading from '../../components/Reusable/Loading/Loading';
import ProductInfoGridContainer from './ProductInfoGridContainer';
import ModalContainer from '../Modal/ModalContainer';
import FabricShopContainer from '../Fabric/FabricShopContainer';
import useApiHost from '../../entities/pageSetup/apiHosts/useApiHost';
import {ApiHostsEnum} from '../../entities/pageSetup/apiHosts';
import useOrigin from '../../entities/pageSetup/origin/useOrigin';
import useCurrentPath from '../../entities/pageSetup/path/useCurrentPath';
import usePrevious from '../../redux/hooks/usePreviousValue';
import fetchPricingForHomeGoodUsingSubstrateCode from './api/fetchHomeGoodData';
import fetchColorways, {completeColorways} from './api/fetchColorways';
import fetchUserFavoriteDesigns from '../Design/api/fetchUserFavoriteDesigns';
import {ErrorBoundary} from '../../components/Reusable/ErrorBoundary/ErrorBoundary';
import {cleanDesignState} from '../../actions/design';
import {cleanAddToCartState} from '../../actions/addToCart';
import {resetColorways} from '../../actions/colorways';
import {clearAllProducts} from '../../actions/allProducts';


const HomeGoodContainer = (props) => {
  const {
    setProductSize, setSubstrate, homeGoodType, queryParams, quantity, country, currency, params, measurementSystem, designName,
    designerUserName, designId, designerId, userId, setQuantity, addToCartIsFetching, reviewSummary,
    userIsPrivateBuyer, buttonContent, buttonContentIsAdded, userType, slideInModal, openFabricPreferencesModal,
    taxRate, addToCartClick, addSwatchToCartClick, productTypeAbbreviated, orderItem, designThumbnailSimple, productAvailable,
    quantityInputUpdateComplete, completingQuantityInputUpdate, swiperParams, dispatchSetAddition, colorways, selectedColorway, designSlug, viewport
  } = props;
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const [rawItemPriceAddition, setRawItemPriceAddition] = useState(0);
  const [rawItemPriceAdditionUndiscounted, setRawItemPriceAdditionUndiscounted] = useState(0);
  const [ogImage, setOgImage] = useState(0);
  const currentPath = useCurrentPath();
  const currentOrigin = useOrigin();

  const {
    locale, displayModal, modalName, sizeQueryParam, hasAdditionPossible, productPricing, additionPricing, swatchPrice, tags,
    isAvailableOnHomeGood, allowedFabrics, fabric, newSelectedProductSize, activeProductSize, homeGoodImages,
    wallpaperAvailableSubstrateThumbnail, selectedAddition, validAdditionParam, analytics, afterpayAvailability,
    showAfterpayWidget, pendingCart, turnarounds, promoName, promoEndDate, orderItemIdQueryParam, homeGoodThumbnail, isDesignFetching
  } = useSelector((state) => {
    const locale = params.pageLang; // assume that url lang is pagelang
    const tagsByLocale = isNotUndefined(state.design.tags) ? state.design.tags[locale] : [];
    const productPricing = state.addToCart.pricing;
    // If at least one of the sizes has an addition, we say the product can have additions
    const hasAdditionPossible = !!Object.keys(productPricing || {})
      .find((size) => (Object.keys(get(productPricing, `${size}.home_goods_additions`, {})).length > 0));
    const initialProps = {
      locale,
      displayModal: state.pageSetup.modal.displayModal,
      fabric: selectedHomeGoodFabric(state),
      modalName: state.pageSetup.modal.modalName,
      sizeQueryParam: pdpSelectedHomeGoodSize(queryParams, currentPath, currentOrigin),
      orderItemIdQueryParam: queryParams.order_item_id,
      hasAdditionPossible,
      productPricing,
      analytics: state.addToCart.analytics,
      additionPricing: state.addToCart.price_per,
      swatchPrice: state.addToCart.fabric_swatch_price,
      tags: !isEmpty(tagsByLocale) ? (tagsByLocale).filter((t) => (isNotUndefined(t.slug))).slice(0, MAX_NUMBER_OF_TAGS) : [],
      isAvailableOnHomeGood: state.design.for_sale_as_home_good,
      pendingCart: state.carts.pending,
      turnarounds: state.addToCart.turnarounds
    };

    if (objectIsEmpty(state.addToCart.allowed_fabrics) || objectIsEmpty(state.design.images)) {
      return initialProps;
    }

    const homeGoodTypeGeneric = deriveHomeGoodTypeGeneric(homeGoodType);
    const activeProductSize = productSizeHomeGood(state, queryParams, homeGoodTypeGeneric);
    const allowedFabrics = get(state.addToCart, 'allowed_fabrics', {});
    const validAdditions = isNotUndefined(state.addToCart.pricing?.[activeProductSize]) && Object.keys(state.addToCart.pricing[activeProductSize].home_goods_additions || []);
    const selectedProductSize = state.addToCart.productSize;
    let newSelectedProductSize;

    if (isUndefined(orderItem.id) && isUndefined(selectedProductSize) && selectedProductSize !== activeProductSize) {
      newSelectedProductSize = activeProductSize;
    }

    const productSize = activeProductSize || selectedProductSize;
    const afterpayAvailability = getAfterpayAvailability(state.addToCart, productSize);
    const showAfterpayWidget = get(afterpayAvailability, 'showAfterpay', false);
    const promoPerSize = state.addToCart.promo?.hasOwnProperty(productSize) ? state.addToCart.promo[productSize] : undefined;

    // TODO SP-15021 remove when new colorways API will be ready
    const productDimension = productSize && productPricing?.[productSize]?.size;
    const productStyle = productSize && productPricing?.[productSize] && productPricing?.[productSize]?.style;
    const homeGoodImages = state.design.images.home_good;
    const homeGoodTypeImages = homeGoodImages[homeGoodType];
    let homeGoodTypeList = [];

    isNotUndefined(homeGoodTypeImages) && Object.keys(homeGoodTypeImages).forEach((size) => {
      if (size === productDimension) {
        homeGoodTypeList = Object.keys(homeGoodTypeImages[size]);
      }
    });
    const activeHomeGoodStyle = homeGoodTypeList && homeGoodTypeList.filter((options) => (options === productStyle));

    const getImages = (homeGoodImageType) => {
      const styleNames = Object.keys(homeGoodTypeImages[productDimension][homeGoodImageType]);

      return styleNames.map((styleName) => homeGoodTypeImages[productDimension][homeGoodImageType][styleName].desktop);
    };

    const getMainImages = (activeHomeGoodStyle) => activeHomeGoodStyle.flatMap(getImages);

    const updateOgImage = (firstMainImage) => {
      if (ogImage !== firstMainImage) {
        setOgImage(firstMainImage);
      }
    };

    const mainImages = getMainImages(activeHomeGoodStyle);
    const firstMainImage = mainImages[0];

    updateOgImage(firstMainImage);

    return Object.assign(initialProps, {
      homeGoodTypeGeneric,
      allowedFabrics: allowedFabrics.fabrics,
      newSelectedProductSize,
      activeProductSize,
      promoName: promoPerSize?.name,
      promoEndDate: promoPerSize?.end_date,
      homeGoodImages,
      wallpaperAvailableSubstrateThumbnail: getWallpaperThumbnail(state.design.images.wallpaper),
      selectedAddition: state.addToCart.addition,
      validAdditionParam: validAdditions && validAdditions.includes(queryParams.addition) ? queryParams.addition : undefined,
      afterpayAvailability,
      showAfterpayWidget,
      homeGoodThumbnail: mainImages[0],
      isDesignFetching: state.design.isFetching
    });
  }, shallowEqual);

  const newCanonicalUrl = useMemo(() => (currentOrigin + currentPath), [currentOrigin, currentPath]);

  const dispatch = useDispatch();
  const dispatchPageSetup = () =>
    dispatch(pageUpdate({
      pageTitle: translate('homeGood.head.title')
    }));

  useEffect(() => {
    dispatchPageSetup();

    return () => {
      dispatch(cleanDesignState());
      dispatch(cleanAddToCartState());
      dispatch(resetColorways());
      dispatch(clearAllProducts());
    };
  }, []);

  useEffect(() => {
    dispatch(cleanDesignState());
    dispatch(cleanAddToCartState());
    dispatch(resetColorways());
  }, [homeGoodType]);

  useEffect(() => {
    if (pendingCart.pendingCartFetched) {
      window.dataLayer.push(cartContentEvent(DATA_HOME_GOOD, pendingCart));
    }
  }, [pendingCart.pricing]);

  useEffect(() => {
    const measurementCookie = Cookies.get('substrateMeasurementCookie');

    if (isNotUndefined(measurementCookie)) {
      setProductSize(measurementCookie);
      document.cookie = 'substrateMeasurementCookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC;Secure;Path=/';
    } else {
      isNotUndefined(newSelectedProductSize) && setProductSize(newSelectedProductSize);
    }
  }, [newSelectedProductSize]);

  useEffect(() => {
    isNotUndefined(validAdditionParam) && dispatchSetAddition(validAdditionParam);
  }, [validAdditionParam]);

  useEffect(() => {
    if (selectedAddition === undefined &&
      isNotUndefined(activeProductSize) &&
      isNotUndefined(productPricing?.[activeProductSize]) &&
      isNotUndefined(productPricing[activeProductSize]?.home_goods_additions)) {
      if (queryParams.item_addition === HOME_GOOD_ADDITION_COVER_ONLY) return;
      dispatchSetAddition(Object.keys(productPricing?.[activeProductSize]?.home_goods_additions)[0]);
    }

    if (!isEmpty(selectedAddition) &&
      isNotUndefined(productPricing?.[activeProductSize]) &&
      isNotUndefined(productPricing[activeProductSize].home_goods_additions?.[selectedAddition])) {
      setRawItemPriceAddition(productPricing[activeProductSize].home_goods_additions[selectedAddition].price);
      setRawItemPriceAdditionUndiscounted(productPricing[activeProductSize].home_goods_additions[selectedAddition].price_undiscounted);
    }
  }, [selectedAddition, productPricing, activeProductSize]);

  useEffect(() => {
    isNotUndefined(fabric) && setSubstrate(fabric);
  }, [fabric]);

  const previousQuantity = usePrevious(quantity);
  const previousFabric = usePrevious(fabric);
  const prevQuantityInputUpdateComplete = usePrevious(quantityInputUpdateComplete);

  useEffect(() => {
    const quantityWasChanged = isNotUndefined(prevQuantityInputUpdateComplete) &&
      prevQuantityInputUpdateComplete !== quantityInputUpdateComplete ||
      isNotUndefined(previousQuantity) &&
      previousQuantity !== quantity;
    const fabricWasChanged = isNotUndefined(previousFabric) && previousFabric !== fabric;

    if (addToCartIsFetching || orderItemIdQueryParam && pendingCart.isFetching) return;

    if (isNotUndefined(homeGoodType) && quantityInputUpdateComplete && (isUndefined(productPricing) || quantityWasChanged || fabricWasChanged)) {
      dispatch(fetchPricingForHomeGoodUsingSubstrateCode());
    }
  }, [homeGoodType, quantity, fabric, quantityInputUpdateComplete, pendingCart.isFetching, designId, productPricing, activeProductSize]);

  useEffect(() => {
    if (
      !objectIsEmpty(productPricing) && isNotUndefined(activeProductSize) && isNotUndefined(productPricing[activeProductSize])) {
      const productSkuPath = isNotUndefined(selectedAddition) && selectedAddition.includes(INSERT) ?
        `${activeProductSize}.additions.${selectedAddition}.sku` : `${activeProductSize}.sku`;
      const productSku = get(analytics, productSkuPath, DATA_ERROR_NO_SKU);

      const parentSku = get(analytics, 'parent_sku', DATA_ERROR_NO_SKU);

      const ecommValuePath = isNotUndefined(selectedAddition) && selectedAddition.includes(INSERT) ?
        `${activeProductSize}.home_goods_additions.${selectedAddition}.price` : `${activeProductSize}.price`;
      const ecommValue = get(productPricing, ecommValuePath, 0);

      const productData = {
        name: Translate.translate(`en.homeGood.${homeGoodType}`),
        id: designId,
        price: ecommValue,
        currency,
        quantity,
        userId,
        brand: designerUserName,
        category: DATA_HOME_GOOD,
        variant: Translate.translate(`en.homeGood.cart.productSize.${activeProductSize}`),
        department: getHomeGoodDepartment(homeGoodType),
        addition: selectedAddition && Translate.translate(`en.homeGood.${homeGoodType}.additions.${selectedAddition}`),
        productSku,
      };

      window.dataLayer.push(
        ecommValueUpdatedEvent(
          productSku,
          parentSku,
          ecommValue,
          currency,
          quantity,
          getHomeGoodDepartment(homeGoodType),
          userId,
          productData,
        )
      );
    }
  }, [productPricing, activeProductSize, selectedAddition]);

  useEffect(() => {
    if (designId && isNotUndefined(colorways.colorways) && isEmpty(colorways.colorways) && !colorways.isFetching) {
      dispatch(fetchColorways());
    }
  }, [designId, colorways]);

  useEffect(() => {
    if (registeredUser(userType) && designId) {
      dispatch(fetchUserFavoriteDesigns());
    }
  }, [userType, designId]);

  const reduceFabricsArray = () => allowedFabrics?.map((fabric) => Object.keys(fabric).toString());

  const fabricsArray = useMemo(() => reduceFabricsArray(), [allowedFabrics]);

  const createOutOfStockArray = () => {
    const existingHomeDecorFabrics = Object.entries(allowedFabrics || {});
    const existingHomeDecorAvailability = [];

    existingHomeDecorFabrics?.forEach((fabric) => {
      const homeDecorValues = Object.values(fabric[1]);
      const {active, oos, standby} = homeDecorValues[0];
      const name = Object.keys(fabric[1]).toString();
      const newObject = {
        name, active, oos, standby
      };

      existingHomeDecorAvailability.push(newObject);
    });

    return existingHomeDecorAvailability;
  };

  const outOfStockArray = useMemo(() => createOutOfStockArray(), [allowedFabrics]);

  // TODO SP-15021 remove when new colorways API will be ready
  const completedColorways = useMemo(() => completeColorways(colorways.colorways, selectedColorway, homeGoodThumbnail), [selectedColorway?.id, colorways]);

  if (isEmpty(designId) || isDesignFetching || isUndefined(isDesignFetching) || isEmpty(productPricing) || colorways.isFetching || isUndefined(productPricing?.[activeProductSize])) {
    return <Loading message={translate('common.loading')} />;
  }

  const addToCartRequestBody = {
    fabric,
    home_good_code: activeProductSize,
    design_id: designId,
    quantity,
    ...selectedAddition && {
      home_good_addition_codes: [selectedAddition]
    }
  };
  const addSwatchToCartRequestBody = {
    fabric,
    design_id: designId
  };
  const sizesOrStylesAreAvailable = () => {
    const sizes = [];
    const styles = [];

    productPricing && Object.keys(productPricing).forEach((productPrice) => {
      (productPricing[productPrice].size !== SIZE_STANDARD) && !sizes.includes(productPricing[productPrice].size) && sizes.push(productPricing[productPrice].size);
      (productPricing[productPrice].style !== STYLE_STANDARD) && !styles.includes(productPricing[productPrice].style) && styles.push(productPricing[productPrice].style);
    });

    return {
      sizes: !isEmpty(sizes),
      styles: !isEmpty(styles),
      stylesArray: styles
    };
  };
  const createSizeStyleMap = () => {
    const sizeStyleMap = {};

    productPricing && Object.keys(productPricing).forEach((productPrice) => {
      sizeStyleMap[productPrice] = {
        [productPricing[productPrice].size]: productPricing[productPrice].style
      };
    });

    return sizeStyleMap;
  };

  function handleSetAddition(value) {
    dispatchSetAddition(value);
  }

  function handleAddToCartClick(overrides) {
    addToCartClick(addToCartRequestBody, overrides);
  }

  function handleAddSwatchToCartClick(overrides) {
    addSwatchToCartClick(addSwatchToCartRequestBody, overrides);
  }

  const fabricDetailsHtml = {
    __html: `<ul class='circle-list x-indented'>${translate(`homeGoodFabrics.${fabric}.pdpDetails`)}</ul>`
  };
  const designTitle = designDisplayTitle(tags, designName, locale);
  const homeGoodDescription = translate('meta.homeGoodPDP.description', {
    designTitle,
    homeGoodType: translate(`homeGood.${homeGoodType}.name`),
    designer: designerUserName
  });
  const addToCartButtonTitleComplex = translate('fabric.content.addToCartTitle', {
    product: `${quantity} ${translate(`homeGood.${homeGoodType}.name`)}`
  });

  const selectedOutOfStockValues = allowedFabrics?.find((fabricType) => Object.keys(fabricType).toString() === fabric);

  const {active, oos, standby} = selectedOutOfStockValues ? Object.values(selectedOutOfStockValues)[0] : {};
  const outOfStock = isOutOfStock(active, oos, standby);

  return (
    <React.Fragment>
      <Helmet
        title={translate('titles.homeGoodPage.title', {
          designTitle: homeGoodDesignTitle(designName, homeGoodType)
        })}
        meta={[
          {
            name: 'description',
            content: homeGoodDescription
          },
          {
            property: 'og:title',
            content: translate('titles.homeGoodPage.title', {
              designTitle: designDisplayTitle(tags, designName, locale)
            })
          },
          {
            property: 'og:description',
            content: translate('meta.homeGoodPDP.description', {
              designTitle: designDisplayTitle(tags, designName, locale),
              designer: designerUserName
            })
          },
          {
            property: 'og:image',
            content: ogImage
          },
          {
            property: 'og:image:type',
            content: 'image/jpeg'
          },
          {
            property: 'og:image:width',
            content: '400'
          },
          {
            property: 'og:image:height',
            content: '400'
          },
          {
            property: 'og:site_name',
            content: SPOONFLOWER
          },
          {
            property: 'og:url',
            content: newCanonicalUrl
          }
        ]}/>
      <Helmet onChangeClientState={removeDuplicateNonHelmetElements}
        link={[
          {
            rel: 'canonical', href: newCanonicalUrl
          }
        ]}
      />
      <ErrorBoundary>
        <ProductForm
          productAvailable={productAvailable}
          productType={homeGoodType}
          homeGoodType={homeGoodType}
          hasAdditionPossible={hasAdditionPossible}
          taxRate={taxRate}
          locale={locale}
          currency={currency}
          measurementSystem={measurementSystem}
          orderItemId={orderItem.id}
          selectedSubstrate={fabric}
          outOfStock={outOfStock}
          fabricList={fabricsArray}
          productSize={activeProductSize}
          productPricingMap={productPricing}
          sizeStyleMap={createSizeStyleMap()}
          sizesAreAvailable={sizesOrStylesAreAvailable().sizes}
          stylesAreAvailable={sizesOrStylesAreAvailable().styles}
          stylesArray={sizesOrStylesAreAvailable().stylesArray}
          designId={designId}
          designName={designName}
          designerUserName={designerUserName}
          designThumbnail={designThumbnailSimple}
          tags={tags}
          userType={userType}
          productTypeAbbreviated={productTypeAbbreviated}
          userId={userId}
          quantity={quantity}
          completingQuantityInputUpdate={completingQuantityInputUpdate}
          quantityInputUpdateComplete={quantityInputUpdateComplete}
          selectedAddition={selectedAddition}
          additionPricing={additionPricing}
          setQuantity={setQuantity}
          setAddition={handleSetAddition}
          rawItemPrice={get(productPricing, `${activeProductSize}.price`, 0)}
          rawItemPriceUndiscounted={get(productPricing, `${activeProductSize}.price_undiscounted`, 0)}
          rawItemPriceAddition={rawItemPriceAddition}
          rawItemPriceAdditionUndiscounted={rawItemPriceAdditionUndiscounted}
          discountsApplied={get(productPricing, `${activeProductSize}.discounts_applied`)}
          addToCartIsFetching={addToCartIsFetching}
          setProductSize={setProductSize}
          addToCartClick={handleAddToCartClick}
          addSwatchToCartClick={handleAddSwatchToCartClick}
          testSwatchPrice={swatchPrice}
          buttonContent={buttonContent}
          buttonContentIsAdded={buttonContentIsAdded}
          reviewSummary={reviewSummary}
          userIsPrivateBuyer={userIsPrivateBuyer}
          isAvailableOnProduct={isAvailableOnHomeGood}
          userIsDesigner={designerId === userId}
          designImages={homeGoodImages}
          productFormTitle={translate(`homeGood.${homeGoodType}.name`)}
          addToCartButtonTitle={addToCartButtonTitleComplex}
          sizeQueryParam={sizeQueryParam}
          openFabricPreferencesModal={openFabricPreferencesModal}
          afterpayAvailability={afterpayAvailability}
          showAfterpayWidget={showAfterpayWidget}
          country={country}
          imagesHost={imagesHost}
          turnaroundTime={turnarounds}
          swiperParams={swiperParams}
          promoName={promoName}
          promoEndDate={promoEndDate}
          colorways={completedColorways}
          selectedColorway={selectedColorway}
          designSlug={designSlug}
          routingPath={homeGoodType}
          viewport={viewport}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <ProductInfoGridContainer
          selectedSubstrate={fabric}
          productType={homeGoodType}
          locale={locale}
          homeGoodType={homeGoodType}
          fabricDetailsHtml={fabricDetailsHtml}
          showMeasurement={false}
          productSize={activeProductSize}
          orderItemId={orderItem.id}
          wallpaperAvailableSubstrateThumbnail={wallpaperAvailableSubstrateThumbnail}
          rawItemPrice={get(productPricing, `${activeProductSize}.price`, 0)}
          quantity={quantity}
          sizeQuery={sizeQueryParam}
        />
      </ErrorBoundary>
      {(displayModal && modalName === 'fabric-shop') &&
      <ErrorBoundary>
        <ModalContainer
          slideInModal={slideInModal}
          displayFabricsModal={displayModal}
          modalExtraWide={true}
          modalWithButton={false}
          contents={<FabricShopContainer
            params={params}
            fabricOptionsArray={fabricsArray}
            displayFabricsModal={displayModal}
            isStandardFabricModal={false}
            routingPath={homeGoodType}
            outOfStockArray={outOfStockArray}
          />}
        />
      </ErrorBoundary>}
    </React.Fragment>
  );
};

HomeGoodContainer.propTypes = {
  // from shared
  params: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
  country: PropTypes.string.isRequired,
  measurementSystem: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  orderItem: PropTypes.shape({
    id: PropTypes.number
  }),
  userId: PropTypes.number,
  userType: PropTypes.oneOf([UNAUTHENTICATED, GUEST, REGISTERED]),
  homeGoodType: PropTypes.string,
  designId: PropTypes.number.isRequired,
  designName: PropTypes.string.isRequired,
  productTypeAbbreviated: productTypeAbbreviatedShape.isRequired,
  userIsPrivateBuyer: PropTypes.bool.isRequired,
  designerUserName: PropTypes.string.isRequired,
  designerId: PropTypes.number.isRequired,
  designThumbnailSimple: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  addToCartIsFetching: PropTypes.bool.isRequired,
  reviewSummary: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  buttonContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  buttonContentIsAdded: PropTypes.bool.isRequired,
  addToCartClick: PropTypes.func.isRequired,
  addSwatchToCartClick: PropTypes.func.isRequired,
  taxRate: PropTypes.number,
  setProductSize: PropTypes.func,
  setSubstrate: PropTypes.func,
  setQuantity: PropTypes.func,
  openFabricPreferencesModal: PropTypes.func.isRequired,
  slideInModal: PropTypes.bool,
  productAvailable: PropTypes.bool.isRequired,
  completingQuantityInputUpdate: PropTypes.func,
  quantityInputUpdateComplete: PropTypes.bool,
  dispatchSetAddition: PropTypes.func.isRequired,
  swiperParams: PropTypes.object.isRequired,
  colorways: PropTypes.object,
  selectedColorway: PropTypes.object,
  designSlug: PropTypes.string,
  viewport: PropTypes.string,
};

export default HomeGoodContainer;
