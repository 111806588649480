import Translate from 'i18n-react';
import React from 'react';

import {DEFAULT_USER_IMAGE} from '../../../../constants/Assets';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import ImageUrls from '../../../../utils/design';
import AvatarImage from '../../../Reusable/Images/AvatarImage/AvatarImage';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';
import './_designer-details.scss';


export interface DesignerDetailsProps {
  designerUserName?: string;
  isMugshot?: boolean;
  designerInfoImageId?: number;
  designerInfoImageFile?: string;
  designerPublicDesigns?: number;
}

const DesignerDetails = ({designerUserName, isMugshot, designerInfoImageId, designerInfoImageFile, designerPublicDesigns}: DesignerDetailsProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const gardenHost = useApiHost(ApiHostsEnum.garden);

  const designerInformation = () => {
    const designerAvatar = isMugshot ? ImageUrls.avatarDesignerImageUrl(gardenHost, designerInfoImageId, designerInfoImageFile) :
      `${imagesHost}/${DEFAULT_USER_IMAGE}`;

    return <React.Fragment>
      <AvatarImage
        altText={designerUserName}
        imgSrc={designerAvatar}
      />
      <section className='designer-details-wrapper'>
        <p className='designer-details-heading'>
          <span className='by'>{translate('fabric.content.by')}</span>{designerUserName}
        </p>
        <p className='designer-details-text'>
          {translate('designs.designerInfo.designCount', {
            context: designerPublicDesigns
          })}
        </p>
      </section>
    </React.Fragment>;
  };
  const userProfilePath = Translate.translate('xurls.userProfile', {
    userName: designerUserName
  })?.toString() || ''; // TODO: SP-8362 Refactor or extend translate utility.

  return (
    <LinkWrapper
      className='b-designer-details'
      hrefValue={userProfilePath}
      contents={designerInformation()}
      title={translate('designs.designerInfo.designerShopTitle', {
        designer: designerUserName
      })} />
  );
};

export default DesignerDetails;
