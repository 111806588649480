import React from 'react';

import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


export interface DiscountsInfoBoxProps {
  isDesktop: boolean;
}

const DiscountsInfoBox = ({isDesktop}: DiscountsInfoBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    <InfoBox
      isDesktop={isDesktop}
      boxClass='b-trade-info-box x-icon-box'
      heading={translate('fabric.discounts.title')}
      infoText={translate('fabric.discounts.info')}
      imageUrl={`${imagesHost}/discounts-tags-icon.svg`}
      imageAlt={translate('fabric.discounts.title')}
      moreInfo={<LinkWrapper
        className='learn-more-link'
        hrefValue={translate('navigation.ourDiscounts.href')}
        title={translate('fabric.discounts.title')}
        contents={translate('fabric.discounts.learnMore')}
      />}
    />
  );
};

export default DiscountsInfoBox;
