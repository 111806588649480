import React from 'react';
import {useSelector, shallowEqual} from 'react-redux';

import FeaturedInCollection from '../../components/Product/DesignerSection/FeaturedInCollection/FeaturedInCollection';
import Loading from '../../components/Reusable/Loading/Loading';
import {ORDER_ITEM_ID} from '../../constants/Parameters';
import {ApiHostsEnum} from '../../entities/pageSetup/apiHosts';
import useApiHost from '../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../services';
import {isDesignResponse} from '../../shapes/design';
import {Locale} from '../../shapes/locale';
import {State} from '../../store/initialState';


export interface FeaturedInCollectionContainerProps {
  designFilename?: string;
  designId: number;
  locale: Locale;
  productTypeAbbreviated: string;
  productType: string;
}

const FeaturedInCollectionContainer = ({designId, designFilename, locale, productType, productTypeAbbreviated}: FeaturedInCollectionContainerProps): JSX.Element => {
  const s3host = useApiHost(ApiHostsEnum.s3);
  const gardenHost = useApiHost(ApiHostsEnum.garden);
  const stateSlice = useSelector((state: State) => {
    const featuredDesign = state.design;

    if (!isDesignResponse(featuredDesign)) {
      return;
    }

    const designerCollection = featuredDesign.designer_collection;
    const designerCollectionDesigns = designerCollection?.designs || [];
    const designerCollectionId = designerCollection?.id;
    const designerCollectionDesign = designerCollectionDesigns.slice(0, 1);
    const designerCollectionDesignId = designerCollectionDesign?.[0]?.id;
    const designerCollectionDesignName = designerCollectionDesign?.[0]?.name || '';
    const designerCollectionDesignFilename = designerCollectionDesign?.[0]?.filename || '';

    return {
      designName: featuredDesign.name,
      designThumbnail: featuredDesign.design_thumbnail,
      designerScreenName: featuredDesign.designer.screen_name,
      designTags: featuredDesign.tags,
      designerCollectionDesignId,
      designerCollectionDesignName,
      designerCollectionDesignFilename,
      designerCollectionDesigns,
      designerCollectionId,
      orderItemId: state.routing?.locationBeforeTransitions.query[ORDER_ITEM_ID]
    };
  }, shallowEqual);

  if (!stateSlice) {
    return (<Loading message={translate('common.loading')} />); // TODO: SP-8361 How do we handle error cases like this? For now, assuming that state design data has not loaded.
  }

  const {
    designName, designThumbnail, designerScreenName, designTags, designerCollectionDesignId, designerCollectionDesignName, designerCollectionDesignFilename,
    designerCollectionDesigns, designerCollectionId, orderItemId
  } = stateSlice;

  return (
    <FeaturedInCollection
      designId={designId}
      designName={designName}
      designThumbnail={designThumbnail}
      designFilename={designFilename}
      designerScreenName={designerScreenName}
      designTags={designTags}
      designerCollectionDesignId={designerCollectionDesignId}
      designerCollectionDesignName={designerCollectionDesignName}
      designerCollectionDesignFilename={designerCollectionDesignFilename}
      designerCollectionDesigns={designerCollectionDesigns}
      designerCollectionId={designerCollectionId}
      orderItemId={orderItemId}
      locale={locale}
      productTypeAbbreviated={productTypeAbbreviated}
      productType={productType}
      s3host={s3host}
      gardenHost={gardenHost}
    />
  );
};

export default FeaturedInCollectionContainer;
