export const MATERIAL_RATING_QUESTION = 'How would you rate the material?';
export const PRINT_RATING_QUESTION = 'How would you rate the print?';
export const MATERIAL_RECEIVED_QUESTION = 'What material did you receive?';
export const PROJECT_QUESTION = 'What did you make?';
export const RECOMMENDATION_QUESTION = 'Would you recommend this item to others?';
export const VOTE_UP = 'voteup';
export const VOTE_DOWN = 'votedown';
export const FIRST = 0;
export const SECOND = 1;
export const THIRD = 2;
export const FOURTH = 3;
export const FIFTH = 4;
export const MATERIAL_TYPES = 'materialTypes';
export const PROJECT_TYPES = 'projectTypes';
export const RECOMMENDATION = 'recommendation';
export const STARS = 'stars';
export const MATERIAL = 'material';
export const PRINT = 'print';
export const DESIGN_IMAGE_SIZE = '140';
export const MAX_RATING = 5;
