/* eslint max-lines: "off" */
import React, {MouseEvent, useCallback, useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';

import {modalOpen} from '../../actions/pageSetup';
import {updateSearchParamsAndSubmit} from '../../actions/searchParams';
import ProductInfoGrid from '../../components/Product/ProductInfoGrid/ProductInfoGrid';
import {ReviewInterface} from '../../components/Product/Reviews/IndividualReviewListItem/IndividualReviewListItem';
import UserSubmittedPhotosModal from '../../components/Product/UserSubmittedPhotos/UserSubmittedPhotosModal/UserSubmittedPhotosModal';
import {ErrorBoundary} from '../../components/Reusable/ErrorBoundary/ErrorBoundary';
import Loading from '../../components/Reusable/Loading/Loading';
import {SEARCH_PARAMS_RESET} from '../../constants/ActionTypes';
import {FABRIC, FABRIC_BLANK, HOME_GOOD, SOLID, WALLPAPER} from '../../constants/Codes';
import {MAX_NUMBER_OF_TAGS} from '../../constants/Design';
import {VIEWPORT_DESKTOP} from '../../constants/Viewports';
import {ApiHostsEnum} from '../../entities/pageSetup/apiHosts';
import useApiHost from '../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../entities/pageSetup/origin/useOrigin';
import {translate} from '../../services';
import {CrossSellCollection, SolidCrossSellItem} from '../../shapes/crossSell';
import {isDesignResponse} from '../../shapes/design';
import {Locale} from '../../shapes/locale';
import {MeasurementType} from '../../shapes/measurement';
import {isProductType, ProductTypeAbbreviated} from '../../shapes/products';
import {TagsProps} from '../../shapes/tags';
import {CommentPhotoData, PhotoData, ReviewPhotoData} from '../../shapes/uploadedPhotos';
import {hasUserId, UserType} from '../../shapes/user';
import {ViewportType} from '../../shapes/viewport';
import {State} from '../../store/initialState';
import ImageUrls, {SMALL_THUMBNAIL_SIZE} from '../../utils/design';
import {queryParamsOverrideUserPreferences} from '../../utils/stateToPropsHelpers';
import {isNotUndefined, objectIsEmpty} from '../../utils/validation';
import ModalContainer from '../Modal/ModalContainer';

import fetchAllProducts from './api/fetchAllProducts';


export interface ProductInfoGridContainerProps {
  productType: string; // from parent
  // eslint-disable-next-line react/no-unused-prop-types
  wallpaperType?: string; // from parent
  homeGoodType?: string; // from parent
  productSize?: string; // from parent
  selectedSubstrate: string; // from parent
  orderItemId?: number; // from parent
  hasNoUserFeedback?: boolean; // from parent
  locale: Locale; // from parent
  fabricDetailsHtml?: { __html: string } | undefined;
  showMeasurement?: boolean;
  wallpaperAvailableSubstrateThumbnail?: string;
  rawItemPrice?: number;
  quantity?: number;
  queryParams?: Record<string, string>;
  productInfoRef?: React.LegacyRef<HTMLElement> | undefined;
  crossSellBarItems?: {[key: string]: {imageSrc: string; name: string; link: string}[]};
  longFabricDescription?: string;
  longDescriptionEmphazised?: string;
  trademarkType?: string;
  handleShopClick?: (pathName?: string) => void;
  fabricPrice?: string;
  sizeQuery?: string;
  crossSellFabricDesigns?: CrossSellCollection[];
  crossSellFabricSolids?: SolidCrossSellItem[];
  solidsPricePer?: number;
  colorCode?: string;
  showOnlyProductInfo?: boolean;
  handleFaqButtonClick?: () => void;
}

export interface ProductInfoGridContainerState {
  measurementSystem: MeasurementType;
  currency: string;
  userId?: number;
  userType?: UserType;
  windowPosition?: number;
  designName?: string;
  designId?: number;
  shortDescription?: string;
  longDescription?: string;
  isAvailableOnHomeGood?: boolean;
  isAvailableOnWallpaper?: boolean;
  isAvailableOnFabric?: boolean;
  designFileName?: string;
  designThumbnail?: string;
  tags?: TagsProps;
  designerUserName?: string;
  designerPublicDesigns?: number;
  designerDescription?: string;
  designerId?: number;
  viewport: ViewportType;
  displayModal?: boolean;
  modalName?: string;
  disableUserFeedback?: boolean;
  reviews: {
    reviews: [];
  };
  isMugshot?: boolean;
  designerInfoImageId?: number;
  designerInfoImageFile?: string;
  isRotatedFatQuarter?: boolean;
}

const ProductInfoGridContainer = ({
  locale, hasNoUserFeedback, homeGoodType, productSize, orderItemId, selectedSubstrate, showMeasurement,
  fabricDetailsHtml, productType, wallpaperAvailableSubstrateThumbnail, rawItemPrice, quantity, productInfoRef,
  crossSellBarItems, longFabricDescription, longDescriptionEmphazised, handleShopClick, fabricPrice,
  crossSellFabricDesigns, crossSellFabricSolids, solidsPricePer, trademarkType, sizeQuery, colorCode, queryParams, showOnlyProductInfo, handleFaqButtonClick
}: ProductInfoGridContainerProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const s3host = useApiHost(ApiHostsEnum.s3);
  const gardenHost = useApiHost(ApiHostsEnum.garden);
  const currentOrigin = useOrigin();
  const [photoDataIndex, setPhotoDataIndex] = useState(0);
  const [photoDataList, setPhotoDataList] = useState<(CommentPhotoData | ReviewPhotoData)[] | []>([]);
  const [slideInModal, setSlideInModal] = useState(false);

  const stateSlice = useSelector((state: State) => {
    const featuredDesign = state.design;
    let designPropsFromState;

    if (isDesignResponse(featuredDesign)) {
      const tagsByLocale = isNotUndefined(featuredDesign.tags) ? featuredDesign.tags[locale] : [];

      designPropsFromState = {
        designName: featuredDesign.name,
        designId: featuredDesign.id,
        shortDescription: featuredDesign.short_description,
        longDescription: featuredDesign.description,
        isAvailableOnHomeGood: featuredDesign.for_sale_as_home_good,
        isAvailableOnWallpaper: featuredDesign.for_sale_as_wallpaper,
        isAvailableOnFabric: featuredDesign.for_sale_as_fabric,
        isRotatedFatQuarter: featuredDesign.for_sale_as_tea_towel, // this includes wall hangings now, both are rotated fat quarters
        designFileName: featuredDesign.filename,
        designThumbnail: featuredDesign.design_thumbnail,
        tags: (tagsByLocale || []).filter((t) => (isNotUndefined(t.slug))).slice(0, MAX_NUMBER_OF_TAGS),
        designerUserName: featuredDesign.designer.screen_name,
        designerPublicDesigns: featuredDesign.designer.public_designs,
        designerDescription: featuredDesign.designer.designer_description,
        designerId: featuredDesign.designer.id,
      };
    }

    let containerProps: ProductInfoGridContainerState = {
      ...queryParamsOverrideUserPreferences(state), // country, measurementSystem, currency, userPreferencesOverridden
      userId: hasUserId(state.user) ? state.user.id : undefined,
      userType: state.user.userType,
      windowPosition: state.pageSetup.windowPosition,

      viewport: state.pageSetup.viewport || VIEWPORT_DESKTOP, // TODO SP-8365 handle undefined state.pageSetup
      displayModal: state.pageSetup.modal.displayModal,
      modalName: state.pageSetup.modal.modalName,

      disableUserFeedback: hasNoUserFeedback,
      reviews: state.reviews,
      tags: [], // if defined in the design response, this will be overwritten by the following spread
      ...designPropsFromState
    };

    if (isDesignResponse(featuredDesign) && !objectIsEmpty(featuredDesign.designer.designer_image) && isNotUndefined(featuredDesign.designer.designer_image)) {
      containerProps = {
        ...containerProps,
        isMugshot: !!featuredDesign.designer.designer_image.mugshot,
        designerInfoImageId: featuredDesign.designer.designer_image.id,
        designerInfoImageFile: featuredDesign.designer.designer_image.filename
      };
    }

    return containerProps;
  }, shallowEqual);

  if (!stateSlice) {
    return (<Loading message={translate('common.loading')} />); // TODO: SP-8361 How do we handle error cases like this? For now, assuming that state design data has not loaded.
  }

  const {
    measurementSystem, userId, userType, designName, designId, shortDescription, longDescription,
    isAvailableOnHomeGood, isAvailableOnWallpaper, isAvailableOnFabric, designFileName, designThumbnail, tags,
    designerUserName, designerPublicDesigns, designerDescription, designerId, viewport, displayModal, modalName,
    disableUserFeedback, reviews, isMugshot, designerInfoImageId,
    designerInfoImageFile, isRotatedFatQuarter
  } = stateSlice;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  const dispatchResetAllParams = () => dispatch(updateSearchParamsAndSubmit({}, SEARCH_PARAMS_RESET, currentOrigin)); // TODO - SP-8757 Cleanup SearchParams
  const dispatchOpenModal = (name: string) => (
    dispatch(modalOpen({
      modal: {
        displayModal: true,
        modalName: name
      }
    })));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isNotUndefined(designId)) {
      dispatch(fetchAllProducts());
    }
  }, [designId]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const getUserSubmittedPhotos = useCallback(() => {
    const userSubmittedPhotoList: PhotoData[] = [];

    reviews && reviews.reviews.forEach((review: ReviewInterface) => {
      if (review.media.photo.length > 0) {
        const reviewPhotoList = review.media.photo;
        const userReview = review;

        reviewPhotoList.forEach((photo: ReviewPhotoData) => {
          userSubmittedPhotoList.push({
            thumbnailPhoto: photo.thumbnailUrl,
            photo: photo.originalUrl,
            caption: photo.caption,
            id: review.id,
            review: userReview,
            title: review.title,
            text: review.text,
            createdAt: review.dateCreated
          });
        });
      }
    });

    return userSubmittedPhotoList;
  }, [designFileName, designId, gardenHost, imagesHost, locale, reviews, s3host, userId, userType]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    getUserSubmittedPhotos();
  }, [reviews]);

  const openUserUploadedImagesModal = (event: MouseEvent<HTMLButtonElement>, photoDataIndex: number, photoDataList: (CommentPhotoData | ReviewPhotoData)[]): void => {
    event.preventDefault();
    setPhotoDataIndex(photoDataIndex);
    setPhotoDataList(photoDataList);
    dispatchOpenModal('user-uploaded-images');
    setTimeout(() =>
      setSlideInModal(true), 350);
    setSlideInModal(false);
  };

  const handleSwiperSlideChange = (direction: number): void => {
    if ((direction === 1) && ((photoDataIndex + 1) < photoDataList.length)) {
      setPhotoDataIndex(photoDataIndex + 1);
    } else if ((direction === 0) && ((photoDataIndex - 1) > -1)) {
      setPhotoDataIndex(photoDataIndex - 1);
    }
  };

  const productTypeAbbreviated = () => {
    let productTypeAbbreviated: ProductTypeAbbreviated;

    if ([FABRIC, WALLPAPER, SOLID, FABRIC_BLANK].includes(productType) && isProductType(productType)) {
      productTypeAbbreviated = productType;
    } else if (productType.startsWith(HOME_GOOD)) {
      productTypeAbbreviated = HOME_GOOD;
    } else {
      throw new Error(`Invalid productType: ${productType}`);
    }

    return productTypeAbbreviated;
  };

  const addNoIndexMeta = () => {
    const productType = productTypeAbbreviated();
    const isFabricNotForSale = productType === FABRIC && !isAvailableOnFabric;
    const isWallpaperNotForSale = productType === WALLPAPER && !isAvailableOnWallpaper;
    const isHomeGoodNotForSale = productType === HOME_GOOD && !isAvailableOnHomeGood;
    // TODO: SP-13467 - FIX THIS and restore this check
    // const isSolidNotForSale = (productType === SOLID && !isRotatedFatQuarter);

    if (isFabricNotForSale || isWallpaperNotForSale || isHomeGoodNotForSale) {
      return (
        <Helmet
          meta={[
            {
              name: 'robots',
              content: 'noindex',
            },
          ]}
        />
      );
    }
  };

  const validSizeQuery = productSize && sizeQuery && productSize.includes(sizeQuery.toUpperCase()) ? sizeQuery.toUpperCase() : '';
  const designThumbnailURL = designId ? ImageUrls.cipheredDesignThumbnail(gardenHost, designId, designThumbnail, SMALL_THUMBNAIL_SIZE) : undefined;

  return (
    <ErrorBoundary>
      {addNoIndexMeta()}
      <ProductInfoGrid
        selectedSubstrate={selectedSubstrate}
        productTypeAbbreviated={productTypeAbbreviated()}
        productType={productType}
        homeGoodType={homeGoodType}
        measurementSystem={measurementSystem}
        locale={locale}
        viewport={viewport}
        userType={userType}
        userId={userId}
        productSize={productSize}
        openUserUploadedImagesModal={openUserUploadedImagesModal}
        designId={designId}
        designerId={designerId}
        designerUserName={designerUserName}
        designerPublicDesigns={designerPublicDesigns}
        designerDescription={designerDescription}
        wallpaperAvailableSubstrateThumbnail={wallpaperAvailableSubstrateThumbnail}
        shortDescription={shortDescription}
        longDescription={longDescription}
        fabricDetailsHtml={fabricDetailsHtml}
        isMugshot={isMugshot}
        designerInfoImageId={designerInfoImageId}
        designerInfoImageFile={designerInfoImageFile}
        tags={tags}
        orderItemId={orderItemId}
        designFileName={designFileName}
        designThumbnailURL={designThumbnailURL}
        designName={designName}
        showMeasurement={showMeasurement}
        isAvailableOnWallpaper={isAvailableOnWallpaper}
        isAvailableOnHomeGood={isAvailableOnHomeGood}
        isAvailableOnFabric={isAvailableOnFabric}
        isRotatedFatQuarter={isRotatedFatQuarter}
        userSubmittedPhotoData={getUserSubmittedPhotos()}
        rawItemPrice={rawItemPrice}
        quantity={quantity}
        disableUserFeedback={disableUserFeedback}
        productInfoRef={productInfoRef}
        crossSellBarItems={crossSellBarItems}
        longFabricDescription={longFabricDescription}
        longDescriptionEmphazised={longDescriptionEmphazised}
        trademarkType={trademarkType}
        handleShopClick={handleShopClick}
        fabricPrice={fabricPrice}
        crossSellFabricDesigns={crossSellFabricDesigns}
        solidsPricePer={solidsPricePer}
        sizeQuery={validSizeQuery}
        colorCode={colorCode}
        queryParams={queryParams}
        dispatchResetAllParams={dispatchResetAllParams}
        showOnlyProductInfo={showOnlyProductInfo}
        handleFaqButtonClick={handleFaqButtonClick}
      />
      {(displayModal && modalName === 'user-uploaded-images') &&
      <ErrorBoundary>
        <ModalContainer
          displayFabricsModal={displayModal}
          showFiltersComponent={false}
          slideInModal={slideInModal}
          modalExtraWide={true}
          modalWithButton={false}
          contents={
            <UserSubmittedPhotosModal
              userType={userType}
              viewport={viewport}
              locale={locale}
              photoDataIndex={photoDataIndex}
              photoDataList={photoDataList}
              handleSwiperSlideChange={handleSwiperSlideChange}
              designId={designId} />}
        />
      </ErrorBoundary>}
    </ErrorBoundary>
  );
};

export default ProductInfoGridContainer;
