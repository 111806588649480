/* eslint-disable max-lines */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FieldInlineError from '../components/Reusable/FormElements/FieldInlineError/FieldInlineError';
import Icon from '../components/Reusable/Icon/Icon';
import {formInputMetadata} from '../shapes/formInputMetadata';
import Select from 'react-select';
import {countriesByCodeAndName} from '../constants/Countries';
import {isEmpty} from './validation';

// The render input methods are built for forms that use "redux-form" only.

/**
 * @param optionKeysAndValues Object that will be rendered
 * @param sortHandler Function that sorts the options
 * @param prompt String that show as first option in the select
 * @returns Array of JSX <option /> tags with a prompt option at the beginning
 */

export function renderOptions(optionKeysAndValues, sortHandler) {
  const options = [];
  const optionKeys = Object.keys(optionKeysAndValues);

  if (sortHandler !== undefined) {
    optionKeys.sort(sortHandler);
  }
  for (const option of optionKeys) {
    options.push(
      {
        label: optionKeysAndValues[option],
        value: option
      }
    );
  }

  return options;
}

export const renderCountryOptions = (countries, locale) => {
  const countriesMap = countriesByCodeAndName(countries, locale);

  return renderOptions(countriesMap, (countryCode, nextCountryCode) => {
    const countryName = countriesMap[countryCode];
    const nextCountryName = countriesMap[nextCountryCode];

    return countryName.localeCompare(nextCountryName);
  });
};

export const renderInput = ({input, formPrefix, label, type, placeholder, autoComplete, ariaRequired, ariaDescribedby, helperText, meta: {touched, error, submitting}}) => (
  <div className={classNames('type-text', {
    'x-error': touched && error
  })}>
    {touched && error && (
      <FieldInlineError message={error}/>
    )}
    {label && <label htmlFor={`${formPrefix}-${input.name}`} className='label'>{label}</label>}
    <input {...input}
      id={`${formPrefix}-${input.name}`}
      type={type}
      value={input.value}
      placeholder={placeholder}
      autoComplete={autoComplete}
      disabled={submitting}
      aria-disabled={submitting}
      aria-required={ariaRequired}
      aria-describedby={ariaDescribedby}
      className={classNames('text', {
        'x-error': touched && error
      })}/>
    {helperText && <p id={ariaDescribedby} className='helper-text'>{helperText}</p>}
  </div>
);

renderInput.propTypes = {
  input: PropTypes.object,
  formPrefix: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  ariaRequired: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  helperText: PropTypes.string,
  meta: formInputMetadata,
  onChangeAction: PropTypes.func
};

export const renderCheckRadio = ({input, disabled, formPrefix, label, type, highlightClass, placeholder, ariaRequired, onClickAction, meta: {touched, error, submitting}}) => (
  <div className={classNames(`type-${type}`, highlightClass, {
    'x-error': touched && error
  })}>
    {touched && error && (<FieldInlineError message={error}/>)}
    <input {...input}
      id={`${formPrefix}-${input.name}`}
      type={type}
      placeholder={placeholder}
      disabled={submitting || disabled}
      aria-disabled={submitting || disabled}
      aria-required={ariaRequired}
      className={classNames(type, {
        'x-error': touched && error
      })}
      onClick={onClickAction} />
    <label htmlFor={`${formPrefix}-${input.name}`} className='label'>{label}</label>
  </div>
);

renderCheckRadio.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  formPrefix: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  highlightClass: PropTypes.string,
  placeholder: PropTypes.string,
  ariaRequired: PropTypes.string,
  onClickAction: PropTypes.func,
  meta: formInputMetadata
};

export const renderRadioButtons = ({input, disabled, formPrefix, label, type, highlightClass, placeholder, ariaRequired, onClickAction, meta: {touched, error, submitting}}) => (
  <div className={classNames(`type-${type}`, highlightClass, {
    'x-error': touched && error
  })}>
    {touched && error && (<FieldInlineError message={error}/>)}

    <label
      htmlFor={`${formPrefix}-${input.name}-${input.value}`}
      className={`${formPrefix}-${input.name}-label`}>
      <input {...input}
        id={`${formPrefix}-${input.name}-${input.value}`}
        type={type}
        placeholder={placeholder}
        disabled={submitting || disabled}
        aria-disabled={submitting || disabled}
        aria-required={ariaRequired}
        className={classNames(type, {
          'x-error': touched && error
        })}
        onClick={onClickAction}/>
      <span className='label-text'>{label}</span>
    </label>
  </div>
);

renderRadioButtons.propTypes = {
  input: PropTypes.object,
  disabled: PropTypes.bool,
  formPrefix: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  highlightClass: PropTypes.string,
  placeholder: PropTypes.string,
  ariaRequired: PropTypes.string,
  onClickAction: PropTypes.func,
  meta: formInputMetadata
};

export const renderTwoPartLabelRadio = ({
  deleteButtonClickSubmitting = false, fieldValue, radioValue, input, formPrefix, labelPartOne, labelPartTwo, classNamesLabelPartOne, classNamesLabelPartTwo,
  highlightClass, placeholder, ariaRequired, onClickAction, iconName, actionTitle, onButtonClickAction, meta: {touched, error}
}) => (
  <div className={classNames('type-radio', highlightClass, {
    'x-error': touched && error
  })}>
    {touched && error && (
      <FieldInlineError message={error}/>
    )}
    <div className='radio-wrapper'>
      {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
      <input {...input}
        checked={fieldValue === radioValue}
        value={radioValue}
        id={`${formPrefix}-${radioValue}`}
        type='radio'
        placeholder={placeholder}
        disabled={deleteButtonClickSubmitting}
        aria-disabled={deleteButtonClickSubmitting}
        aria-required={ariaRequired}
        className={classNames('radio', {
          'x-error': touched && error
        })}
        onClick={onClickAction} />
    </div>
    <label htmlFor={`${formPrefix}-${radioValue}`} className='label'>
      <span className={classNamesLabelPartOne}>{labelPartOne}</span>
      <span className={classNamesLabelPartTwo}>{labelPartTwo}</span>
      {(iconName) && <button
        className={classNames('link-button', {
          'x-disabled': deleteButtonClickSubmitting
        })}
        type='button'
        aria-label={actionTitle}
        title={actionTitle}
        onClick={onButtonClickAction}>
        <Icon
          iconName={iconName}/>
      </button>
      }
    </label>
  </div>
);

renderTwoPartLabelRadio.propTypes = {
  deleteButtonClickSubmitting: PropTypes.bool,
  fieldValue: PropTypes.string,
  radioValue: PropTypes.string,
  input: PropTypes.object,
  formPrefix: PropTypes.string,
  labelPartOne: PropTypes.string,
  labelPartTwo: PropTypes.string,
  classNamesLabelPartOne: PropTypes.string,
  classNamesLabelPartTwo: PropTypes.string,
  type: PropTypes.string,
  highlightClass: PropTypes.string,
  placeholder: PropTypes.string,
  ariaRequired: PropTypes.string,
  onClickAction: PropTypes.func,
  iconName: PropTypes.string,
  actionTitle: PropTypes.string,
  onButtonClickAction: PropTypes.func,
  meta: formInputMetadata
};

export const renderTextarea = ({input, formPrefix, label, labelClass, placeholder, ariaRequired, meta: {touched, error, submitting}}) => (
  <div className={classNames('type-textarea', {
    'x-error': touched && error
  })}>
    {touched && error && (
      <FieldInlineError message={error}/>
    )}
    <label htmlFor={`${formPrefix}-${input.name}`} className={labelClass}>{label}</label>
    <textarea {...input}
      id={`${formPrefix}-${input.name}`}
      placeholder={placeholder}
      disabled={submitting}
      aria-disabled={submitting}
      aria-required={ariaRequired}
      className={classNames('textarea', {
        'x-error': touched && error
      })}>
      {input.value}
    </textarea>
  </div>
);

renderTextarea.propTypes = {
  input: PropTypes.object,
  formPrefix: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  placeholder: PropTypes.string,
  ariaRequired: PropTypes.string,
  meta: formInputMetadata
};

export const renderSelect = ({
  input, formPrefix, onChangeAction, options, containerClass, labelText, isClearable, className, divider, placeholder, size, isDisabled,
  meta: {touched, error, submitting}, initialValue
}) => {
  function handleSelection(value) {
    value ? input.onChange(value.value) : input.onChange(input.value);
    setTimeout(onChangeAction);
  }

  return (
    <div className={classNames('x-react-select', containerClass, {
      'x-bottom-dashed': divider
    }, {
      'x-error': touched && error
    })}>
      {touched && error && (
        <FieldInlineError message={error}/>
      )}
      {!isEmpty(labelText) && <label className='label' htmlFor={`${formPrefix}-${input.name}`}>{labelText}</label>}
      <Select
        id={`${formPrefix}-${input.name}`}
        value={options.filter(({value}) => (value === input.value || (value === initialValue && isEmpty(input.value))))}
        options={options}
        className={classNames(className, {
          'x-error': touched && error
        })}
        classNamePrefix={'react-select'}
        onChange={handleSelection}
        blurInputOnSelect={false} // https://github.com/JedWatson/react-select/issues/2771
        isClearable={isClearable}
        labelText={labelText}
        autoBlur={true}
        isDisabled={isDisabled || submitting}
        disabled={isDisabled || submitting}
        placeholder={placeholder}
        size={size}/>
    </div>
  );
};

renderSelect.propTypes = {
  input: PropTypes.object,
  formPrefix: PropTypes.string,
  onChangeAction: PropTypes.func,
  options: PropTypes.array,
  containerClass: PropTypes.string,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.string,
  divider: PropTypes.bool,
  isDisabled: PropTypes.bool,
  meta: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  placeholder: PropTypes.string,
  size: PropTypes.number,
  initialValue: PropTypes.string
};

export const renderMonthYearSelects = ({
  month: {input: monthInput, meta: {touched: monthTouched, error: monthError, submitting: monthSubmitting}},
  year: {input: yearInput, meta: {touched: yearTouched, error: yearError, submitting: yearSubmitting}},
  monthOptions, yearOptions, className, formPrefix, isClearable, labelText, containerClass, monthName, yearName,
  monthPlaceholder, yearPlaceholder
}) => {
  function handleMonthInput(value) {
    monthInput.onChange(value.value);
  }
  function handleMonthInputOnBlur() {
    monthInput.onBlur(monthInput.value.value);
  }
  function handleYearInput(value) {
    yearInput.onChange(value.value);
  }
  function handleYearInputOnBlur() {
    yearInput.onBlur(yearInput.value.value);
  }

  return (
    <div className={classNames('x-react-select', containerClass, {
      'x-error': monthTouched && monthError || yearTouched && yearError
    })}>
      {monthTouched && monthError && (
        <FieldInlineError message={monthError}/>
      )}
      {yearTouched && yearError && (
        <FieldInlineError message={yearError}/>
      )}
      <label htmlFor={`${formPrefix}-${monthName}`} className='label'>{labelText}</label>
      <Select {...monthInput}
        id={`${formPrefix}-${monthName}`}
        name={monthName}
        value={monthInput.value}
        options={monthOptions}
        className={classNames(className, {
          'x-error': monthTouched && monthError
        })}
        onChange={handleMonthInput}
        onBlur={handleMonthInputOnBlur}
        isClearable={isClearable}
        labelText={labelText}
        autoBlur={true}
        disabled={monthSubmitting}
        placeholder={monthPlaceholder}/>
      <Select {...yearInput}
        id={`${formPrefix}-${yearName}`}
        name={yearName}
        value={yearInput.value}
        options={yearOptions}
        className={classNames(className, {
          'x-error': yearTouched && yearError
        })}
        onChange={handleYearInput}
        onBlur={handleYearInputOnBlur}
        isClearable={isClearable}
        labelText={labelText}
        autoBlur={true}
        disabled={yearSubmitting}
        placeholder={yearPlaceholder}/>
    </div>
  );
};

renderMonthYearSelects.propTypes = {
  month: PropTypes.shape({
    input: PropTypes.object,
    meta: formInputMetadata
  }),
  year: PropTypes.shape({
    input: PropTypes.object,
    meta: formInputMetadata
  }),
  onChangeAction: PropTypes.func,
  monthOptions: PropTypes.array,
  yearOptions: PropTypes.array,
  containerClass: PropTypes.string,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  labelText: PropTypes.string,
  disabled: PropTypes.bool,
  monthName: PropTypes.string,
  yearName: PropTypes.string,
  monthPlaceholder: PropTypes.string,
  yearPlaceholder: PropTypes.string,
  formPrefix: PropTypes.string
};

export const renderFileSelect = ({id, label, inputStyle, labelStyle, onChangeAction}) => (
  <div className='type-file'>
    <label className={labelStyle} htmlFor={id}>
      {label}<Icon iconName='plus' />
    </label>
    <input type='file' id={id} className={inputStyle}
      onChange={onChangeAction} />
  </div>
);

renderFileSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputStyle: PropTypes.string.isRequired,
  labelStyle: PropTypes.string.isRequired,
  onChangeAction: PropTypes.func.isRequired
};
