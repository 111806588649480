/* eslint-disable react/no-multi-comp */
import classNames from 'classnames';
import React, {useEffect} from 'react';
import './_substrate-shop.scss';

import {FABRIC_PERENNIAL_SATEEN_GRAND, FABRIC_LONGLEAF_SATEEN_GRAND, RETURN_HTML} from '../../../constants/Codes';
import {measurementSystemToUnit, IMPERIAL} from '../../../constants/Measurements';
import {AVAILABLE_SORTINGS_LIST} from '../../../constants/Sortings';
import {isDesktop} from '../../../constants/Viewports';
import {SortedCategoryType} from '../../../containers/Fabric/FabricShopContainer';
import {translate} from '../../../services/index';
import {AvailableStock} from '../../../shapes/fabrics';
import {ToggleSlideInComponentFunction} from '../../../shapes/functions';
import {MeasurementType} from '../../../shapes/measurement';
import {Fabrics, WallpaperDimensions} from '../../../shapes/products';
import {ViewportType} from '../../../shapes/viewport';
import {isColorMap} from '../../../utils/design';
import {defaultBasePrice} from '../../../utils/fabrics';
import {getCategoryName} from '../../../utils/filterFabrics';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import {wallpaperDetailSections, isOutOfStock} from '../../../utils/wallpaper';
import {markingSearchQuery, renderDetailList} from '../../../utils/ymlFormatting';
import SubstrateFilter from '../../SubstrateFilter/SubstrateFilter';
import RadioGroup, {RadioItemType} from '../FormElements/RadioGroup/RadioGroup';
import Icon from '../Icon/Icon';
import SubstrateItem from '../SubstrateItem/SubstrateItem';

import SubstrateShopSearchBoxContainer from './SubstrateShopSearchBoxContainer';


export interface SubstrateShopProps {
  sortingCriteria?: string;
  fabrics: Fabrics;
  dispatchToggleSlideInComponent: ToggleSlideInComponentFunction;
  viewport: ViewportType;
  setSubstrate: (substrateString: string) => void;
  clearSearch: () => void;
  sortedFabrics: string[];
  handleSorting: (sortingCriteria: string) => void;
  sortedCategories: SortedCategoryType;
  measurementSystem: MeasurementType;
  designId?: number;
  searchBoxRunSearchWithNewInput: () => void;
  selectedSubstrate?: string;
  outOfStockArray?: AvailableStock[];
  searchQuery?: string;
  inputRefCallback: (input: HTMLInputElement) => void;
  clearFilters: () => void;
  category?: string;
  isCategoryPage: boolean;
  displayFabricsModal?: boolean;
  locale: string;
  currency?: string;
  isStandardFabricModal?: boolean;
  isWallpaper?: boolean;
  wallpaperDimensions?: WallpaperDimensions;
  designImages?: {fabric: {[key: string]: {fabric_specific: Record<string, string>}}};
}

const SubstrateShop = ({
  sortingCriteria, fabrics, outOfStockArray, dispatchToggleSlideInComponent, viewport, setSubstrate, clearSearch, sortedFabrics, handleSorting,
  sortedCategories, measurementSystem, designId, searchBoxRunSearchWithNewInput, selectedSubstrate, searchQuery,
  inputRefCallback, clearFilters, category, isCategoryPage, displayFabricsModal, locale, currency,
  isStandardFabricModal, isWallpaper, wallpaperDimensions, designImages
}: SubstrateShopProps): JSX.Element => {
  const getSortingOptions = (): RadioItemType[] => AVAILABLE_SORTINGS_LIST.map((sorting) => ({
    id: `${sorting}_button`,
    value: sorting,
    inputLabel: `${sorting}_button`,
    labelText: translate(`fabricShop.sorting.${sorting}`),
    isDefaultValue: sortingCriteria === sorting
  }));

  const isGrandFabric = (fabric: string) => fabric === FABRIC_PERENNIAL_SATEEN_GRAND || fabric === FABRIC_LONGLEAF_SATEEN_GRAND;
  const translationFileName = isStandardFabricModal || isWallpaper ? 'fabricShop' : 'homeGoodFabrics';
  const getFabricDescription = (fabric: string) => {
    const translationDescription = translate(`${translationFileName}.${fabric}.description`);

    return searchQuery ? markingSearchQuery(translationDescription, searchQuery) : translationDescription;
  };
  const headingClasses = displayFabricsModal ? classNames('substrate-heading-modal') : classNames('substrate-heading');
  const getWallpaperDescription = (fabric: string) => renderDetailList({
    'descriptionList': translate(`fabricShop.${fabric}.descriptionList`)
  }, searchQuery);

  const getWallpaperDetails = (wallpaper: string) => {
    let repeatInfo;

    if (isNotUndefined(wallpaperDimensions)) {
      repeatInfo = measurementSystem === IMPERIAL ?
        wallpaperDimensions.normalizedVerticalRepeatSize : wallpaperDimensions.normalizedVerticalRepeatSizeCm;
    }

    const wallpaperSpecs = wallpaperDetailSections(wallpaper, measurementSystem, repeatInfo) as Record<string, string>;
    let fabricDetails;
    let hasSearchResult = false;

    if (searchQuery) {
      fabricDetails = renderDetailList(wallpaperSpecs, searchQuery);
      hasSearchResult = true;
    } else {
      fabricDetails = renderDetailList(wallpaperSpecs);
    }

    return {
      fabricDetails,
      hasSearchResult
    };
  };

  const getFabricDetails = (fabric: string) => {
    let fabricDetails: string | (string | JSX.Element)[] = translate(`${translationFileName}.${fabric}.details`);
    let hasSearchResult = false;

    if (isWallpaper && isNotUndefined(wallpaperDimensions)) {
      const repeatInfo = measurementSystem === IMPERIAL ?
        wallpaperDimensions.normalizedVerticalRepeatSize : wallpaperDimensions.normalizedVerticalRepeatSizeCm;

      fabricDetails = translate(`fabricShop.${fabric}.details.${measurementSystem}`, {
        repeatSize: repeatInfo
      });
    }
    if (searchQuery) {
      fabricDetails = markingSearchQuery(fabricDetails, searchQuery, RETURN_HTML);
    }
    if (Array.isArray(fabricDetails)) {
      fabricDetails = fabricDetails.join('');
      hasSearchResult = true;
    }

    return {
      fabricDetails,
      hasSearchResult
    };
  };

  const noResults = () => {
    const translationKey = isCategoryPage ? 'search.noResultsCategory' : 'search.noResults';

    return (
      <p className='no-results'>{translate(translationKey, {
        searchQuery,
        category
      })}
      <button className='clear-filters' onClick={clearFilters}>
        <span className='clear-filters-no-results'>{translate('search.clearYourSearch')}</span>
      </button>{translate('search.andTryAgain')}
      </p>
    );
  };

  const handleClickSlideIn = () =>
    dispatchToggleSlideInComponent(true, currentCategory, sortedCategories, clearFilters, searchQuery);
  const handleOnChangeRadio = (value: string) => handleSorting(value);
  const fabricIsAvailableInYml = (fabric: string) => isNotUndefined(translate(`fabricShop.${fabric}.name`));
  const getFabricHeader = (fabric: string) => (searchQuery ? markingSearchQuery(translate(`fabricShop.${fabric}.name`), searchQuery) : translate(`fabricShop.${fabric}.name`));
  const viewportIsDesktop = isDesktop(viewport as ViewportType);
  const designIsColorMap = isColorMap(designId);
  const categoriesIsDefined = isStandardFabricModal && isNotUndefined(fabrics.categories) && isNotUndefined(fabrics.categories[locale]);
  const searchCategoryPlaceholder = isCategoryPage ? 'search.fabricSearchPlaceholderQuick' : 'search.fabricSearchPlaceholder';
  const searchPlaceholder = 'search.queryPlaceholder';
  const searchCategory = isStandardFabricModal ? getCategoryName(category, fabrics) : false;
  const currentCategory = category;
  const measureUnit = measurementSystemToUnit[measurementSystem];
  const inputPlaceholder = !isStandardFabricModal ? translate(searchPlaceholder) : translate(searchCategoryPlaceholder, {
    searchCategory
  });

  useEffect(() => () => {
    clearSearch();
  }, []);

  const selectedCategoryText = () => {
    for (const key of Object.keys(sortedCategories)) {
      const categories = sortedCategories[key];

      for (const key of Object.keys(categories)) {
        const selectedCategory = categories[Number(key)];

        if (category && selectedCategory.slug === category) {
          return selectedCategory.text;
        }
      }
    }
  };

  const renderTag = () => (
    <button
      className='selected-category'
      type='button'
      onClick={clearFilters}
      aria-label={category}
      title={category}>
      {selectedCategoryText()}
      <Icon iconName='close' iconTitle={category} />
    </button>
  );

  const renderHeaderContent = () => (
    <>
      {!displayFabricsModal &&
      <div className='heading-container'>
        <p className='h2 filter-heading'>{translate('fabricShop.allFabricTypes')}</p>
        {category && renderTag()}
      </div>}
      <div className='top-filters'>
        <SubstrateShopSearchBoxContainer
          inputPlaceholder={inputPlaceholder}
          inputRefCallback={inputRefCallback}
          onButtonClick={searchBoxRunSearchWithNewInput}
          onClearSearch={clearSearch}
          onInputKeyUp={searchBoxRunSearchWithNewInput}
          searchWrapperFullWidth={displayFabricsModal}
        />
        {!displayFabricsModal &&
      <RadioGroup
        radios={getSortingOptions()}
        name='fabric-sorting'
        handleOnChangeRadio={handleOnChangeRadio}
        inputClassNames={['type-radio']}
        labelClassNames={['type-label']}
      />}
      </div>
    </>
  );

  return (
    <div className='b-substrate-shop'>
      {(viewportIsDesktop && categoriesIsDefined && !displayFabricsModal) &&
        <SubstrateFilter
          viewport={viewport}
          searchQuery={searchQuery}
          clearFilters={clearFilters}
          currentCategory={category}
          sortedCategories={sortedCategories}
        />}
      <div className='shop-content'>
        {displayFabricsModal &&
          <h3 className='fabric-shop-modal-title'>
            {isWallpaper ? translate('fabricShop.subTitleWallpaper') : translate('fabricShop.subTitle')}
          </h3>}

        <div className={headingClasses}>
          {renderHeaderContent()}
        </div>

        {(!viewportIsDesktop && categoriesIsDefined && !displayFabricsModal && isStandardFabricModal) && <div className='button-wrapper-fixed'>
          <button
            type='button'
            className='btn blue-button big-button filter-button'
            onClick={handleClickSlideIn}>
            {translate('fabricShop.filterFabrics')}
          </button>
        </div>}

        {!isEmpty(sortedFabrics) ?
          <ul className='order-item-list'>
            {sortedFabrics.map((fabric, index) => { // eslint-disable-line
              if (fabricIsAvailableInYml(fabric)) {
                const {fabricDetails, hasSearchResult} = isWallpaper ? getWallpaperDetails(fabric) : getFabricDetails(fabric);

                const priceIsDefined = isStandardFabricModal ? isNotUndefined(fabrics['fabrics'][`${fabric}`]['pricing'][`${measureUnit}`]) : false;
                const substratePrice = priceIsDefined && fabrics['fabrics'][`${fabric}`]['pricing'][`${measureUnit}`].price_undiscounted;
                const substrateUrl = isStandardFabricModal && isNotUndefined(fabrics['fabrics'][`${fabric}`]['url']) ? fabrics['fabrics'][`${fabric}`]['url'] : undefined;
                const finalDescription = isWallpaper ? getWallpaperDescription(fabric) : getFabricDescription(fabric);
                const subtitle = isWallpaper ? translate(`fabricShop.${fabric}.description`) : '';
                const fabricPinImageUrl = (!isWallpaper && isNotUndefined(designImages?.fabric) && isNotUndefined(designImages?.fabric[fabric]?.fabric_specific) && isNotUndefined(viewport)) ?
                  designImages?.fabric[fabric].fabric_specific[viewport] : undefined;
                const materialAvailabilityData = outOfStockArray ? outOfStockArray?.find((el) => el.name === fabric) : fabrics.fabrics[fabric];
                const {active, oos, standby} = materialAvailabilityData || {
                  active: true,
                  oos: false,
                  standby: false,
                };
                const materialOutOfStock = isOutOfStock(active, oos, standby);
                const buttonClass = materialOutOfStock ? 'out-of-stock-button' : 'white-button';

                return !(isGrandFabric(fabric) && designIsColorMap) &&
                  <SubstrateItem
                    key={index}
                    viewport={viewport}
                    fabricCode={fabric}
                    name={getFabricHeader(fabric)}
                    altTextFabricName={translate(`fabricShop.${fabric}.name`)}
                    subtitle={subtitle}
                    isSelected={fabric === selectedSubstrate}
                    pricePerUnit={translate('measurements.priceInfo', {
                      price: defaultBasePrice(substratePrice, currency),
                      unit: translate(`measurements.${measurementSystemToUnit[measurementSystem]}`)
                    })}
                    description={finalDescription}
                    details={fabricDetails}
                    outOfStock={materialOutOfStock}
                    collapsibleOpen={hasSearchResult}
                    setSubstrateClick={setSubstrate}
                    buttonText={translate('fabricShop.buttonText')}
                    selectButtonText={translate('fabricShop.selectButtonText')}
                    selectedButtonText={translate('common.forms.selected')}
                    collapsibleText={translate('fabricShop.collapsibleText')}
                    collapsibleTextSecond={translate('fabricShop.collapsibleTextLess')}
                    displayFabricsModal={displayFabricsModal}
                    showPrice={isStandardFabricModal}
                    learnMoreLink={substrateUrl}
                    buttonClass={buttonClass}
                    measurementSystem={measurementSystem}
                    locale={locale}
                    imageSource={fabricPinImageUrl}
                    hideSwiper={true}
                  />;
              }
            })}
          </ul> :
          noResults()
        }
      </div>
    </div>
  );
};

export default SubstrateShop;
