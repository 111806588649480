import classNames from 'classnames';
import React from 'react';

import './_tags-list.scss';
import {QUERY_PARAM, SORT_PARAM, URL_QUERY_PARAM_SORT_BESTSELLERS, ORDER_ITEM_ID} from '../../../../constants/Parameters';
import useOrigin from '../../../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter, translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {TagsProps, Tag, isTagObject} from '../../../../shapes/tags';
import {upsertUrlQuery} from '../../../../utils/url';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';


export interface TagsListProps {
  tagNames: string[];
  tags: TagsProps;
  orderItemId?: number;
  isBlocked?: boolean;
  classicTags?: boolean;
  locale?: Locale;
}

const TagsList = ({tagNames, tags, orderItemId, isBlocked, classicTags = true, locale}: TagsListProps): JSX.Element => {
  const adjustedTag = (tag: Tag) => (isTagObject(tag) ? tag.slug : tag);
  const adjustedName = (tag: Tag) => (isTagObject(tag) ? tag.name : tag);
  const currentOrigin = useOrigin();

  const tagLinkUrlClassic = (tag: Tag) => (
    upsertUrlQuery(
      translate('tags.main.tagSlug', {
        tagSlug: adjustedTag(tag),
        langCode: locale
      }),
      {
        [ORDER_ITEM_ID]: orderItemId
      },
      currentOrigin
    )
  );
  const tagLinkUrl = (tag: Tag) => (
    upsertUrlQuery(MultiLocaleRouter.localePathname('shop.collections'), Object.assign({}, {
      [QUERY_PARAM]: adjustedTag(tag),
      [SORT_PARAM]: URL_QUERY_PARAM_SORT_BESTSELLERS,
      [ORDER_ITEM_ID]: orderItemId
    }), currentOrigin)
  );
  const listClassName = classNames('b-tags-list', {
    'x-blocked': isBlocked
  });
  const tagNamesStrings = tagNames.toString();

  return (
    <div className={listClassName} aria-label={tagNamesStrings} title={tagNamesStrings}>
      {tags.map((tag, index) => (
        <h2 key={index} className='description' itemProp='category'>
          <LinkWrapper
            className='tag-link'
            hrefValue={classicTags ? tagLinkUrlClassic(tag): tagLinkUrl(tag)}
            contents={adjustedName(tag)}
            target='_self'
          />
        </h2>
      ))}
    </div>
  );
};

export default TagsList;
