export function randomArray(array) {
  return Array.isArray(array) ? (array) => array.sort(() => Math.random() - 0.5) : array;
}

export const times = (amountOfIterations) => (functionCallBack) => {
  if (amountOfIterations > 0) {
    functionCallBack();
    times(amountOfIterations - 1)(functionCallBack);
  }
};

export function countItemsInArray(list, item) {
  return list.reduce((totalAmount, currentValue) => totalAmount + (currentValue === item), 0);
}
