import React from 'react';

import './_user-submitted-photos-modal.scss';
import SwiperWrapperContainer from '../../../../containers/Design/SwiperWrapperContainer';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {CommentPhotoData, ReviewPhotoData} from '../../../../shapes/uploadedPhotos';
import {UserType} from '../../../../shapes/user';
import {ViewportType} from '../../../../shapes/viewport';
import {isNotUndefined} from '../../../../utils/validation';
import {ErrorBoundary} from '../../../Reusable/ErrorBoundary/ErrorBoundary';
import IndividualReviewListItem from '../../Reviews/IndividualReviewListItem/IndividualReviewListItem';


export interface UserSubmittedPhotosModalProps {
  photoDataIndex: number;
  handleSwiperSlideChange: (direction: number) => void;
  photoDataList: (CommentPhotoData | ReviewPhotoData)[];
  locale: Locale;
  userType?: UserType;
  viewport: ViewportType;
  designId?: number;
}

const UserSubmittedPhotosModal = ({
  photoDataIndex, photoDataList, locale, userType, handleSwiperSlideChange,
  viewport, designId
}: UserSubmittedPhotosModalProps): JSX.Element => {
  const isCommentPhotoData = (input: CommentPhotoData | ReviewPhotoData): input is CommentPhotoData =>
    (input as CommentPhotoData).comment !== undefined;

  const createReview = () => {
    const photoData = photoDataList[photoDataIndex] as ReviewPhotoData;

    return isNotUndefined(photoData.review) && <IndividualReviewListItem
      designId={designId}
      userType={userType}
      locale={locale}
      key={photoData.id}
      review={photoData.review}
      reviewTitle={photoData.title}
      reviewText={photoData.text}
      isPhotoModal={isNotUndefined(photoData.photo)}
      openPhotoModal={false}
    />;
  };

  const photoData = photoDataList[photoDataIndex];
  const photoBelongsToReview = !isCommentPhotoData(photoData) && isNotUndefined(photoData.review);

  const content = photoBelongsToReview ? createReview() : '';
  const swiperParams = {
    initialSlide: photoDataIndex,
    slidesPerView: 'auto',
    centeredSlidesBounds: true
  };

  return (
    <ErrorBoundary>
      <div className='b-user-submitted-photos-modal'>
        <h3 className='h3 photo-modal-heading'>
          {photoBelongsToReview ? translate('fabric.userSubmittedPhotos.reviewPhotoModalHeading') :
            translate('fabric.userSubmittedPhotos.commentPhotoModalHeading')}
        </h3>
        <SwiperWrapperContainer
          mountTimer={true}
          viewport={viewport}
          swiperParams={swiperParams}
          handleSwiperSlideChange={handleSwiperSlideChange}
          navigationClasses='x-on-top-of-slides'>
          {photoDataList.map((design, index) => (
            <img
              key={index}
              className='user-submitted-photo swiper-lazy'
              src={design.photo}
              alt={design.caption}
              width='100'
              height='100' />
          ))}
        </SwiperWrapperContainer>
        {content}
      </div>
    </ErrorBoundary>
  );
};

export default UserSubmittedPhotosModal;
