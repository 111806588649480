import isString from 'lodash/isString';
import React, {ReactElement} from 'react';

import {RETURN_HTML, RETURN_JSX} from '../constants/Codes';
import {REGEX_HTML_LIST_FORMATTING} from '../constants/Validation';
import {translate} from '../services';

import {isEmpty, isNotUndefined} from './validation';


export const renderSpecList = (title: string, specList: Record<string, string | undefined>): JSX.Element => {
  const specListItems: ReactElement<JSX.Element[]>[] = [];

  Object.keys(specList).forEach((key, index) => {
    const listTerm = translate(`fabric.fabricDetail.${key}`);
    const listDescription = specList[key];

    if (listTerm.includes('|') || (listDescription && listDescription.includes('|'))) {
      const splitTerm = listTerm.split('|');
      const splitDescription = listDescription?.split('|');

      (listDescription && !listDescription.includes(`.${key}`)) &&
      specListItems.push(
        <React.Fragment key={index}>
          {splitTerm.map((key, index) =>
            <dt key={`term-${index}`} className='list-term'>{key}</dt>
          )}
          {splitDescription?.map((key, index) =>
            <dd key={`description-${index}`} className={splitDescription.length-1 === index ? 'list-description':''}>{key}</dd>
          )}
        </React.Fragment>);
    } else {
      (listDescription && !listDescription.includes(`.${key}`)) &&
      specListItems.push(
        <React.Fragment key={index}>
          <dt className='list-term'>{listTerm}</dt>
          <dd className='list-description'>{listDescription}</dd>
        </React.Fragment>);
    }
  });

  return (
    <div className='spec-wrapper'>
      <h3 className='spec-title'>{title}</h3>
      <dl className='spec-list'>{specListItems}</dl>
    </div>);
};

const descriptionShouldSplit = (listDescription?: string | (string | JSX.Element)[]) => {
  if (listDescription && listDescription.includes('|') || Array.isArray(listDescription)) {
    return true;
  }

  return false;
};

export const renderDetailList = (detailList: Record<string, string | undefined>, searchQuery?: string): JSX.Element => {
  const detailListItems: ReactElement<JSX.Element[]>[] = [];

  Object.keys(detailList).forEach((key, index) => {
    const hasTranslation = (translation: string) => (
      !translation.includes('.fabricDetail')
    );
    const listTerm = hasTranslation(translate(`fabric.fabricDetail.${key}`)) ? translate(`fabric.fabricDetail.${key}`) : '';
    const listDescription = detailList[key];


    if (listTerm.includes('|') || descriptionShouldSplit(listDescription)) {
      const splitTerm = listTerm.split('|');
      const splitDescription = Array.isArray(listDescription) ? listDescription : listDescription?.split('|');

      (listDescription && !listDescription.includes(`.${key}`)) &&
      detailListItems.push(
        <React.Fragment key={index}>
          {splitTerm.map((key, index) =>
            <ul key={`term-${index}`} className='list-term'>{searchQuery && isString(key) ? markingSearchQuery(key, searchQuery) : key}
              {splitDescription?.map((key, index) =>
                <li key={`description-${index}`} className={`list-term-item list-description-${index}`}>{searchQuery && isString(key) ? markingSearchQuery(key, searchQuery) : key}</li>
              )}
            </ul>
          )}
        </React.Fragment>);
    } else if (listDescription && !listDescription.includes(`.${key}`)) {
      detailListItems.push(
        <React.Fragment key={index}>
          <ul className='list-term'>{searchQuery && isString(listTerm) ? markingSearchQuery(listTerm, searchQuery) : listTerm}
            <li className='list-term-item list-description'>{
              searchQuery && isString(listDescription) ? markingSearchQuery(listDescription, searchQuery) : listDescription
            }</li>
          </ul>
        </React.Fragment>);
    }
  });

  return (
    <div className='detail-wrapper'>
      <ul className='detail-list'>{detailListItems}</ul>
    </div>);
};

export const markingSearchQuery = (fabricInfo: string, searchQuery: string, returnType: string = RETURN_JSX): string | (JSX.Element | string)[] => {
  if (![RETURN_HTML, RETURN_JSX].includes(returnType)) {
    throw new Error(`Invalid returnType: ${returnType}`);
  }
  const lowerCaseFabricInfo = fabricInfo.toLowerCase().replace(REGEX_HTML_LIST_FORMATTING, '');
  let modifiedFabricInfo: (string | JSX.Element)[] = [];

  if (isNotUndefined(searchQuery) && lowerCaseFabricInfo.includes(searchQuery) && searchQuery !== '') {
    const splitInfo = lowerCaseFabricInfo.split(/[\s,<>()-:]+/);
    let lowerCaseSearchQuery = searchQuery;

    splitInfo.map((infoFragment) => { // eslint-disable-line
      if (infoFragment.includes(searchQuery)) {
        lowerCaseSearchQuery = infoFragment;
      }
    });

    const searchQueryRegex = new RegExp(`(\\b${lowerCaseSearchQuery}\\b)`, 'gi');

    modifiedFabricInfo = fabricInfo.split(searchQueryRegex);

    // Using outdated way of writing a for loop due the given possibility to easily increment by 2
    for (let i = 1; i < fabricInfo.length; i += 2) {
      if (modifiedFabricInfo[i]) {
        modifiedFabricInfo[i] = returnType === RETURN_JSX ? <mark key={i}>{modifiedFabricInfo[i]}</mark> :
          `<mark>${modifiedFabricInfo[i]}</mark>`;
      }
    }
  }

  return !isEmpty(modifiedFabricInfo) ? modifiedFabricInfo : fabricInfo;
};
