import React, {ReactElement} from 'react';

import './_add_to_cart_button.scss';


interface AddToCartButtonProps {
  addToCartButtonClasses: string;
  addToCartButtonTitle?: string;
  buttonContent: string | ReactElement | Record<string, unknown>;
  buttonIsDisabled: boolean;
  onClickFunction: () => void;
}

const AddToCartButton = ({addToCartButtonClasses, addToCartButtonTitle, buttonContent, buttonIsDisabled, onClickFunction}: AddToCartButtonProps): JSX.Element => (
  <button
    type='button'
    className={addToCartButtonClasses}
    title={addToCartButtonTitle}
    aria-label={addToCartButtonTitle}
    onClick={onClickFunction}
    disabled={buttonIsDisabled}
    data-testid='AddToCartButton'>
    {buttonContent}
  </button>
);

export default AddToCartButton;
