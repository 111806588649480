import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {DebounceInput} from 'react-debounce-input';
import './_quantity-changer.scss';

import {translate} from '../../../../services';
import {isFunction, isNotUndefined, isUndefined} from '../../../../utils/validation';
import Icon from '../../../Reusable/Icon/Icon';


interface QuantityChangerProps {
  id?: number;
  orderId?: number;
  orderStatus?: string;
  quantity: number;
  isFetching: boolean;
  parentComponent: string;
  handleFabricQuantity?: (quantity: number) => void;
  handleOrderItemChangeQuantity?: (orderItemId: number, orderId: number, orderStatus: string, quantity: number) => void;
  handleOrderItemRemoveClicked?: () => void;
  singleFabricSizeAvailable?: boolean;
  completingQuantityInputUpdate?: (isComplete: boolean) => void;
  isDisabled?: boolean;
}

const QuantityChanger = ({
  quantity, id, orderId, orderStatus, isFetching, parentComponent, handleFabricQuantity, handleOrderItemChangeQuantity,
  handleOrderItemRemoveClicked, singleFabricSizeAvailable, completingQuantityInputUpdate, isDisabled
}: QuantityChangerProps): JSX.Element => {
  const [quantityState, setQuantityState] = useState(quantity);
  const quantityTimeout: ReturnType<typeof setTimeout | ((...args: never) => unknown)> = useRef(0);

  useEffect(() => {
    setQuantityState(quantity);
  }, [quantity]);

  // changes inside the input
  const handleOnChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => (
    processQuantity(parseInt(event.target.value, 10))
  );

  // changes by the buttons
  const handleOnClickQuantityButton = (value: 1 | -1) => {
    if (![1, -1].includes(value)) {
      throw new Error('value must be 1 or -1');
    }
    // increment/decrement
    const quantityCurrent = value === 1 ? quantityState + 1 : quantityState - 1;

    // don't allow user to click to a value below 0
    if (quantityCurrent < 0) {
      return false;
    }

    processQuantity(quantityCurrent);

    if (isNotUndefined(completingQuantityInputUpdate)) {
      completingQuantityInputUpdate && completingQuantityInputUpdate(false);

      clearTimeout(quantityTimeout.current);
      quantityTimeout.current = setTimeout(function() {
        completingQuantityInputUpdate(true);
      }, 500);
    }
  };

  const increment = () => (
    handleOnClickQuantityButton(1)
  );

  const decrement = () => (
    handleOnClickQuantityButton(-1)
  );

  // Handle the new quantity being set, set state and determine request to server
  const processQuantity = (quantityCurrent: number) => {
    // disallow use of non-numerical/null/undefined values
    if (quantityCurrent !== 0 && !quantityCurrent) {
      quantityCurrent = 1;
    }
    // don't allow a negative quantity
    if (quantityCurrent < 0) {
      quantityCurrent = Math.abs(quantityCurrent);
    }
    // auto-correct if above a set upper limit
    if (quantityCurrent > 99) {
      quantityCurrent = 99;
    }
    // auto-correct for context where deletion not allowed, therefore 0 not valid.
    if (quantityCurrent < 1 && isUndefined(handleOrderItemRemoveClicked)) {
      quantityCurrent = 1;
    }

    setQuantityState(quantityCurrent);

    return updateQuantityOrRemove(quantityCurrent);
  };

  // determine which methods to use to make request to server
  const updateQuantityOrRemove = (quantityCurrent: number) => {
    // delete order item if 0 quantity
    if (isFunction(handleOrderItemRemoveClicked) && quantityCurrent === 0) {
      handleOrderItemRemoveClicked();
    } else {
      (quantityCurrent !== quantityState) && updateQuantity(quantityCurrent);
    }
  };

  const updateQuantity = (quantityCurrent: number) => {
    if (!isFetching) {
      isFunction(handleFabricQuantity) && handleFabricQuantity(quantityCurrent);
      (isFunction(handleOrderItemChangeQuantity) && (id && orderId && orderStatus)) &&
      handleOrderItemChangeQuantity(id, orderId, orderStatus, quantityCurrent);
    }
  };

  const minAmount = handleOrderItemRemoveClicked ? 0 : 1;
  const isProductPage = parentComponent === 'productForm';
  const quantityChangerClasses = classNames('b-quantity-changer', {
    'x-fabric-page-quantity-changer': isProductPage,
  });

  return (
    <div className={quantityChangerClasses}>
      <label htmlFor='change-quantity-input' className='change-quantity-label visuallyhidden'>Quantity</label>
      <DebounceInput
        id='change-quantity-input'
        type='number'
        max={99}
        min={minAmount}
        step='1'
        debounceTimeout={500}
        onChange={handleOnChangeQuantity}
        value={singleFabricSizeAvailable ? 1 : quantityState}
        className='change-quantity-input'
        disabled={isFetching || singleFabricSizeAvailable || isDisabled}
        aria-required='true'
        required={true} />

      <button
        disabled={isFetching || singleFabricSizeAvailable || isDisabled}
        className='link-button quantity-button x-non-full-width-mobile-button up-button'
        type='button'
        aria-label={translate('fabric.content.higherQuantity')}
        title={translate('fabric.content.higherQuantity')}
        onClick={increment}>
        <Icon iconName='chevron-up'/>
      </button>
      <button
        disabled={isFetching || singleFabricSizeAvailable || isDisabled}
        className='link-button quantity-button x-non-full-width-mobile-button down-button'
        type='button'
        aria-label={translate('fabric.content.lowerQuantity')}
        title={translate('fabric.content.lowerQuantity')}
        onClick={decrement}>
        <Icon iconName='chevron-down'/>
      </button>
    </div>
  );
};

export default QuantityChanger;
