import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import {userFavoriteDesignsFetching, userFavoriteDesignsReceived, userFavoriteDesignsError} from '../../../actions/userFavoriteDesigns';
import request from '../../../application/api/request';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';


export interface UserFavoriteDesignsResponse {
  data: {
    user_favorites: Record<number, boolean>;
  };
}

export default function fetchUserFavoriteDesigns(): ThunkAction<Promise<void | UserFavoriteDesignsResponse> | void, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const store = getState();
    const {isFetching, favoriteDesigns} = store.userFavoriteDesigns;
    const collectionDesigns = store.collection.designDocs;
    const designId = store.design.id;
    const searchDesigns = store.searchResults.page_results;
    const collectionDesignIds = collectionDesigns ? collectionDesigns.map((d: { designId?: number }) => d.designId) : [];
    const searchDesignIds = searchDesigns ? searchDesigns.map((d: { designId?: number }) => d.designId) : [];
    const designIds: number[] =
      collectionDesignIds
        .concat(searchDesignIds, (designId ? [designId] : []))
        .filter(Boolean);
    const existingDesignIds = favoriteDesigns ? Object.keys(favoriteDesigns) : [];
    const missingDesignIds = designIds.filter((id: number) => !existingDesignIds.includes(String(id)));

    if (!isFetching && missingDesignIds.length > 0) {
      dispatch(userFavoriteDesignsFetching());

      return request<UserFavoriteDesignsResponse>(store,{
        url: `${selectAlpenroseHost(store)}/user_favorite/designs`,
        params: {
          design_ids: missingDesignIds,
        }
      })
        .then(({data}) => {
          dispatch(userFavoriteDesignsReceived(data));

          return data;
        })
        .catch((e: RequestError) => {
          dispatch(userFavoriteDesignsError());

          if (process.env.REACT_APP_IS_SERVER) {
            dispatch(serverError(e));
          } else {
            console.log('Error caught in the `fetchUserFavoriteDesigns` function', e); // eslint-disable-line
          }
        });
    }
  };
}
