/* eslint-disable complexity, max-lines */
import classNames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';
import React, {MouseEvent} from 'react';

import {FABRIC, FABRIC_BLANK, HOME_GOOD, SOLID, WALLPAPER} from '../../../constants/Codes';
import {EN} from '../../../constants/SupportedLanguages';
import {VIEWPORT_DESKTOP, VIEWPORT_SMALL_DESKTOP} from '../../../constants/Viewports';
import AllProductListContainer from '../../../containers/Design/AllProductsListContainer';
import DesignCrossSellListContainer from '../../../containers/Design/DesignCrossSellListContainer';
import ShippingInfoContainer from '../../../containers/Product/ShippingInfoContainer';
import {translate} from '../../../services';
import {CrossSellCollection, SolidCrossSellItem} from '../../../shapes/crossSell';
import {Locale} from '../../../shapes/locale';
import {MeasurementType} from '../../../shapes/measurement';
import {ProductTypeAbbreviated} from '../../../shapes/products';
import {TagsProps} from '../../../shapes/tags';
import {CommentPhotoData, PhotoData, ReviewPhotoData} from '../../../shapes/uploadedPhotos';
import {UserType} from '../../../shapes/user';
import {ViewportType} from '../../../shapes/viewport';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import {ErrorBoundary} from '../../Reusable/ErrorBoundary/ErrorBoundary';
import DesignCrossSellList from '../DesignCrossSellList/DesignCrossSellList';
import DesignerSection from '../DesignerSection/DesignerSection';
import DesignerInfoBox from '../InfoBoxes/DesignerInfoBox/DesignerInfoBox';
import DesignInfoBox from '../InfoBoxes/DesignInfoBox/DesignInfoBox';
import DiscountsInfoBox from '../InfoBoxes/DiscountsInfoBox/DiscountsInfoBox';
import HappinessGuaranteeInfoBox from '../InfoBoxes/HappinessGuaranteeInfoBox/HappinessGuaranteeInfoBox';
import SpoonflowerInfoBox from '../InfoBoxes/SpoonflowerInfoBox/SpoonflowerInfoBox';
import TradeInfoBox from '../InfoBoxes/TradeInfoBox/TradeInfoBox';
import ProductInfo from '../ProductInfo/ProductInfo';
import SolidsNavbar from '../SolidsNavbar/SolidsNavbar';
import UserSubmittedPhotos from '../UserSubmittedPhotos/UserSubmittedPhotos';

import './_product-info-grid.scss';


export interface ProductInfoGridProps {
  crossSellBarItems?: {[key: string]: {imageSrc: string; name: string; link: string}[]};
  crossSellFabricDesigns?: CrossSellCollection[];
  designerDescription?: string;
  designerId?: number;
  designerInfoImageFile?: string;
  designerInfoImageId?: number;
  designerPublicDesigns?: number;
  designerUserName?: string;
  designFileName?: string;
  designId?: number;
  designName?: string;
  designThumbnailURL?: string;
  disableUserFeedback?: boolean;
  fabricDetailsHtml?: { __html: string } | undefined;
  fabricPrice?: string;
  handleShopClick?: (pathName?: string) => void;
  homeGoodType?: string;
  isAvailableOnFabric?: boolean;
  isAvailableOnHomeGood?: boolean;
  isAvailableOnWallpaper?: boolean;
  isMugshot?: boolean;
  isRotatedFatQuarter?: boolean;
  locale: Locale;
  longDescription?: string;
  longDescriptionEmphazised?: string;
  longFabricDescription?: string;
  measurementSystem: MeasurementType;
  openUserUploadedImagesModal: (event: MouseEvent<HTMLButtonElement>, photoDataIndex: number, photoDataList: (CommentPhotoData | ReviewPhotoData)[]) => void;
  orderItemId?: number;
  productInfoRef?: React.LegacyRef<HTMLElement> | undefined;
  productSize?: string;
  productType: string;
  productTypeAbbreviated: ProductTypeAbbreviated;
  quantity?: number;
  sizeQuery?: string;
  rawItemPrice?: number;
  selectedSubstrate: string;
  shortDescription?: string;
  showMeasurement?: boolean;
  solidsPricePer?: number;
  tags?: TagsProps;
  trademarkType?: string;
  userId?: number;
  userSubmittedPhotoData: PhotoData[];
  userType?: UserType;
  viewport: ViewportType;
  wallpaperAvailableSubstrateThumbnail?: string;
  colorCode?: string;
  showOnlyProductInfo?: boolean;
  queryParams?: Record<string, string>;
  dispatchResetAllParams?: () => void;
  handleFaqButtonClick?: () => void;
}

const ProductInfoGrid = ({
  locale, selectedSubstrate, designerUserName, designerDescription, measurementSystem,
  shortDescription, longDescription, isMugshot, designerInfoImageId, designerInfoImageFile,
  designId, userId, isAvailableOnHomeGood, isRotatedFatQuarter,
  isAvailableOnWallpaper, designerId, viewport, designName, sizeQuery, designFileName,
  openUserUploadedImagesModal, tags, orderItemId, designerPublicDesigns, homeGoodType, userSubmittedPhotoData,
  isAvailableOnFabric, designThumbnailURL, fabricDetailsHtml, productType, productTypeAbbreviated, wallpaperAvailableSubstrateThumbnail,
  showMeasurement, rawItemPrice, quantity, disableUserFeedback, productInfoRef, crossSellBarItems, longFabricDescription,
  longDescriptionEmphazised, handleShopClick, fabricPrice, crossSellFabricDesigns,trademarkType, productSize, colorCode,
  queryParams, dispatchResetAllParams, showOnlyProductInfo
}: ProductInfoGridProps): JSX.Element => {
  const createInfoText = (className: string, introText: string, infoText: string) => (
    <p className={`${className}-description`}>
      <span className={`${className}-info-text heading`}>{introText}</span>
      <span className={`${className}-info-text`}>{infoText}</span>
    </p>
  );

  const renderProductInfoContent = () => {
    let productInfoTitle;
    let productInfoProduct;

    switch (productTypeAbbreviated) {
      case WALLPAPER:
        productInfoTitle = translate('wallpaper.content.productInfoTitle');
        productInfoProduct = translate(`fabricShop.${selectedSubstrate}.name`);
        break;
      case FABRIC:
      case FABRIC_BLANK:
        productInfoTitle = translate('fabric.aboutFabric.headline');
        productInfoProduct = translate(`fabricShop.${selectedSubstrate}.name`);
        break;
      case SOLID:
        productInfoTitle = translate('solidFabrics.productInfo.headline');
        productInfoProduct = translate(`solidFabrics.${selectedSubstrate}.name`);
        break;
      case HOME_GOOD:
        productInfoTitle = translate('homeGood.content.productInfoTitle');
        productInfoProduct = translate(`homeGood.${productType}.name`);
        break;
      default:
        throw new Error(`Unsupported productType[${productTypeAbbreviated}] scenario`);
    }

    return {
      'title': productInfoTitle,
      'product': productInfoProduct
    };
  };

  const navigateToShopPage = () => handleShopClick && handleShopClick();
  const navigateToFabricPage = () => handleShopClick && handleShopClick('shop.fabric');
  const gridClass = (placement: string) => classNames('grid-item', `grid-item-${placement}`);
  const displayUserSubmittedPhotos = userSubmittedPhotoData.length > 0;
  const spoonflowerInfoText = createInfoText('spoonflower', translate('fabric.aboutSpoonflower.intro'), translate('fabric.aboutSpoonflower.info'));
  const happinessGuaranteeInfoText = createInfoText('happiness-guarantee', translate('fabric.happinessGuarantee.intro'), translate('fabric.happinessGuarantee.info'));
  const isDesktop = [VIEWPORT_SMALL_DESKTOP, VIEWPORT_DESKTOP].includes(viewport);
  const isSolid = productType === SOLID && isNotUndefined(dispatchResetAllParams);
  const renderNavbar = !isSolid && (isNotUndefined(get(crossSellBarItems, `${selectedSubstrate}`)) || designerId || designId ||
    (isAvailableOnWallpaper || isAvailableOnHomeGood || isAvailableOnFabric));

  return (
    <article className='b-product-info-grid'>
      <div className='grid-item-center'>{renderNavbar &&
        <ErrorBoundary>
          <DesignerSection
            locale={locale}
            designerUserName={designerUserName}
            designerPublicDesigns={designerPublicDesigns}
            isMugshot={isMugshot}
            designerInfoImageId={designerInfoImageId}
            designerInfoImageFile={designerInfoImageFile}
            isAvailableOnWallpaper={isAvailableOnWallpaper}
            isAvailableOnHomeGood={isAvailableOnHomeGood}
            isAvailableOnFabric={isAvailableOnFabric}
            isRotatedFatQuarter={isRotatedFatQuarter}
            designId={designId}
            designerId={designerId}
            designFileName={designFileName}
            wallpaperAvailableSubstrateThumbnail={wallpaperAvailableSubstrateThumbnail}
            productType={productType}
            productTypeAbbreviated={productTypeAbbreviated}
            crossSellBarItems={crossSellBarItems}
            selectedSubstrate={selectedSubstrate}
            homeGoodType={homeGoodType}
          />
        </ErrorBoundary>}
      {isSolid && !showOnlyProductInfo &&
        <ErrorBoundary>
          <SolidsNavbar
            locale={locale}
            dispatchResetAllParams={dispatchResetAllParams ?? noop}
          />
        </ErrorBoundary>
      }
      <ErrorBoundary>
        {crossSellFabricDesigns && !isEmpty(crossSellFabricDesigns) &&
          <DesignCrossSellList
            crossSellDesigns={crossSellFabricDesigns}
            locale={locale}
            viewport={viewport}
            productType={productType}
            selectedSubstrate={selectedSubstrate}
            productTypeAbbreviated={productTypeAbbreviated}
            userId={userId}
          />
        }
        {(isEmpty(crossSellFabricDesigns) && productTypeAbbreviated !== FABRIC_BLANK) && !showOnlyProductInfo &&
          <DesignCrossSellListContainer
            locale={locale}
            designId={designId}
            productType={productType}
            selectedSubstrate={selectedSubstrate}
            productTypeAbbreviated={productTypeAbbreviated}
            productSize={productSize}
            sizeQuery={sizeQuery}
            rawItemPrice={rawItemPrice}
            homeGoodType={homeGoodType}
            quantity={quantity}
            colorCode={colorCode}
            queryParams={queryParams ?? {}}
            userId={userId}
          />
        }
      </ErrorBoundary>
      <ErrorBoundary>
        {productTypeAbbreviated !== FABRIC_BLANK && !showOnlyProductInfo &&
          <AllProductListContainer
            locale={locale}
            designId={designId}
            productType={productType}
            selectedSubstrate={selectedSubstrate}
            productTypeAbbreviated={productTypeAbbreviated}
            productSize={productSize}
            sizeQuery={sizeQuery}
            rawItemPrice={rawItemPrice}
            homeGoodType={homeGoodType}
            quantity={quantity}
            colorCode={colorCode}
            queryParams={queryParams ?? {}}
            userId={userId}
          />
        }
      </ErrorBoundary>
      </div>
      <div className='grid x-6633'>
        <div className={gridClass('left')}>
          <ErrorBoundary>
            <ProductInfo
              selectedSubstrate={selectedSubstrate}
              designId={designId}
              measurementSystem={measurementSystem}
              locale={locale}
              homeGoodType={homeGoodType}
              productType={productType}
              fabricDetailsHtml={fabricDetailsHtml}
              productInfoTitle={renderProductInfoContent().title}
              productInfoProduct={renderProductInfoContent().product}
              showMeasurement={showMeasurement}
              productInfoRef={productInfoRef}
              longFabricDescription={longFabricDescription}
              longDescriptionEmphazised={longDescriptionEmphazised}
              trademarkType={trademarkType}
              handleShopClick={handleShopClick}
              fabricPrice={fabricPrice}
              isSolid={isSolid}
              colorCode={colorCode}
              showOnlyProductInfo={showOnlyProductInfo}
            />
          </ErrorBoundary>
          <ErrorBoundary>
            <ShippingInfoContainer
              locale={locale}
              isDesktop={isDesktop}
            />
          </ErrorBoundary>
        </div>
        <div className={gridClass('right')}>
          {designThumbnailURL &&
            <ErrorBoundary>
              <DesignInfoBox
                shortDescription={shortDescription}
                longDescription={longDescription}
                collapsibleOpen={false}
                designName={designName}
                designThumbnailURL={designThumbnailURL}
                isDesktop={isDesktop}
                locale={locale}
                tags={tags}
                orderItemId={orderItemId}
              />
            </ErrorBoundary>}
          {designerUserName &&
             <ErrorBoundary>
               <DesignerInfoBox
                 designerUserName={designerUserName}
                 designerDescription={designerDescription}
                 isMugshot={isMugshot}
                 designerInfoImageId={designerInfoImageId}
                 designerInfoImageFile={designerInfoImageFile}
                 locale={locale}
                 isDesktop={isDesktop}
               />
             </ErrorBoundary>}
          <SpoonflowerInfoBox
            infoText={spoonflowerInfoText}
            isDesktop={isDesktop}
          />
          <HappinessGuaranteeInfoBox
            infoText={happinessGuaranteeInfoText}
            isDesktop={isDesktop}
            locale={locale}
          />
          {(productType === FABRIC_BLANK || isSolid) &&
            <React.Fragment>
              {locale === EN &&
                <TradeInfoBox isDesktop={isDesktop} />}
              <DiscountsInfoBox isDesktop={isDesktop} />
            </React.Fragment>}
          {displayUserSubmittedPhotos &&
            <ErrorBoundary>
              <UserSubmittedPhotos
                userSubmittedPhotoData={userSubmittedPhotoData}
                isDesktop={isDesktop}
                viewport={viewport}
                openUserUploadedImagesModal={openUserUploadedImagesModal}
              />
            </ErrorBoundary>}
        </div>
      </div>
      {(showOnlyProductInfo && isNotUndefined(handleShopClick)) &&
        <section className='call-to-action-buttons'>
          <button
            className='big-button white-button x-dark btn-explore-fabric x-non-full-width-mobile-button'
            type='button'
            onClick={navigateToFabricPage}>
            {translate('solidFabrics.content.exploreAllFabricTypes')}
          </button>
        </section>
      }
      {(productType === FABRIC_BLANK && isNotUndefined(handleShopClick) && !showOnlyProductInfo) &&
        <section className='call-to-action-buttons'>
          <button
            className='btn white-button x-dark btn-shop-designs x-non-full-width-mobile-button'
            type='button'
            onClick={navigateToShopPage}>
            {translate('fabric.fabricDetail.shopDesigns')}
          </button>
          <button
            className='btn white-button x-dark btn-select-different x-non-full-width-mobile-button'
            type='button'
            onClick={navigateToFabricPage}>
            {translate('fabric.fabricDetail.selectDifferentFabric')}
          </button>
        </section>}
    </article>
  );
};

export default ProductInfoGrid;
