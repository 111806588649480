import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './_inline-errors.scss';

class FieldInlineError extends Component {
  render() {
    const {message, classname} = this.props;
    const displayClassName = classname ? classname : '';

    return (
      <p className={`b-inline-errors ${displayClassName}`} role='alert' aria-live='polite'>{message}</p>
    );
  }
}

FieldInlineError.propTypes = {
  message: PropTypes.string,
  classname: PropTypes.string
};

export default FieldInlineError;
