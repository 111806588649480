import classNames from 'classnames';
import React from 'react';

import './_radio-group.scss';
// TODO: This component will not work with redux-form as it currently exists. Also, if it is modified to be compatible
// with redux-form, it will throw errors when used on forms that are not redux-forms (such as fabric-design).
// EN-1606

export type RadioItemType = {
  id: string;
  inputLabel: string;
  labelText?: string;
  value: string;
  isDefaultValue: boolean;
}

export interface RadioGroupProps {
  radios: RadioItemType[];
  name: string;
  handleOnChangeRadio: (value: string, id: string) => void;
  inputClassNames?: string[];
  labelClassNames?: string[];
  extensionClass?: string;
  verticalAlignment?: boolean;
}

const RadioGroup = ({radios, handleOnChangeRadio, name, inputClassNames, labelClassNames, extensionClass, verticalAlignment}: RadioGroupProps): JSX.Element => {
  const radioClasses = classNames('radio-group', extensionClass, {
    'x-vertical': verticalAlignment
  });

  const radioToggleOnClick = (event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeRadio(event.target.value, event.target.id);

  return (
    <div className={radioClasses}>
      {radios.map((option) => (
        <input key={option.id}
          type='radio'
          name={name}
          id={option.id}
          onChange={radioToggleOnClick}
          className={classNames('radio', inputClassNames)}
          value={option.value}
          checked={option.isDefaultValue}
        />
      ))}
      {radios.map((option) => (
        <label key={option.id} htmlFor={option.id} className={classNames('label', labelClassNames)} data-value={option.labelText}>
          {option.labelText}
        </label>
      ))}
    </div>
  );
};

export default RadioGroup;
