import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class SummaryAnswerList extends Component {
  render() {
    const {answer, heading} = this.props;

    return (
      <div className='b-summary-answer-list'>
        <h3 className='sub-heading'>{heading}</h3>
        <ul className='list-of-answers'>{answer}</ul>
      </div>
    );
  }
}

SummaryAnswerList.propTypes = {
  answer: PropTypes.array.isRequired,
  heading: PropTypes.string.isRequired
};
