export interface TurnaroundTime {
  STANDARD: TurnaroundOptions;
  RUSH?: TurnaroundOptions;
  GUARANTEE?: TurnaroundOptions;
}

export type TurnaroundRange = {
  min_days: number;
  max_days: number;
}

export type TurnaroundDays = {
  days: number;
}

export type TurnaroundOptions = TurnaroundRange | TurnaroundDays

export function hasTurnaroundDays(input: TurnaroundOptions): input is TurnaroundDays {
  return (input as TurnaroundDays).days !== undefined;
}
