import Translate from 'i18n-react';
import React from 'react';

import './_shipping-info.scss';
import {returnAndExchangesHelpUrl} from '../../../constants/Delivery';
import {translate} from '../../../services';
import {Locale} from '../../../shapes/locale';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';


export interface ShippingInfoProps {
  locale: Locale;
  heading: string;
  isDesktop: boolean;
}
const ShippingInfo = ({locale, heading, isDesktop}: ShippingInfoProps): JSX.Element => {
  // TODO: SP-8362 Refactor or extend translate utility.
  const guaranteeLinkTranslation = Translate.translate(`xurls.happinessGuarantee.${locale}`)?.toString() || '';
  const helpPageLinkTranslation = returnAndExchangesHelpUrl(locale)?.toString() || '';

  return (
    <article id='shipping-info-section' className='b-shipping-info'>
      {isDesktop &&
        <h3 className='h3'>{heading}</h3>}
      <p className='x-fat-border'>
        {translate('fabric.shippingInfo.content', {
          guaranteeLink: (
            <LinkWrapper
              hrefValue={guaranteeLinkTranslation}
              contents={translate('fabric.happinessGuarantee.title')}
              target='_blank'
              className='link-text' />
          ),
          helpPageLink: (
            <LinkWrapper
              hrefValue={helpPageLinkTranslation}
              contents={translate('fabric.shippingInfo.helpPageText')}
              target='_blank'
              className='link-text' />
          )
        })}
      </p>
    </article>
  );
};

export default ShippingInfo;
