import React, {SyntheticEvent} from 'react';

import {FABRIC, HOME_GOOD, WALLPAPER} from '../../../../constants/Codes';
import {FABRIC_PARAM, ORDER_ITEM_ID, SIZE} from '../../../../constants/Parameters';
import {ROUTING_PATH_FABRIC, ROUTING_PATH_WALLPAPER} from '../../../../constants/Products';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter} from '../../../../services';
import {Colorway} from '../../../../shapes/colorways';
import ImageUrls, {setPlaceholderImage, SMALL_THUMBNAIL_SIZE} from '../../../../utils/design';
import {upsertUrlQuery} from '../../../../utils/url';
import LazyImage from '../../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


interface ColorItemProps {
    colorway: Colorway;
    isSelected: boolean;
    productType: string;
    orderItemId?: number;
    sizeQuery?: string;
    fabricQuery?: string;
    productTypeAbbreviated?: string;
    index: number;
    pushProductClickToGTM?: (colorway: Colorway, index: number, productLink: string) => void;
  }

const ColorwayItem = ({colorway, isSelected, productTypeAbbreviated, productType, orderItemId, sizeQuery, fabricQuery, pushProductClickToGTM, index}: ColorItemProps): JSX.Element => {
  const colorItemClassName = isSelected ? 'colorway-item colorway-item-selected' : 'colorway-item';
  const currentOrigin = useOrigin();

  let multiLocalePathname = '';

  switch (productTypeAbbreviated) {
    case WALLPAPER:
      multiLocalePathname = ROUTING_PATH_WALLPAPER;
      break;
    case HOME_GOOD:
      multiLocalePathname = productType;
      break;
    default:
      multiLocalePathname = ROUTING_PATH_FABRIC;
  }

  const productLinkParam = {
    [ORDER_ITEM_ID]: orderItemId,
    [SIZE]: sizeQuery,
    [FABRIC_PARAM]: fabricQuery
  };

  const productLink = upsertUrlQuery(`${MultiLocaleRouter.localePathname(multiLocalePathname)}/${colorway.slug || colorway.id}`, productLinkParam, currentOrigin);
  const gardenHost = useApiHost(ApiHostsEnum.garden);
  const imagesHost = useApiHost(ApiHostsEnum.images);
  // TODO SP-15021 remove when new colorways API will be ready
  const imageUrl = isSelected && colorway.thumbnail.startsWith('http') ?
    colorway.thumbnail :
    ImageUrls.cipheredDesignThumbnail(gardenHost, colorway.id, colorway.thumbnail, SMALL_THUMBNAIL_SIZE, FABRIC);
  const onColorwayClick = () => pushProductClickToGTM?.(colorway, index, productLink);

  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);

  const linkInner = <div className={colorItemClassName}>
    <LazyImage
      aspectRatio={true}
      className='colorway-item-img'
      src={imageUrl}
      alt={colorway.title}
      aria-label={colorway.title}
      itemProp='image'
      onError={onErrorFunc}
      height='38'
      width='38'/>
  </div>;

  return (
    <div onClick={onColorwayClick} className='colorway-item-wrapper'>
      <LinkWrapper hrefValue={productLink} contents={linkInner} title={colorway.title} className='design-link' />
    </div>
  );
};

export default ColorwayItem;
