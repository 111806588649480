import React from 'react';

import {collectionUrlCreator} from '../../../../constants/Collections';
import {STACKED_THUMB_NAIL_SIZE, FEATURED_THUMB_NAIL_SIZE} from '../../../../constants/Design';
import {ORDER_ITEM_ID} from '../../../../constants/Parameters';
import {IS_FABRIC} from '../../../../constants/Products';
import {EN} from '../../../../constants/SupportedLanguages';
import useOrigin from '../../../../entities/pageSetup/origin/useOrigin';
import {translate} from '../../../../services';
import {DesignerCollectionDesigns} from '../../../../shapes/designerCollection';
import {Locale} from '../../../../shapes/locale';
import {DesignTags, InternationalTag} from '../../../../shapes/tags';
import ImageUrls, {SMALL_THUMBNAIL_SIZE} from '../../../../utils/design';
import {upsertUrlQuery} from '../../../../utils/url';
import {isEmpty, isNotUndefined, isUndefined} from '../../../../utils/validation';
import TextBox from '../../../Reusable/Content/TextBox/TextBox';
import StackedImages from '../../../Reusable/Images/StackedImages/StackedImages';
import LazyImage from '../../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';
import './_featured-in-collection.scss';


export interface FeaturedInCollectionProps {
  designerCollectionDesignFilename?: string;
  designerCollectionDesignId?: number;
  designerCollectionDesignName?: string;
  designerCollectionDesigns?: DesignerCollectionDesigns[];
  designerCollectionId?: number;
  designerScreenName: string;
  designFilename?: string;
  designId: number;
  designName: string;
  designTags?: DesignTags;
  designThumbnail: string;
  locale: Locale;
  orderItemId?: string;
  productTypeAbbreviated: string;
  productType: string;
  s3host: string;
  gardenHost: string;
}

const FeaturedInCollection = ({ // TODO: SP-8570 Split this component
  designId, designName, designThumbnail, designerScreenName, designerCollectionDesignId, designerCollectionDesignName, designerCollectionDesignFilename,
  orderItemId, locale, designFilename, designerCollectionDesigns, designTags, designerCollectionId, productTypeAbbreviated, productType, s3host, gardenHost
}: FeaturedInCollectionProps): JSX.Element => {
  const currentOrigin = useOrigin();
  const renderDesignThumbnail = (size: string, designId: number, designName: string, designFilename?: string, enciphered = false) => (
    <LazyImage
      height={size}
      width={size}
      alt={designName}
      className='featured-in-collection-img'
      src={enciphered ? designFilename : ImageUrls.designImageUrl(s3host, designId, designFilename).thumbnail}/>
  );

  const featuredInCollectionContent = () => (
    <>
      <StackedImages
        stackedImagesExtensionClass='x-stacked-images-small'
        thumbnailExtensionClass='x-thumbnail-small'
        size={STACKED_THUMB_NAIL_SIZE}
        firstDesignId={designId}
        secondDesignId={designerCollectionDesignId}
        firstDesignName={designName}
        secondDesignName={designerCollectionDesignName}
        firstDesignFileName={ImageUrls.cipheredDesignThumbnail(gardenHost, designId, designThumbnail, SMALL_THUMBNAIL_SIZE)}
        secondDesignFileName={designerCollectionDesignFilename}
        firstDesignEnciphered={true}
        secondDesignEnciphered={false}/>
      <TextBox
        headline={translate('designs.designerInfo.featuredInCollectionHeading')}
        mainText={translate('designs.designerInfo.designersCollectionText', {
          designer: designerScreenName
        })}
        extensionClass={'featured-in-collection-wrapper'}/>
    </>
  );

  const moreLikeThisContent = () => (
    <>
      <section className='featured-in-collection-image-wrapper'>
        <div className='featured-thumbnail'>
          {renderDesignThumbnail(FEATURED_THUMB_NAIL_SIZE, designId, designName, ImageUrls.cipheredDesignThumbnail(gardenHost, designId, designThumbnail, SMALL_THUMBNAIL_SIZE), true)}
        </div>
      </section>
      <TextBox
        headline={translate('designs.designerInfo.moreLikeThisHeading')}
        mainText={translate('designs.designerInfo.moreLikeThisText')}
        extensionClass={'featured-in-collection-wrapper'}/>
    </>
  );

  const productDetailsContent = () => (
    <>
      <section className='featured-in-collection-image-wrapper'>
        <div className='featured-thumbnail'>
          {renderDesignThumbnail(FEATURED_THUMB_NAIL_SIZE, designId, designName, designFilename)}
        </div>
      </section>
      <TextBox
        headline={IS_FABRIC(productTypeAbbreviated) ? translate('fabric.aboutFabric.headline') : translate('wallpaper.content.productInfoTitle')}
        mainText={translate('fabric.aboutFabric.learnMore')}
        extensionClass={'featured-in-collection-wrapper'}/>
    </>
  );
  const tagLinkUrl = (tag: InternationalTag) => (
    upsertUrlQuery(
      translate('tags.main.tagSlug', {
        tagSlug: tag.slug
      }),
      {
        [ORDER_ITEM_ID]: orderItemId
      },
      currentOrigin
    )
  );

  // With designs in collection
  if (!isEmpty(designerCollectionDesigns) && isNotUndefined(designerCollectionId)) {
    return <LinkWrapper
      className='b-featured-in-collection'
      hrefValue={collectionUrlCreator(designerCollectionId.toString(), true, productType)}
      contents={featuredInCollectionContent()}
      title={translate('designs.designerInfo.shopDesignersCollectionTitle', {
        designer: designerScreenName,
      })}
    />;
  }

  // With tags
  if (!isEmpty(designTags) && isNotUndefined(designTags)) {
    return <LinkWrapper
      className='b-featured-in-collection'
      hrefValue={
        isUndefined(designTags[locale]) ?
          tagLinkUrl(designTags[EN][0]) :
          tagLinkUrl(designTags[locale][0])
      }
      contents={moreLikeThisContent()}
      title={translate('designs.designerInfo.moreLikeThisTitle')}
    />;
  }

  // No tags, no designs in collection
  return <LinkWrapper
    className='b-featured-in-collection'
    hrefValue='#fabric-info-section'
    contents={productDetailsContent()}
    title={translate('fabric.aboutFabric.title')}
  />;
};

export default FeaturedInCollection;
