import React, {SyntheticEvent} from 'react';

import {collectionIdFromSlug, collectionUrlCreator} from '../../../constants/Collections';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import {translate, MultiLocaleRouter} from '../../../services';
import {setPlaceholderImage} from '../../../utils/design';
import {upsertUrlQuery} from '../../../utils/url';
import {isEmpty, isNotUndefined} from '../../../utils/validation';
import Icon from '../../Reusable/Icon/Icon';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_design-item.scss';


export type DesignItemFabricProps = {
  imageUrl: string;
  collectionName: string;
  collectionSlug?: string;
  searchParams?: string;
}

const DesignItemFabric = ({imageUrl, collectionSlug, collectionName, searchParams}: DesignItemFabricProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const currentOrigin = useOrigin();
  const searchUrlCreator = (searchParams: string) => upsertUrlQuery(`${MultiLocaleRouter.localePathname('shop.search')}?${searchParams}&sort=bestSelling`, {}, currentOrigin);
  const renderLinkAroundElement = (content: JSX.Element) => {
    let linkElement;

    if (searchParams) {
      // Fabric Detail page cross sell shop/search
      linkElement = <LinkWrapper hrefValue={searchUrlCreator(searchParams)} contents={content} title={collectionName} className='design-link' />;
    } else if (isNotUndefined(collectionSlug)) {
      // Fabric Detail page shop cross sell collections
      const collectionTitle = collectionName ? collectionName : translate('collections.links.designAlsoIn');

      linkElement = <LinkWrapper hrefValue={collectionUrlCreator(collectionSlug, true)} contents={content} title={collectionTitle} className='design-link' />;
    }

    return linkElement;
  };

  const titleProperty = collectionName ? translate('collections.links.collectionTilesTitle',{
    collectionName
  }) : translate('collections.userCollections.linkIconTitle');

  let linkValue = '';

  if (searchParams) {
    linkValue = searchUrlCreator(searchParams);
  } else if (isNotUndefined(collectionSlug)) {
    if (!isEmpty(collectionIdFromSlug(collectionSlug))) {
      linkValue = collectionUrlCreator(collectionIdFromSlug(collectionSlug), true);
    } else {
      linkValue = collectionUrlCreator(collectionSlug, true);
    }
  }

  const renderSubtextContent = () => (
    <LinkWrapper
      className='item-in-collection-link'
      hrefValue={linkValue}
      title={titleProperty}
      target='_blank'
      contents={
        <React.Fragment>
          <Icon iconName='collection-in'/>
          <span>{collectionName ? searchParams ? collectionName : collectionName : translate('collections.links.designAlsoIn')}</span>
        </React.Fragment>}
    />
  );

  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);

  const image = <LazyImage
    aspectRatio={true}
    className='item-pic'
    src={imageUrl}
    alt={titleProperty}
    aria-label={titleProperty}
    itemProp='image'
    onError={onErrorFunc}
    height='294'
    width='294'
    data-testid='design-item-image'/>;

  return (
    <div
      className='b-design-item x-large-cross-sell'
      itemProp='isRelatedTo'
      itemScope itemType='https://schema.org/Product'>
      {renderLinkAroundElement(image)}
      <div className='design-item-text'>
        {renderSubtextContent()}
      </div>
    </div>
  );
};

export default DesignItemFabric;
