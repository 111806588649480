import React from 'react';
import ReactImageMagnify from 'react-image-magnify';

import {FABRIC} from '../../../../constants/Codes';
import {FABRIC_CAROUSEL_LINK_TYPES} from '../../../../constants/Products';
import {handleImageTypes} from '../../../../utils/carouselHelpers';
import LazyImage from '../../LazyImage/LazyImage';

import ProductLink from './CarouselSlideLink';


interface CarouselSlideProps {
  index: number;
  imageSrcSmall: string;
  imageSrcLarge: string;
  imgAlt: string;
  imageHeight: string | number;
  imageWidth: string | number;
  children?: Record<string, unknown>;
  isProductPage: boolean;
  isMobile: boolean;
  zoomSize?: number;
  designId: number;
  productType: string;
}

const CarouselSlide = ({index, children, imageSrcSmall, imageSrcLarge, imgAlt, imageHeight, imageWidth, zoomSize, isProductPage, isMobile, designId, productType}: CarouselSlideProps): JSX.Element => {
  const RenderImage = () => {
    const zoomImage = <div>
      <ReactImageMagnify {...{
        enlargedImagePosition: 'over',
        className: 'fallback-image-wrapper',
        imageClassName: 'fallback-image small-carousel-image',
        enlargedImageClassName: 'large-carousel-image',
        isActivatedOnTouch: !isMobile,
        hoverDelayInMs: 250,
        isEnlargedImagePortalEnabledForTouch: true,
        smallImage: {
          alt: imgAlt,
          src: imageSrcSmall,
          isFluidWidth: false,
          width: handleImageTypes(imageWidth),
          height: handleImageTypes(imageHeight)
        },
        largeImage: {
          alt: imgAlt,
          src: imageSrcLarge,
          width: zoomSize || handleImageTypes(imageWidth) * 2,
          height: zoomSize || handleImageTypes(imageHeight) * 2
        }
      }}/>

    </div>;

    const lazyImage = (
      <div>
        <LazyImage
          alt={imgAlt}
          className='fallback-image'
          height={imageHeight}
          width={imageWidth}
          src={imageSrcSmall}
        />
      </div>

    );

    if (isProductPage) {
      return (isMobile && index !== 0) ? lazyImage : zoomImage;
    }

    return lazyImage;
  };

  return (
    <React.Fragment>
      <picture className='carousel-image' itemProp='image'>
        <RenderImage />
      </picture>
      {children}
      {(FABRIC_CAROUSEL_LINK_TYPES.includes(imgAlt) && productType === FABRIC) && <ProductLink imageWidth={imageWidth} designId={designId} imageType={imgAlt}/>}
    </React.Fragment>
  );
};

export default CarouselSlide;
