import {MeasurementSystem} from '../constants/Measurements';
import {GUEST, REGISTERED, UNAUTHENTICATED} from '../constants/userTypes';
import {UserDefaults} from '../entities/user/types';

import {Locale} from './locale';


export type UserType =
  | typeof UNAUTHENTICATED
  | typeof GUEST
  | typeof REGISTERED;

export interface UserPreferences {
  adult_content: string;
  country: string;
  locale: Locale;
  currency: string;
  measurement_system: MeasurementSystem;
}

interface BaseUser {
  cookies: { [k: string]: string };
  isFetching: boolean;
  newsletter_opt_in: boolean;
  preferences: UserPreferences;
  userType: UserType;
  userDefaults: UserDefaults;
  userHasJwt: boolean;
  userGroup: string;
  user_groups: number[];
  stayLegacyUser: boolean;
}

export interface UnauthenticatedUser extends BaseUser {
  userType: typeof UNAUTHENTICATED;
  id?: number;
  order_id_last_paid?: number;
}

export interface GuestUser extends BaseUser {
  business_tools: boolean;
  guest: true;
  id: number;
  order_id_last_paid?: number;
  order_id_pending: number;
  pro: boolean;
  spoondollars: {
    available: string;
    currency: string;
    raw_available: number;
  };
  userType: typeof GUEST;
}

export interface RegisteredUser extends BaseUser {
  business_tools: boolean;
  email: string;
  favorites?: {
    design_ids: number[];
    isFetching: boolean;
  };
  guest: false;
  id: number;
  order_id_last_paid?: number;
  order_id_pending: number;
  order_id_saved?: number;
  pro: boolean;
  screen_name: string;
  spoondollars: {
    available: string;
    currency: string;
    raw_available: number;
  };
  userType: typeof REGISTERED;
}

export type UserState = RegisteredUser | UnauthenticatedUser | GuestUser; // SP-11184 Todo we need to fix the type overloading here,
                                                                          // the conditional properties of GuesUser are overriding required properties of other types

type IdUsers = RegisteredUser | GuestUser;

export const hasUserId = (input: IdUsers | UnauthenticatedUser): input is IdUsers => (input as IdUsers).id !== undefined;

export const isRegisteredUser = (user: UserState): user is RegisteredUser => user.userType === REGISTERED;

export const isGuestUser = (user: UserState): user is GuestUser => user.userType === GUEST;

export const isUserWithCookie = (user: UserState): user is GuestUser | RegisteredUser => isRegisteredUser(user) || isGuestUser(user);

