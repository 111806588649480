import React from 'react';
import {times} from './math';
import Icon from '../components/Reusable/Icon/Icon';

export const getStarListItems = (rating) => {
  const listItems = [];

  times(rating)(() => {
    listItems.push(<li key={listItems.length} className='star-list-item'><Icon iconName='star-full'/></li>);
  });

  while (listItems.length < 5) {
    listItems.push(<li key={listItems.length} className='star-list-item'><Icon iconName='star-empty'/></li>);
  }

  return listItems;
};
