
import {name} from 'aws-sdk/clients/importexport';
import React from 'react';
import './_promo-info.scss';

import {Promo} from '../../../shapes/promo';
import Icon from '../Icon/Icon';


const PromoInfo = ({promoName}: {promoName: Promo[name] | string}): JSX.Element => (
  <div className='b-promo-info'>
    <Icon iconName='tag' />
    <span className='promo-name'>
      {promoName}
    </span>
  </div>);

export default PromoInfo;
