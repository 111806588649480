import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {setFabricShopSearch} from '../../../actions/fabrics';
import type {State} from '../../../store/initialState';
import SearchBox from '../SearchBox/SearchBox';


interface SubstrateShopSearchBoxContainerProps {
  inputPlaceholder: string;
  inputRefCallback?: (input: HTMLInputElement) => void;
  onButtonClick: () => void;
  onClearSearch: () => void;
  onInputKeyUp: (() => void) | ((event: React.KeyboardEvent<HTMLInputElement>) => void);
  searchWrapperFullWidth?: boolean;
}

const SubstrateShopSearchBoxContainer = ({
  inputPlaceholder,
  inputRefCallback,
  onButtonClick,
  onClearSearch,
  onInputKeyUp,
  searchWrapperFullWidth,
}: SubstrateShopSearchBoxContainerProps): JSX.Element => {
  const searchBoxValue = useSelector<State, string>((state) => state.fabrics.fabricShopSearch);
  const dispatch = useDispatch();

  const handleSetSearch = (fabricShopSearch: string) => {
    dispatch(setFabricShopSearch(fabricShopSearch));
  };

  return (
    <SearchBox
      clearSearch={onClearSearch}
      extensionClass='x-substrate-shop'
      inputPlaceholder={inputPlaceholder}
      inputRefCallback={inputRefCallback}
      inputText={searchBoxValue}
      onButtonClick={onButtonClick}
      onChangeInputText={handleSetSearch}
      onInputKeyUp={onInputKeyUp}
      searchWrapperFullWidth={searchWrapperFullWidth}
    />
  );
};

export default SubstrateShopSearchBoxContainer;
