import React from 'react';

import {translate} from '../../../services';
import AfterpayTeaserBox from '../AfterpayTeaserBox/AfterpayTeaserBox';
import Icon from '../Icon/Icon';
import './_afterpay-modal.scss';


export interface AfterpayModalProps {
  toggleModal: () => void;
  country: string;
}

const AfterpayModal = ({toggleModal, country}: AfterpayModalProps): JSX.Element => {
  const closeModal = () => toggleModal();

  const closeModalButton = (
    <button
      type='button'
      className='afterpay-modal-close-button'
      aria-label={translate('afterpay.modal.closeDetailModal')}
      autoFocus={true}
      onClick={closeModal}>
      <Icon iconName='close' extensionClass='x-icon-close'/>
    </button>
  );

  return (
    <div className='b-afterpay-modal' onClick={closeModal} role='dialog' aria-modal='true' aria-describedby='aria-afterpay-dialog' id='afterpay-modal'>
      <AfterpayTeaserBox
        closeModalButton={closeModalButton}
        country={country} />
    </div>
  );
};

export default AfterpayModal;
