import Translate from 'i18n-react';
import {translate} from '../services';
import {DE} from './SupportedLanguages';

export const RANGE_TYPE = 'RANGE';
export const HELP_LINK_EN = 'https://support.spoonflower.com/hc/en-us/articles/204444710-What-are-your-shipping-options';
export const HELP_LINK_DE = 'https://support.spoonflower.com/hc/de/articles/204444710';
export const ARRIVAL_DATE_TYPE = 'ARRIVAL_DATE';
export const SHIP_DATE_TYPE = 'SHIP_DATE';
export const STANDARD = 'STANDARD';
export const GUARANTEE = 'GUARANTEE';
export const RUSH = 'RUSH';
export const DEFAULT_MIN_SHIPPING_DAYS = 7;
export const DEFAULT_MAX_SHIPPING_DAYS = 10;

export const deliverySpeedConstants = {
  STANDARD: 'Standard',
  GUARANTEE: 'Guaranteed',
  RUSH: 'Rush'
};
export const deliverySpeedNames = {
  STANDARD: translate('checkout.delivery.deliveryStandard'),
  GUARANTEE: translate('checkout.delivery.deliveryGuaranteed'),
  RUSH: translate('checkout.delivery.deliveryRush')
};
export const shippingOptionsHelpUrl = (locale) => {
  if (locale === DE) {
    return HELP_LINK_DE;
  }

  return HELP_LINK_EN;
};
export const returnAndExchangesHelpUrl = (locale) => (
  Translate.translate(`xurls.about.${locale === DE ? 'returnsAndExchangesDe' : 'returnsAndExchanges'}`)
);

export const deliveryInfoTranslations = ['homeTextilesDeliveryInfo', 'homeTextilesArrivalTimeInfo', 'homeTextilesSplittingInfo'];
export const guaranteeAndRushDeliveryOptions = ['GUARANTEE', 'RUSH'];
