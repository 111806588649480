import Translate from 'i18n-react/dist/i18n-react';
import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {resetColorways} from '../../../../actions/colorways';
import {DATA_COLORWAYS_CATEGORIES, DATA_COLORWAYS_LIST_TYPE, DATA_FABRIC, DATA_HOME_GOOD, DATA_WALLPAPER} from '../../../../constants/DataLayer';
import {IS_FABRIC, IS_HOME_GOOD, IS_WALLPAPER} from '../../../../constants/Products';
import {translate} from '../../../../services';
import {Colorway} from '../../../../shapes/colorways';
import {ColorwaysProductTypeAbbreviated} from '../../../../shapes/products';
import {colorwayClickEvent, colorwaysImpressionsEvent, itemNameMask} from '../../../../utils/dataLayer';
import {getHomeGoodDepartment} from '../../../../utils/orderItemData';
import {isEmpty, isNotUndefined} from '../../../../utils/validation';
import Icon from '../../../Reusable/Icon/Icon';
import ColorwayItem from '../ProductFormColorwayItem/ColorwayItem';
import './_product-form-colors.scss';


interface ProductFormColorWaysProps {
  colorways: Colorway[];
  selectedColorway?: Colorway;
  productTypeAbbreviated?: string;
  productType: string;
  orderItemId?: number;
  sizeQuery?: string;
  fabricQuery?: string;
  rawItemPrice?: number;
  userId?: number;
  currency?: string;
  productSize?: string;
  designerUserName?: string;
}

const ProductFormColorways = ({
  colorways,
  selectedColorway,
  productTypeAbbreviated,
  productType,
  orderItemId,
  sizeQuery,
  fabricQuery,
  rawItemPrice,
  userId,
  currency,
  productSize,
  designerUserName
}: ProductFormColorWaysProps): JSX.Element => {
  const isMoreColorsAvailable = colorways.length > 1;
  const quantityOfMoreColors = colorways.length - 8;
  const isLongColorList = quantityOfMoreColors > 0;
  const [displayAllColors, setDisplayAllColors] = useState(() => colorways.findIndex((colorway) => colorway.id === selectedColorway?.id) > 7);
  const [productColorwaysPushedToGA, setProductColorwaysPushedToGA] = useState(false);
  const first8Colors = colorways.length > 8 ? colorways.slice(0, 8) : colorways;
  const displayedColors = displayAllColors ? colorways : first8Colors;
  const dispatch = useDispatch();

  useEffect(() => () => {
    dispatch(resetColorways());
  }, []);

  const onMoreClick = (): void => {
    setDisplayAllColors((displayAllColors) => !displayAllColors);
  };

  const getColorwaysDesignData = () => {
    const isHomeGood = IS_HOME_GOOD(productTypeAbbreviated);
    const isFabric = IS_FABRIC(productTypeAbbreviated);
    const isWallpaper = IS_WALLPAPER(productTypeAbbreviated);
    const designDepartment = isHomeGood && productType ? getHomeGoodDepartment(productType) : isFabric ? DATA_FABRIC : isWallpaper ? DATA_WALLPAPER : undefined;
    const designCategory = DATA_COLORWAYS_CATEGORIES[productTypeAbbreviated as ColorwaysProductTypeAbbreviated];
    const homeGoodCategory = Translate.translate(`en.homeGood.${productType}`);
    const designVariant = isNotUndefined(productSize) &&
      (isHomeGood ? Translate.translate(`en.homeGood.cart.productSize.${productSize}`) :
        (isFabric || isWallpaper) ? Translate.translate(`en.cart.cart.${productSize}`) : undefined);
    const itemCategories = designCategory === DATA_HOME_GOOD ? {
      item_category: designCategory,
      item_category2: designDepartment,
      item_category3: homeGoodCategory,
    } : {
      item_category: designCategory
    };

    return {
      designDepartment,
      designVariant,
      itemCategories
    };
  };

  const pushProductImpressionsToGTM = () => {
    const {
      designDepartment,
      designVariant,
      itemCategories
    } = getColorwaysDesignData();

    const designs = !isEmpty(colorways) ?
      colorways.map((colorway, index) => {
        const {title, id} = colorway;

        return {
          item_id: undefined, // TODO SP-15474: Investigate colorways item SKU from search API?, we only have access to the parent SKU
          item_name: itemNameMask(title),
          design_id: id,
          item_brand: designerUserName,
          ...itemCategories,
          department: designDepartment,
          item_list_name: DATA_COLORWAYS_LIST_TYPE,
          index: index + 1,
          price: rawItemPrice,
          quantity: undefined,
          item_variant: designVariant,
        };
      }) : [];

    if (!productColorwaysPushedToGA && !isEmpty(designs)) {
      setProductColorwaysPushedToGA(true);
      window.dataLayer.push(colorwaysImpressionsEvent(currency, designs, userId, DATA_COLORWAYS_LIST_TYPE));
    }
  };

  const pushProductClickToGTM = (colorway: Colorway, index: number, productLink: string) => {
    const {
      designDepartment,
      designVariant,
      itemCategories
    } = getColorwaysDesignData();
    const {title, id} = colorway;
    const position = index + 1;

    const eventData = {
      item_id: undefined, // TODO SP-15474: Investigate colorways item SKU from search API?, we only have access to the parent SKU
      item_name: itemNameMask(title),
      design_id: id,
      item_brand: designerUserName,
      ...itemCategories,
      department: designDepartment,
      item_list_name: DATA_COLORWAYS_LIST_TYPE,
      position,
      price: rawItemPrice,
      quantity: undefined,
      item_variant: designVariant,
    };

    window.dataLayer.push(colorwayClickEvent(eventData, currency, userId, DATA_COLORWAYS_LIST_TYPE));
  };

  !process.env.REACT_APP_IS_SERVER && pushProductImpressionsToGTM();

  const moreColorBtnInner = displayAllColors ?
    <>
      <Icon iconName='minus'/> show less
    </>:
    <>
      <Icon iconName='plus'/> {quantityOfMoreColors} more
    </>;

  return (
    <section className='colorway-list-wrapper'>
      <header className='colorway-list-header'>
        <h3 className='colorway-list-title'>{isMoreColorsAvailable ? translate('fabric.headlines.chooseColor') : translate('fabric.headlines.color')}</h3>
        {isLongColorList &&
        <h3
          className='colorway-list-title colorway-list-toggle'
          onClick={onMoreClick}
        >
          {moreColorBtnInner}
        </h3>}
      </header>
      <div className='colorway-list'>
        {displayedColors.map((colorway: Colorway, index) => (
          <ColorwayItem
            key={colorway.id}
            colorway={colorway}
            isSelected={selectedColorway?.id === colorway.id}
            productTypeAbbreviated={productTypeAbbreviated}
            productType={productType}
            orderItemId={orderItemId}
            sizeQuery={sizeQuery}
            fabricQuery={fabricQuery}
            pushProductClickToGTM={pushProductClickToGTM}
            index={index}
          />
        ))}
      </div>
    </section>
  );
};

export default ProductFormColorways;
