import classNames from 'classnames';
import React from 'react';

import {
  MAX_RATING, MATERIAL_RECEIVED_QUESTION, PROJECT_QUESTION, RECOMMENDATION_QUESTION, VOTE_DOWN, VOTE_UP,
  MATERIAL_RATING_QUESTION, PRINT_RATING_QUESTION
} from '../../../../constants/Reviews';
import {registeredUser} from '../../../../constants/userTypes';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {UserType} from '../../../../shapes/user';
import {dateFormat} from '../../../../utils/dateFormat';
import {countItemsInArray} from '../../../../utils/math';
import {getStarListItems} from '../../../../utils/reviews';
import {isFunction} from '../../../../utils/validation';
import FieldInlineError from '../../../Reusable/FormElements/FieldInlineError/FieldInlineError';
import LazyImage from '../../../Reusable/LazyImage/LazyImage';
import RatingBar from '../RatingBar/RatingBar';
import SummaryAnswerList from '../SummaryAnswerList/SummaryAnswerList';
import './_individual-review-list-item.scss';


interface DimensionsInterface {
  dimensionId: number;
  dimensionLabel: string;
  label: string[];
  type: number;
  value: number;
}

export interface ReviewInterface {
  id: number;
  media: {
    photo: [];
  };
  dimensions: DimensionsInterface[];
  dateCreated: Date;
  purchaseDate: Date;
  user: {
    nickName: string;
  };
  rating: number;
  upVotes: number;
  downVotes: number;
  title: string;
  text: string;
}

interface IndividualReviewListItemProps {
  review: ReviewInterface;
  hasAlreadyVoted?: boolean;
  locale: Locale;
  reviewTitle?: string;
  reviewText?: string;
  openPhotoModal: ((name: string, item: {thumbnailUrl: string; caption: string}) => void) | boolean;
  incrementVotes?: (designId: number | undefined, review: ReviewInterface, direction: string) => void;
  flagAsInappropriate?: (designId: number | undefined, review: ReviewInterface) => void;
  isPhotoModal?: boolean;
  userType?: UserType;
  designId?: number;
}

const IndividualReviewListItem = ({
  reviewTitle, review, reviewText, incrementVotes, flagAsInappropriate, locale, isPhotoModal, userType, hasAlreadyVoted,
  openPhotoModal, designId
}: IndividualReviewListItemProps): JSX.Element => {
  const isAnswerAvailable = (answerCollection: DimensionsInterface[], translation: string) => {
    const questionsAvailableList = answerCollection.map((answer) => (answer.dimensionLabel === translation) && answer.dimensionLabel);

    return questionsAvailableList.includes(translation);
  };

  const getAnswerLabel = (answerCollection: DimensionsInterface[], translation: string, countItemsInArray: ((answerLabelList: string[], item: string) => void) | boolean) => (
    answerCollection.map((answer) => {
      let listItem;

      if (Object.values(answer).includes(translation)) {
        const answerLabelList = answer.label;
        const answerLabelListWithoutDuplicates = Array.from(new Set(answerLabelList));

        listItem = answerLabelListWithoutDuplicates.map((item, index) => (
          <li key={index} className='answer-item'>
            <p className='answer'>{translate(`reviews.answerTranslations.${item}`)}</p>
            {isFunction(countItemsInArray) && <span className='answer-item-amount'>({countItemsInArray(answerLabelList, item)})</span>}
          </li>
        ));
      }

      return listItem;
    })
  );

  const arePhotosAvailable = (review: ReviewInterface) => (review.media.photo.length !== 0);

  const getPhotos = (review: ReviewInterface) => {
    const photos = review.media.photo;

    return photos.map((item: {thumbnailUrl: string; caption: string}, index: number) => {
      const handleOpenPhotoModal = () => !isPhotoModal && isFunction(openPhotoModal) && openPhotoModal('review-photos-modal', item);

      return (
        <li key={index} className='individual-review-photos'>
          <LazyImage className='review-photo-thumbnail'
            src={item.thumbnailUrl} width='100' height='100'
            onClick={handleOpenPhotoModal}
            alt={item.caption}
          />
        </li>
      );
    });
  };

  const getAnswerValue = (review: ReviewInterface, translation: string) => {
    for (let i = review.dimensions.length - 1; i >= 0; --i) {
      const answer = review.dimensions[i];

      if (Object.values(answer).includes(translation)) {
        return answer.value;
      }
    }

    return 0;
  };

  const answerCollection = review.dimensions;
  const reviewCreated = review.dateCreated;
  const purchased = review.purchaseDate;
  const userName = review.user.nickName;
  const materialAnswerValue = getAnswerValue(review, MATERIAL_RATING_QUESTION);
  const materialAnswerAverage = materialAnswerValue / MAX_RATING * 100;
  const printAnswerValue = getAnswerValue(review, PRINT_RATING_QUESTION);
  const printAnswerAverage = printAnswerValue / MAX_RATING * 100;
  const materialAnswerValueIsNull = materialAnswerValue === 0;
  const printAnswerValueIsNull = printAnswerValue === 0;
  const isRegisteredUser = userType && registeredUser(userType);
  const reviewSectionClasses = classNames('review-section-right', {
    'x-error': hasAlreadyVoted
  });

  const handleIncrementVote = () => {
    isFunction(incrementVotes) && incrementVotes(designId, review, VOTE_UP);
  };

  const handleDecrementVote = () => {
    isFunction(incrementVotes) && incrementVotes(designId, review, VOTE_DOWN);
  };

  const handleFlagAsInappropriate = () => {
    isFunction(flagAsInappropriate) && flagAsInappropriate(designId, review);
  };

  return (
    <li className='b-individual-review-list-item'>
      <section className='review-section-top'>
        <ul className='individual-stars'>{getStarListItems(review.rating)}</ul>
      </section>
      <section className='review-section-left'>
        {isAnswerAvailable(answerCollection, MATERIAL_RECEIVED_QUESTION) && <SummaryAnswerList
          heading={translate('reviews.reviews.materialReceivedQuestion')}
          answer={getAnswerLabel(answerCollection, MATERIAL_RECEIVED_QUESTION, countItemsInArray)}/>}
        {!materialAnswerValueIsNull && <RatingBar
          heading={translate('reviews.reviews.materialQuestion')}
          contentWidth={materialAnswerAverage}
          ratingAmount={`${materialAnswerValue} / ${MAX_RATING}`}/>}
        {!printAnswerValueIsNull && <RatingBar
          heading={translate('reviews.reviews.printQuestion')}
          contentWidth={printAnswerAverage}
          ratingAmount={`${printAnswerValue} / ${MAX_RATING}`}/>}
        {isAnswerAvailable(answerCollection, PROJECT_QUESTION) && <SummaryAnswerList
          heading={translate('reviews.reviews.projectQuestion')}
          answer={getAnswerLabel(answerCollection, PROJECT_QUESTION, countItemsInArray)}/>}
        {isAnswerAvailable(answerCollection, RECOMMENDATION_QUESTION) && <SummaryAnswerList
          heading={translate('reviews.reviews.recommendationQuestion')}
          answer={getAnswerLabel(answerCollection, RECOMMENDATION_QUESTION, false)}/>}
      </section>
      <section className={reviewSectionClasses}>
        <h3 className='sub-heading'>{reviewTitle}</h3>
        <p className='review-text'>{reviewText}</p>
        <time dateTime={dateFormat(locale, reviewCreated).toString()} className='time-created'>{dateFormat(locale, reviewCreated).toString()}</time>
        <div className='user-name'>{userName}</div>
        {purchased && dateFormat(locale, purchased) && <div className='time-purchased'>
          {translate('reviews.reviews.itemPurchased')} <time dateTime={dateFormat(locale, purchased)}>{dateFormat(locale, purchased)}</time>
        </div>}
        {!isPhotoModal && isRegisteredUser && <React.Fragment>
          <h3 className='sub-heading'>{translate('reviews.reviews.reviewHelpfulQuestion')}</h3>
          {hasAlreadyVoted && <FieldInlineError message={translate('errors.inline.userAlreadyVoted')}/>}
          <ul className='list-of-answers'>
            <li className='evaluate-review-answer'>
              <button type='button' className='evaluation-btn' onClick={handleIncrementVote}>
                {translate('reviews.reviews.positiveAnswer')}
              </button>
              <span className='amount-of-answers'>({review.upVotes})</span>
            </li>
            <li className='evaluate-review-answer'>
              <button type='button' className='evaluation-btn' onClick={handleDecrementVote}>
                {translate('reviews.reviews.negativeAnswer')}
              </button>
              <span className='amount-of-answers'>({review.downVotes})</span>
            </li>
            <li className='evaluate-review-answer'>
              <button type='button' className='evaluation-btn' onClick={handleFlagAsInappropriate}>
                {translate('reviews.reviews.flagAsInappropriate')}
              </button>
            </li>
          </ul>
        </React.Fragment>}
      </section>
      {arePhotosAvailable(review) &&
      <ul className='review-photos-section'>
        {getPhotos(review)}
      </ul>}
    </li>
  );
};

export default IndividualReviewListItem;
