import {MultiLocaleRouter} from '../../../services';
import {FabricShopCategory, LocationQuery} from '../../../store/initialState';
import {upsertUrlQuery} from '../../../utils/url';
import {isNotUndefined} from '../../../utils/validation';


export const getAlternateLanguageUrls = (
  categories: Record<string, Record<string, FabricShopCategory>>,
  locale: string,
  category: string | undefined,
  locationQuery: LocationQuery,
  currentOrigin: string,
): Record<string, string> => {
  const categoryTag = Object.values(categories[locale])
    .find((tag) => (tag.slug === category)) || {} as Partial<FabricShopCategory>;

  const allCategories = Object.values(categories).reduce((results, localeCategory) => (Object.assign(results, localeCategory)), {});
  const translationParent = (categoryTag.best_source_id && allCategories[categoryTag.best_source_id]) || {} as Partial<FabricShopCategory>;

  return Object.keys(categories).reduce((results, categoryLocale) => {
    if (categoryLocale === locale) {
      return results;
    }
    const alternateLanguageTag = Object.values(categories[categoryLocale]).find((categoryByLocale) => (
      categoryByLocale?.id === categoryTag?.id ||
      categoryByLocale.best_source_id === categoryTag.id ||
      (isNotUndefined(translationParent.id) &&
        (categoryByLocale.best_source_id === translationParent.id || categoryByLocale.id === translationParent.id))
    ));

    if (isNotUndefined(alternateLanguageTag) && isNotUndefined(alternateLanguageTag.slug)) {
      results[categoryLocale] = upsertUrlQuery(
        `${MultiLocaleRouter.localePathname('shop.fabric', categoryLocale)}/${alternateLanguageTag.slug}`, locationQuery || {}, currentOrigin
      );
    }

    return results;
  }, {} as Record<string, string>);
};
