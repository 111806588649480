import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './_rating-bar.scss';

export default class RatingBar extends Component {
  render() {
    const {heading, contentWidth, ratingAmount} = this.props;

    return (
      <div className='b-rating-bar'>
        {heading && <h3 className='sub-heading'>{heading}</h3>}
        <div className='rating-bar'>
          <span
            className='rating-bar-content'
            role='progressbar'
            aria-valuenow={contentWidth}
            aria-valuemin='0'
            aria-valuemax='100'
            style={{
              width: `${contentWidth}%`
            }}></span>
        </div>
        <span className='rating-amount'>{ratingAmount}</span>
      </div>
    );
  }
}

RatingBar.propTypes = {
  contentWidth: PropTypes.number.isRequired,
  ratingAmount: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  heading: PropTypes.string
};
