import React from 'react';

import {measurementSystemToUnit} from '../../../../constants/Measurements';
import {translate} from '../../../../services';
import {MeasurementType} from '../../../../shapes/measurement';
import {SimplePricing} from '../../../../shapes/pricing';
import {defaultBasePrice} from '../../../../utils/fabrics';
import {isNotUndefined} from '../../../../utils/validation';
import ItemPrice from '../../../Reusable/ItemPrice/ItemPrice';
import PromoInfo from '../../../Reusable/PromoInfo/PromoInfo';
import './_product-form-price.scss';


export interface ProductFormPriceProps {
  rawItemPrice?: number;
  rawItemPriceUndiscounted?: number;
  rawItemPriceAddition?: number;
  rawItemPriceAdditionUndiscounted?: number;
  discountsApplied?: boolean;
  measurementSystem: MeasurementType;
  currency: string;
  taxApplied: boolean;
  homeGoodType?: string;
  additionTotalPriceMap?: SimplePricing;
  selectedAddition?: string;
  pricePerUnit?: number;
  promoName?: string;
}


const ProductFormPrice = ({
  additionTotalPriceMap, pricePerUnit, currency, measurementSystem, taxApplied, selectedAddition, homeGoodType,
  rawItemPrice, rawItemPriceUndiscounted, rawItemPriceAddition, rawItemPriceAdditionUndiscounted, discountsApplied,
  promoName
}: ProductFormPriceProps): JSX.Element => (
  <article className='b-product-form-price'>
    <ItemPrice
      extensionClass='x-price-product'
      rawPrice={rawItemPrice}
      rawPriceUndiscounted={rawItemPriceUndiscounted}
      rawItemPriceAddition={rawItemPriceAddition}
      rawItemPriceAdditionUndiscounted={rawItemPriceAdditionUndiscounted}
      discountsApplied={discountsApplied}
      currency={currency}
      addition={selectedAddition} />
    <em className='secondary-information'>
      {isNotUndefined(pricePerUnit) &&
      <span className='unit-price'>{translate('measurements.priceInfo', {
        price: defaultBasePrice(pricePerUnit, currency),
        unit: translate(`measurements.${measurementSystemToUnit[measurementSystem]}`)
      })}</span>}
      {taxApplied &&
      <span className='vat-info'>{translate('fabric.content.infoVAT')}</span>}
      {(selectedAddition && isNotUndefined(additionTotalPriceMap)) &&
      Object.keys(additionTotalPriceMap).map((entry, index) =>
        <span className='addition' key={index}>{translate(`homeGood.${homeGoodType}.additions.${entry}`)}</span>)
      }
    </em>
    {isNotUndefined(promoName) &&
      <PromoInfo
        promoName={promoName}
      />}
  </article>
);

export default ProductFormPrice;
