import React from 'react';

import SwiperDesignContainer from '../../../../containers/Design/SwiperDesignContainer';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import getNoImageUrl from '../../../../images/getNoImageUrl';
import {translate} from '../../../../services';
import {MeasurementType} from '../../../../shapes/measurement';
import {ProductTypeAbbreviated} from '../../../../shapes/products';
import {isUndefined, objectIsNotEmpty} from '../../../../utils/validation';
import DesignDetailImage from '../../../Reusable/Images/DesignDetailImage/DesignDetailImage';


interface ProductFormCarouselProps {
  measurementSystem: MeasurementType;
  selectedSubstrate: string;
  designImages?: Record<string, unknown>;
  carouselImages?: Record<string, unknown>;
  solidImages?: Record<string, unknown>;
  homeGoodType?: string;
  productSize?: string;
  productTypeAbbreviated?: ProductTypeAbbreviated;
  wallpaperType?: string;
  hasSquareImages?: boolean;
  swiperParams?: Record<string, unknown>;
  designName?: string;
}

const ProductFormCarousel = ({
  designImages,
  carouselImages,
  solidImages,
  homeGoodType,
  measurementSystem,
  productSize,
  productTypeAbbreviated,
  wallpaperType,
  selectedSubstrate,
  hasSquareImages,
  swiperParams,
  designName,
}: ProductFormCarouselProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    objectIsNotEmpty(designImages) ||
    !(homeGoodType && designImages && isUndefined(designImages[homeGoodType])) ||
    objectIsNotEmpty(carouselImages)
  ) ? (
      <SwiperDesignContainer
        measurementSystem={measurementSystem}
        parentComponent='productForm'
        homeGoodProductSize={productSize}
        productTypeAbbreviated={productTypeAbbreviated}
        homeGoodType={homeGoodType}
        wallpaperType={wallpaperType}
        selectedSubstrate={selectedSubstrate}
        hasSquareImages={hasSquareImages}
        swiperParams={swiperParams}
        carouselImages={carouselImages}
        solidImages={solidImages}
      />
    ) : (
      <DesignDetailImage
        imageAlternateText={translate('errors.NO_IMAGE_AVAILABLE', {
          designName,
        })}
        imgSrc={getNoImageUrl(imagesHost)}
        extensionClasses={'x-no-image'}
      />
    );
};

export default ProductFormCarousel;
