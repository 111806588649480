import React, {useState, SyntheticEvent} from 'react';

import {FABRIC, HOME_GOOD, WALLPAPER} from '../../../../constants/Codes';
import {PRODUCT_QUERY_KEY, PRODUCT_QUERY_KITCHEN, PRODUCT_QUERY_LIVING} from '../../../../constants/Parameters';
import {COLOR_MAP_ID, ROUTING_PATH_FABRIC, ROUTING_PATH_WALLPAPER} from '../../../../constants/Products';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {MultiLocaleRouter, translate} from '../../../../services';
import {ProductTypeAbbreviated} from '../../../../shapes/products';
import ImageUrls from '../../../../utils/design';
import LazyImage from '../../../Reusable/LazyImage/LazyImage';
import AvailableSubstratesLinks from '../AvailableSubstratesLinks/AvailableSubstratesLinks';
import './_available-substrates.scss';


export interface AvailableSubstratesProps {
  designFileName?: string;
  designId?: number;
  homeGoodType?: string;
  isAvailableOnFabric?: boolean;
  isAvailableOnHomeGood?: boolean;
  isAvailableOnWallpaper?: boolean;
  isRotatedFatQuarter?: boolean;
  productTypeAbbreviated: ProductTypeAbbreviated;
  wallpaperAvailableSubstrateThumbnail?: string;
}

const AvailableSubstrates = ({
  designId, designFileName, isAvailableOnWallpaper, isAvailableOnHomeGood, isAvailableOnFabric,
  wallpaperAvailableSubstrateThumbnail, productTypeAbbreviated, isRotatedFatQuarter, homeGoodType
}: AvailableSubstratesProps): JSX.Element => {
  const [imageLoadError, setImageLoadError] = useState(true);
  const s3host = useApiHost(ApiHostsEnum.s3);

  const onError = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    const designThumbnailUrl = ImageUrls.designImageUrl(s3host, designId, designFileName).thumbnail;

    if (imageLoadError && event) {
      setImageLoadError(false);
      // Adding src as a property to the error for logging
      (event.target as typeof event.target & {src: string}).src = designThumbnailUrl;
    }
  };

  const allProductsFabricUrl = `${MultiLocaleRouter.localePathname(ROUTING_PATH_FABRIC)}/${designId}`;
  const allProductsWallpaperUrl = `${MultiLocaleRouter.localePathname(ROUTING_PATH_WALLPAPER)}/${designId}`;
  const allProductsHomeGoodsUrl = `${MultiLocaleRouter.localePathname('all_products')}/${designId}?${PRODUCT_QUERY_KEY}=${PRODUCT_QUERY_KITCHEN}`;
  const allProductsLivingUrl = `${MultiLocaleRouter.localePathname('all_products')}/${designId}?${PRODUCT_QUERY_KEY}=${PRODUCT_QUERY_LIVING}`;
  const isColorMap = (designId === Number(COLOR_MAP_ID));
  const colorMapUrl = translate('navigation.designTools.href');
  const showFabric = productTypeAbbreviated !== FABRIC && isAvailableOnFabric;
  const showWallpaper = productTypeAbbreviated !== WALLPAPER && isAvailableOnWallpaper && !isColorMap;
  const showHomeGood = productTypeAbbreviated !== HOME_GOOD && isAvailableOnHomeGood && !isColorMap;
  const isAvailableOnWallpaperAndHomeGood = showWallpaper && showHomeGood;
  const isAvailableOnWallpaperOrHomeGood = (showWallpaper && !showHomeGood) || (!showWallpaper && showHomeGood);
  const conjunction = translate('designs.otherSubstrateTextConjunction');

  return (
    <article className='b-available-substrates'>
      <div className='available-products'>
        <LazyImage
          height='80'
          width='80'
          alt={translate('designs.otherSubstrateWallpaper')}
          className='available-substrates-img'
          src={wallpaperAvailableSubstrateThumbnail}
          onError={onError}
        />
      </div>
      <AvailableSubstratesLinks
        allProductsFabricUrl={allProductsFabricUrl}
        allProductsHomeGoodsUrl={allProductsHomeGoodsUrl}
        allProductsLivingUrl={allProductsLivingUrl}
        allProductsWallpaperUrl={allProductsWallpaperUrl}
        colorMapUrl={colorMapUrl}
        conjunction={conjunction}
        homeGoodType={homeGoodType}
        isAvailableOnFabric={isAvailableOnFabric}
        isAvailableOnHomeGood={isAvailableOnHomeGood}
        isAvailableOnWallpaper={isAvailableOnWallpaper}
        isAvailableOnWallpaperAndHomeGood={isAvailableOnWallpaperAndHomeGood}
        isAvailableOnWallpaperOrHomeGood={isAvailableOnWallpaperOrHomeGood}
        isColorMap={isColorMap}
        isRotatedFatQuarter={isRotatedFatQuarter}
        showFabric={showFabric}
        showHomeGood={showHomeGood}
        showWallpaper={showWallpaper}
      />
    </article>
  );
};

export default AvailableSubstrates;
