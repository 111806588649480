import classNames from 'classnames';
import React from 'react';

import './_product-info.scss';
import {FABRIC_BLANK, HOME_GOOD_DUVET_COVER, HOME_GOOD_SHEET_SET, HOME_GOOD_STANDARD_PILLOW_SHAM} from '../../../constants/Codes';
import {measurementSystemToUnit, METER, YARD} from '../../../constants/Measurements';
import {fabricCertificateImage} from '../../../constants/Products';
import {EN} from '../../../constants/SupportedLanguages';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../services';
import {Locale} from '../../../shapes/locale';
import {MeasurementType} from '../../../shapes/measurement';
import {getMeasurement} from '../../../utils/fabrics';
import {isEmpty} from '../../../utils/validation';
import {ErrorBoundary} from '../../Reusable/ErrorBoundary/ErrorBoundary';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import ProductInfoFabricDetails from '../ProductInfoFabricDetails/ProductInfoFabricDetails';


export interface ProductInfoProps {
  measurementSystem: string;
  locale: Locale;
  selectedSubstrate: string;
  designId?: number;
  productInfoTitle: string;
  productInfoProduct: string;
  homeGoodType?: string;
  productType: string;
  fabricDetailsHtml?: {__html: string} | undefined;
  showMeasurement?: boolean;
  productInfoRef?: React.LegacyRef<HTMLElement> | undefined;
  longFabricDescription?: string;
  longDescriptionEmphazised?: string;
  trademarkType?: string;
  handleShopClick?: (pathName?: string) => void;
  fabricPrice?: string;
  isSolid?: boolean;
  colorCode?: string;
  showOnlyProductInfo?: boolean;
}

// eslint-disable-next-line complexity
const ProductInfo = ({
  selectedSubstrate, designId, measurementSystem, locale, productInfoTitle, productInfoProduct, homeGoodType, productType,
  fabricDetailsHtml, showMeasurement, productInfoRef, longFabricDescription, longDescriptionEmphazised, handleShopClick,
  fabricPrice, trademarkType, isSolid, colorCode, showOnlyProductInfo
}: ProductInfoProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const isFabricDetailPage = productType === FABRIC_BLANK;
  const unit = measurementSystemToUnit[measurementSystem as MeasurementType];
  const homeGoodsDetailsListHtml = {
    __html: `<ul class='circle-list'>${translate(`homeGood.${homeGoodType}.details`)}</ul>`
  };
  const homeGoodsLongDescriptionHtml = {
    __html: `<ul class='long-description'>${translate(`homeGood.${homeGoodType}.longDescription`)}</ul>`
  };

  const homeGoodsCareListHtml = {
    __html: translate(`homeGood.${homeGoodType}.careList`)
  };

  const fabricNameClasses = classNames('fabric-name', {
    'x-large': isFabricDetailPage
  });
  const fabricWidthIsDefined = Number.isInteger(translate(`fabricShop.${selectedSubstrate}.fabricWidth.${unit}`));
  const translationFileName = isSolid ? 'solidFabrics' : homeGoodType ? 'homeGoodFabrics' : 'fabricShop';
  const hasCareBlock = homeGoodType === HOME_GOOD_DUVET_COVER || homeGoodType === HOME_GOOD_SHEET_SET || homeGoodType === HOME_GOOD_STANDARD_PILLOW_SHAM;

  const renderDescriptionDetailPoints = (propertyList: string[]) => (
    propertyList.map((property, index) => {
      const descriptionTitle = translate(`fabric.fabricDetail.${property}`);
      let descriptionText = translate(`${translationFileName}.${selectedSubstrate}.${property}`);

      if (isSolid && !showOnlyProductInfo && property === 'color') {
        descriptionText = translate(`solidColors.${colorCode}.description`);
      }

      return (
        !descriptionText.includes(`.${property}`) &&
        <React.Fragment key={index}>
          <dt className='description-title'>{descriptionTitle}</dt>
          <dd className='description-text'>{descriptionText}</dd>
        </React.Fragment>
      );
    })
  );

  return (
    <article className='b-product-info' id='fabric-info-section' ref={productInfoRef}>
      <h3 className='h3'>{productInfoTitle}</h3>
      <div className='fabric-characteristics x-fat-border'>
        <div className='fabric-headline'>
          <h3 className={fabricNameClasses}>
            {productInfoProduct}
            {!isEmpty(trademarkType) &&
              <sup className='trade-mark'>{trademarkType}</sup>}
          </h3>
          {designId &&
            <span className='design-number' itemProp='productId'>{translate('fabric.aboutFabric.designNr', {
              designId
            })}</span>}
        </div>
        {homeGoodType &&
          <React.Fragment>
            <div dangerouslySetInnerHTML={homeGoodsDetailsListHtml} />
            <div dangerouslySetInnerHTML={homeGoodsLongDescriptionHtml} />
          </React.Fragment>}

        {
          hasCareBlock &&
          <section className='fabric-care space-top'>
            <strong>{translate('homeGood.content.care')}</strong>
            <ul className='circle-list'>
              <div dangerouslySetInnerHTML={homeGoodsCareListHtml} />
            </ul>
          </section>
        }

        {showMeasurement &&
          <span className='secondary-information'>{translate(`fabricShop.SIZE.${unit}`, {
            width: getMeasurement(selectedSubstrate, unit, 'printableWidth', locale),
            weight: getMeasurement(selectedSubstrate, unit, 'weight', locale)
          })}</span>}

        {homeGoodType &&
          <strong className='fabric-type'>
            <span className='fabric-title'>{translate('fabric.headlines.fabric')}</span>
            {translate(`fabricShop.${selectedSubstrate}.name`)}
          </strong>}

        <section className='fabric-description'>
          {isSolid ?
            <p>{translate(`solidFabrics.${selectedSubstrate}.description.preLink`)}
              <LinkWrapper
                hrefValue={translate(`solidFabrics.${selectedSubstrate}.description.hrefValue`)}
                contents={translate(`solidFabrics.${selectedSubstrate}.description.linkText`)}
                className='link-text' />
              {translate(`solidFabrics.${selectedSubstrate}.description.postLink`)}</p> :
            <p>
              {!isEmpty(longFabricDescription) ?
                longFabricDescription :
                translate(`${translationFileName}.${selectedSubstrate}.description`)}
              {longDescriptionEmphazised && !longDescriptionEmphazised.includes('.longDescriptionEmphazised') &&
                <em className='description-highlighted'>{longDescriptionEmphazised}</em>}
            </p>
          }

          {(isFabricDetailPage || isSolid) &&
            <dl>
              {renderDescriptionDetailPoints(['color', 'useCases', 'careInstructions', 'countryOrigin', 'printing'])}
            </dl>}
        </section>

        {(fabricDetailsHtml && !isFabricDetailPage && !isSolid) &&
          <div className='fabric-details' dangerouslySetInnerHTML={fabricDetailsHtml} />}

        {(isFabricDetailPage || isSolid) &&
          <ErrorBoundary>
            <ProductInfoFabricDetails
              fabricPrice={fabricPrice}
              handleShopClick={handleShopClick}
              printableWidth={fabricWidthIsDefined ? translate('fabricShop.SIZE_WIDTH_UNIVERSAL', {
                widthIn: getMeasurement(selectedSubstrate, YARD, 'printableWidth', locale),
                widthCm: getMeasurement(selectedSubstrate, METER, 'printableWidth', locale)
              }) : undefined}
              fabricWidth={translate('fabricShop.SIZE_WIDTH_UNIVERSAL', {
                widthIn: getMeasurement(selectedSubstrate, YARD, 'fabricWidth', locale),
                widthCm: getMeasurement(selectedSubstrate, METER, 'fabricWidth', locale)
              })}
              weight={translate('fabricShop.SIZE_WEIGHT_UNIVERSAL', {
                weightOz: getMeasurement(selectedSubstrate, YARD, 'weight', locale),
                weightGsm: getMeasurement(selectedSubstrate, METER, 'weight', locale)
              })}
              fabricContent={translate(`${translationFileName}.${selectedSubstrate}.fabricContent`)}
              fabricConstruction={translate(`${translationFileName}.${selectedSubstrate}.fabricConstruction`)}
              color={translate(`${translationFileName}.${selectedSubstrate}.color`)}
              shrinkage={translate(`${translationFileName}.${selectedSubstrate}.shrinkage`)}
              abrasionWyzenbeekDuck={translate(`${translationFileName}.${selectedSubstrate}.abrasionWyzenbeekDuck`)}
              abrasionWyzenbeekWire={translate(`${translationFileName}.${selectedSubstrate}.abrasionWyzenbeekWire`)}
              abrasionMartindale={translate(`${translationFileName}.${selectedSubstrate}.abrasionMartindale`)}
              certification={translate(`${translationFileName}.${selectedSubstrate}.certification`)}
              certificationImage={`${imagesHost}/fabric_detail/${selectedSubstrate}_certificate.svg`}
              certificationImageExists={fabricCertificateImage(selectedSubstrate)}
              needleType={translate(`${translationFileName}.${selectedSubstrate}.needleType`)}
              needleSize={translate(`${translationFileName}.${selectedSubstrate}.needleSize`)}
              threadSize={translate(`${translationFileName}.${selectedSubstrate}.threadSize`)}
              stitchesInch={translate(`${translationFileName}.${selectedSubstrate}.stitchesInch`)}
              threadType={locale === EN ? translate(`${translationFileName}.${selectedSubstrate}.threadType`) : undefined}
              stretchDetail={translate(`${translationFileName}.${selectedSubstrate}.stretchDetail`)}
              fabricPilling={translate(`${translationFileName}.${selectedSubstrate}.fabricPilling`)}
              dryCrocking={translate(`${translationFileName}.${selectedSubstrate}.dryCrocking`)}
              wetCrocking={translate(`${translationFileName}.${selectedSubstrate}.wetCrocking`)}
              threadCount={translate(`${translationFileName}.${selectedSubstrate}.threadCount`)}
              colorfastness={translate(`${translationFileName}.${selectedSubstrate}.colorfastness`)}
              flammability={translate(`${translationFileName}.${selectedSubstrate}.flammability`)}
              breakingStrength={translate(`${translationFileName}.${selectedSubstrate}.breakingStrength`)}
              tearStrength={translate(`${translationFileName}.${selectedSubstrate}.tearStrength`)}
              snagStrength={translate(`${translationFileName}.${selectedSubstrate}.snagStrength`)}
              pileHeight={translate(`${translationFileName}.${selectedSubstrate}.pileHeight`)}
              uvaUvb={translate(`${translationFileName}.${selectedSubstrate}.uvaUvb`)}
              upfRating={translate(`${translationFileName}.${selectedSubstrate}.upfRating`)}
              colorFastnessAcid={translate(`${translationFileName}.${selectedSubstrate}.colorFastnessAcid`)}
              colorFastnessAlkaline={translate(`${translationFileName}.${selectedSubstrate}.colorFastnessAlkaline`)}
              colorFastnessSeaWater={translate(`${translationFileName}.${selectedSubstrate}.colorFastnessSeaWater`)}
              colorFastnessPoolWater={translate(`${translationFileName}.${selectedSubstrate}.colorFastnessPoolWater`)}
              features={translate(`${translationFileName}.${selectedSubstrate}.features`)}
              showOnlyProductInfo={showOnlyProductInfo}
            />
          </ErrorBoundary>}
      </div>
    </article>
  );
};

export default ProductInfo;
