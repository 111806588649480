import React from 'react';

import {
  getOrderItemThumbnailUrls,
  getSolidUrls,
} from '../../../constants/Assets';
import {STACKED_THUMB_NAIL_SIZE} from '../../../constants/Design';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import {MultiLocaleRouter, translate} from '../../../services';
import {Locale} from '../../../shapes/locale';
import StackedImages from '../../Reusable/Images/StackedImages/StackedImages';
import LazyImage from '../../Reusable/LazyImage/LazyImage';
import LinkWrapper from '../../Reusable/LinkWrapper/LinkWrapper';
import './_solids-navbar.scss';


export interface SolidsNavbarProps {
  locale: Locale;
  dispatchResetAllParams: () => void; // TODO - SP-8757 Cleanup SearchParams
}

const SolidsNavbar = ({locale, dispatchResetAllParams}: SolidsNavbarProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const assetsHost = useApiHost(ApiHostsEnum.assets);
  const solidUrls = getSolidUrls(imagesHost);
  const orderItemThumbnailUrls = getOrderItemThumbnailUrls(imagesHost, assetsHost);

  const samplePackImage = `${orderItemThumbnailUrls.STOCK_ITEM_IMAGE_URL}/STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK-4-500.jpg`;

  return (
    <section className='b-solids-navbar'>
      <article className='solids-navbox'>
        <LazyImage
          height='80'
          width='80'
          alt={translate('solidFabrics.crossSell.buySwatch')}
          className='solids-nav-img'
          src={samplePackImage}/>
        <div className='solids-text'>
          <div className='solids-nav-header'>
            {translate('solidFabrics.crossSell.buySwatch')}
          </div>
          <LinkWrapper
            className='solids-link-wrapper'
            hrefValue={MultiLocaleRouter.localePathname('shop.STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK', locale)}
            contents={translate('solidFabrics.crossSell.swatchLink')}
            title={translate('solidFabrics.crossSell.swatchLink')} />
        </div>
      </article>
      <article className='solids-navbox'>
        <LazyImage
          height='80'
          width='80'
          alt={translate('solidFabrics.crossSell.buyDesigns')}
          className='solids-nav-img'
          src={solidUrls.SOLIDS_SHOP_FABRICS}/>
        <div className='solids-text'>
          <div className='solids-nav-header'>
            {translate('solidFabrics.crossSell.buyDesigns')}
          </div>
          <LinkWrapper
            className='solids-link-wrapper'
            hrefValue={MultiLocaleRouter.localePathname('shop.home')}
            customClickEvent={dispatchResetAllParams}
            contents={translate('solidFabrics.crossSell.artistsLink')}
            title={`${translate('solidFabrics.crossSell.buyDesigns')} ${translate('solidFabrics.crossSell.artistsLink')}`} />
        </div>
      </article>
      <article className='solids-navbox'>
        <StackedImages
          stackedImagesExtensionClass='x-stacked-images-small'
          thumbnailExtensionClass='x-thumbnail-small'
          size={STACKED_THUMB_NAIL_SIZE}
          firstDesignId={6444170}
          secondDesignId={7236018}
          firstDesignName='Catching Fireflies'
          secondDesignName='Native Eucalyptus Leaves'
          firstDesignFileName={solidUrls.SOLIDS_SHOP_COLLECTIONS_TOP}
          secondDesignFileName={solidUrls.SOLIDS_SHOP_COLLECTIONS_BOTTOM}
          firstDesignEnciphered={true}
          secondDesignEnciphered={true}/>
        <div className='solids-text'>
          <div className='solids-nav-header'>
            {translate('solidFabrics.crossSell.buyCollections')}
          </div>
          <LinkWrapper
            className='solids-link-wrapper'
            hrefValue={`${MultiLocaleRouter.localePathname('shop.search')}?on=collections&sort=bestSelling`}
            customClickEvent={dispatchResetAllParams}
            contents={translate('solidFabrics.crossSell.artistsLink')}
            title={`${translate('solidFabrics.crossSell.buyCollections')} ${translate('solidFabrics.crossSell.artistsLink')}`} />
        </div>
      </article>
    </section>
  );
};

export default SolidsNavbar;
