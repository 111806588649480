import React from 'react';

import {HOME_GOOD_DUVET_COVER, HOME_GOOD_SQUARE_THROW_PILLOW, HOME_GOOD_THROW_BLANKET, HOME_GOOD_TEA_TOWEL} from '../../../../constants/Codes';
import useOrigin from '../../../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter, translate} from '../../../../services';
import {upsertUrlQuery} from '../../../../utils/url';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';


interface ProductLinkProps {
  imageType: string;
  designId: number;
  imageWidth?: string | number;
}

type productLinkImageType = typeof HOME_GOOD_SQUARE_THROW_PILLOW | typeof HOME_GOOD_THROW_BLANKET | typeof HOME_GOOD_DUVET_COVER | typeof HOME_GOOD_TEA_TOWEL;

const ProductLink = ({imageType, designId, imageWidth}: ProductLinkProps): JSX.Element => {
  const linksMap: Record<productLinkImageType, string> = {
    [HOME_GOOD_SQUARE_THROW_PILLOW]: translate('fabric.carouselImages.squareThrowPillows'),
    [HOME_GOOD_THROW_BLANKET]: translate('fabric.carouselImages.throwBlankets'),
    [HOME_GOOD_DUVET_COVER]: translate('fabric.carouselImages.duvetCovers'),
    [HOME_GOOD_TEA_TOWEL]: translate('fabric.carouselImages.teaTowels')
  };

  const imageLinkText = linksMap[imageType as productLinkImageType];

  const currentOrigin = useOrigin();

  const productLink = upsertUrlQuery(
    `${MultiLocaleRouter.localePathname(imageType)}/${designId}`,
    {},
    currentOrigin
  );

  return (
    <div
      style={{
        width: imageWidth
      }}
      className='carousel-link-wrapper'
    >
      <LinkWrapper hrefValue={productLink} contents={imageLinkText} className='carousel-link' />
    </div>
  );
};

export default ProductLink;
