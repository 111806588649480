/* eslint max-lines: "off" */ // File got too long due to increasing functionality.
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import classNames from 'classnames';
import {MultiLocaleRouter, translate} from '../../services';
import {designRippleUrl} from '../../utils/design';
import {fetchFabrics} from '../Fabric/api/fetchFabrics';
import {objectIsEmpty, isNotUndefined, isEmpty} from '../../utils/validation';
import {getFabricSize, sizeFromParamsOrPreferences} from '../../utils/stateToPropsHelpers';
import {ALL_SIZES, STACKED_LIFESTYLE, FABRIC_LANDING_IMAGE_SIZE_MAIN, FABRIC_LANDING_IMAGE_ZOOM_SIZE_MAIN} from '../../constants/Products';
import {isMobileViewport, VIEWPORT_DESKTOP} from '../../constants/Viewports';
import {CAROUSEL_THUMB_NAIL_SIZE, CAROUSEL_WRAPPER_WIDTH, CAROUSEL_WRAPPER_HEIGHT, QUICKLOOK_CTA_WIDTH} from '../../constants/Design';
import {FABRIC, FABRIC_LONGLEAF_SATEEN_GRAND, FABRIC_PERENNIAL_SATEEN_GRAND, HOME_GOOD, SOLID, STOCK_ITEM, WALLPAPER} from '../../constants/Codes';
import {productTypeAbbreviatedShape} from '../../shapes/products';
import LinkWrapper from '../../components/Reusable/LinkWrapper/LinkWrapper';
import CarouselSlide from '../../components/Reusable/Images/CarouselSlide/CarouselSlide';
import SwiperWrapperContainer from './SwiperWrapperContainer';
import DesignDetailImage from '../../components/Reusable/Images/DesignDetailImage/DesignDetailImage';
import getNoImageUrl from '../../images/getNoImageUrl';
import useApiHost from '../../entities/pageSetup/apiHosts/useApiHost';
import {ApiHostsEnum} from '../../entities/pageSetup/apiHosts';
import {getSolidUrls} from '../../constants/Assets';
import fetchDesign from '../Product/api/fetchDesign';

const SwiperDesignContainer = (props) => {
  // TODO SP-4898 designIdOwn and designNameOwn can be removed in case the QuickLook should get removed,
  // TODO SP-4898 further props might also become redundant
  const {
    selectedSubstrate, parentComponent, homeGoodType, wallpaperType, productTypeAbbreviated, swiperParams,
    homeGoodProductSize, designIdOwn, designNameOwn, carouselImages, hasSquareImages, solidImages
  } = props;
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const solidUrls = getSolidUrls(imagesHost);
  const [imageHeight, setImageHeight] = useState(hasSquareImages ? FABRIC_LANDING_IMAGE_SIZE_MAIN : CAROUSEL_WRAPPER_HEIGHT);
  const [imageWidth, setImageWidth] = useState(hasSquareImages ? FABRIC_LANDING_IMAGE_SIZE_MAIN : 0);

  const {
    isFetchingValidImage, designName, fabrics, productSize,
    viewport, pathName, designThumbnail, windowWidth, designSlugDesignId, pricing,
    designerUserName, designImages, fabricImages, designId, productType
  } = useSelector((state) => {
    const {addToCart, fabrics} = state;
    const productSize = productTypeAbbreviated === FABRIC ? getFabricSize(state, props.selectedSubstrate) : addToCart.productSize || sizeFromParamsOrPreferences(state, props.selectedSubstrate);
    const productType = state.routingData.routesProps.productType;

    return {
      fabrics,
      productSize,
      pricing: addToCart.pricing,
      country: state.user.preferences.country,
      currency: state.user.preferences.currency,
      viewport: state.pageSetup.viewport,
      windowWidth: state.pageSetup.windowWidth,
      pathName: state.routing.locationBeforeTransitions.pathname,
      isFetchingValidImage: state.design.isFetchingValidImage,
      designName: state.design.name,
      designImages: state.design.images,
      fabricImages: get(state.design.images, `fabric[${selectedSubstrate}]`),
      designThumbnail: state.design.thumbnail_url,
      designSlugDesignId: state.design.slug,
      designerUserName: get(state.design.designer, 'screen_name'),
      designId: state.design.id,
      productType
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const isMobile = isMobileViewport(viewport);

  // fabric page
  useEffect(() => {
    (designIdOwn && objectIsEmpty(fabrics.fabrics)) && dispatch(fetchFabrics());
    (designIdOwn && !isFetchingValidImage) && dispatch(fetchDesign());
  }, []);

  const handleSize = (height, width) => {
    const newSizePDP = Math.round((CAROUSEL_WRAPPER_HEIGHT * height / CAROUSEL_WRAPPER_WIDTH));
    const newSize = hasSquareImages ? height : newSizePDP;
    const newHeight = height === 'auto' ? 'auto' : newSize;

    setImageHeight(isMobile ? height : newHeight);
    setImageWidth(width);
  };

  const renderCarouselSlide = (imageStyle, images, key) => {
    const homeGoodLinkType = images.homeGoodLinkType;
    const isHomeGoodLink = isNotUndefined(homeGoodLinkType);
    const isProductPage = parentComponent === 'productForm';
    const isQuickLook = parentComponent === 'quickLook';
    const ctaButtonClass = classNames('btn', 'btn-cta', {
      'small-button': isMobile
    });
    const ctaSize = (isQuickLook && isMobile) ? 'auto' : ((isQuickLook && !isMobile) ? QUICKLOOK_CTA_WIDTH : imageHeight);
    const vendorLinkClass = classNames('vendor-cta-link', {
      'quick-look-link': isQuickLook,
      'home-good-link': isHomeGoodLink
    });
    const size = (isQuickLook && isMobile) ? 'auto' : imageHeight;
    const targetValue = isHomeGoodLink ? '_self' : '_blank';
    let hrefValue;

    if (isHomeGoodLink) {
      try {
        hrefValue = `${MultiLocaleRouter.localePathname(homeGoodLinkType)}/${designSlugDesignId}`;
      } catch {
        // home good type not found, link won't be shown
      }
    }

    const imageSrcSmall = imageStyle === SOLID ? `${solidUrls.SOLIDS_IMAGE_URL}/${images.thumbnail}` : images.mobile;
    const imageSrcLarge = imageStyle === SOLID ? `${solidUrls.SOLIDS_IMAGE_URL}/${images.desktopZoom}` : images.desktop;

    return (
      <CarouselSlide
        key={key}
        index={key}
        imageSrcSmall={imageSrcSmall}
        imageSrcLarge={imageSrcLarge}
        imageHeight={size}
        imageWidth={size}
        imgAlt={imageStyle}
        isProductPage={isProductPage}
        isQuickLook={isQuickLook}
        isMobile={isMobile}
        designId={designId}
        productType={productType}
      >
        {hrefValue &&
        <LinkWrapper
          target={targetValue}
          hrefValue={hrefValue}
          className={vendorLinkClass}
          size={ctaSize}
          contents={<span className={ctaButtonClass}>{translate('designs.carouselCtaShop')}</span>}/>}
      </CarouselSlide>
    );
  };

  const renderCarouselThumbnail = (source, alt, itemPropImage, key) =>
    <img
      key={key}
      className='swiper-image'
      src={(viewport === VIEWPORT_DESKTOP) ? source : undefined}
      alt={alt}
      height={CAROUSEL_THUMB_NAIL_SIZE}
      width={CAROUSEL_THUMB_NAIL_SIZE}
      tabIndex='0'
      {...(itemPropImage ? {
        itemProp: 'image'
      } : {})}/>;

  const renderDesignDetailImage = (imageUrlLarge, imageUrl, imageUrlMobile, key) => {
    const designTitle = designNameOwn ? designNameOwn : designName;
    const isQuickLook = parentComponent === 'quickLook';
    const height = isQuickLook ? 'auto' : imageHeight;
    const isGrandFormatFabric = !hasSquareImages && productTypeAbbreviated === FABRIC && (selectedSubstrate === FABRIC_PERENNIAL_SATEEN_GRAND || selectedSubstrate === FABRIC_LONGLEAF_SATEEN_GRAND);

    return (
      <>
        <DesignDetailImage
          key={key}
          imageAlternateText={designTitle}
          imgSrcLarge={imageUrlLarge}
          imgSrc={imageUrl}
          mobileImage={imageUrlMobile}
          extensionClasses='swiper-slide'
          designName={designName}
          designerUserName={designerUserName}
          pathName={pathName}
          imageHeight={height}
          imageWidth={imageWidth}
          parentComponent={parentComponent}
          viewport={viewport}
        />
        {isGrandFormatFabric &&
          <p className='design-detail-image-note'>{translate('fabricShop.noteActualWidth')}</p>
        }
      </>
    );
  };

  const getImages = () => {
    const isFabricItem = productTypeAbbreviated === FABRIC;
    const isHomeGoodItem = productTypeAbbreviated === HOME_GOOD;
    const isWallpaperItem = productTypeAbbreviated === WALLPAPER;
    const mainImages = [];
    const thumbImages = [];
    const wallpaperDesignImages = get(designImages, 'wallpaper');

    if (isFabricItem && !isEmpty(designImages) && !isEmpty(fabricImages)) {
      let index = 0;

      mainImages.push(renderDesignDetailImage(
        designRippleUrl(fabricImages, productSize).desktop_zoom,
        designRippleUrl(fabricImages, productSize).desktop,
        designRippleUrl(fabricImages, productSize).mobile,
        index
      ));

      thumbImages.push(renderCarouselThumbnail(designThumbnail, designName, true, index));

      // eslint-disable-next-line array-callback-return
      Object.keys(fabricImages).filter((key) => {
        if (key !== 'ripple') {
          let images;

          if (key.startsWith('HOME_GOOD_')) {
            // eslint-disable-next-line array-callback-return
            Object.keys(fabricImages[key]).map((imageStyle) => {
              images = fabricImages[key][imageStyle];
            });
          } else {
            images = fabricImages[key];
          }

          index++;
          mainImages.push(renderCarouselSlide(key, images, index));
          thumbImages.push(renderCarouselThumbnail(images.icon, designName, false, index));
        }
      });
    } else if (isHomeGoodItem && isNotUndefined(get(designImages, `home_good.${[homeGoodType]}`))) {
      const productDimension = homeGoodProductSize && pricing[homeGoodProductSize].size;
      const productStyle = homeGoodProductSize && pricing[homeGoodProductSize]?.style;
      const homeGoodTypeImages = designImages.home_good[homeGoodType];
      let homeGoodTypeList = [];

      isNotUndefined(homeGoodTypeImages) && Object.keys(homeGoodTypeImages).forEach((size) => {
        if (size === productDimension) {
          homeGoodTypeList = Object.keys(homeGoodTypeImages[size]);
        }
      });

      const activeHomegoodStyle = homeGoodTypeList && homeGoodTypeList.filter((options) => (options === productStyle));

      if (activeHomegoodStyle) {
        let index = 0;
        let images;

        activeHomegoodStyle.forEach((homeGoodImageType, typeIndex) => {
          const styleNames = Object.keys(homeGoodTypeImages[productDimension][homeGoodImageType]);

          styleNames.forEach((styleName, styleIndex) => {
            index = typeIndex ? typeIndex : styleIndex;

            images = homeGoodTypeImages[productDimension][homeGoodImageType][styleName];

            // TODO SP-5679: Clean up once stacked image formula includes ALL_SIZE
            if (!styleName.includes(ALL_SIZES) && !styleName.includes(STACKED_LIFESTYLE)) {
              index === 0 && mainImages.push(renderDesignDetailImage(images.desktop, images.desktop, images.mobile, index));
              index > 0 && mainImages.push(renderCarouselSlide(homeGoodType, images, index));
              thumbImages.push(renderCarouselThumbnail(images.icon, designName, false, index));
            }
          });
        });

        const productImageObject = homeGoodTypeImages && homeGoodTypeImages[productDimension]?.[productStyle];
        const sizeIndependentImageNames = [];

        !objectIsEmpty(productImageObject) && Object.keys(productImageObject).forEach((imageName) => {
          // TODO SP-5679: Clean up once stacked image formula includes ALL_SIZE
          (imageName.includes(ALL_SIZES) || imageName.includes(STACKED_LIFESTYLE)) && sizeIndependentImageNames.push(imageName);
        });

        if (!isEmpty(sizeIndependentImageNames)) {
          sizeIndependentImageNames.forEach((sizeIndependentImage) => {
            mainImages.push(renderCarouselSlide(homeGoodType, homeGoodTypeImages[productDimension][productStyle][sizeIndependentImage], index + 1));
            thumbImages.push(renderCarouselThumbnail(homeGoodTypeImages[productDimension][productStyle][sizeIndependentImage].icon, designName, false, index + 1));
            index = index + 1;
          });
        }
      }
    } else if (isWallpaperItem && !isEmpty(wallpaperDesignImages) && isNotUndefined(wallpaperDesignImages[selectedSubstrate])) {
      Object.keys(designImages.wallpaper[selectedSubstrate]).forEach((wallpaperImageType, index) => {
        const selectedWallpaperImages = designImages.wallpaper[selectedSubstrate][wallpaperImageType];

        index === 0 && mainImages.push(renderDesignDetailImage(selectedWallpaperImages.desktop, selectedWallpaperImages.desktop, selectedWallpaperImages.mobile, index));
        index > 0 && mainImages.push(renderCarouselSlide(wallpaperType, selectedWallpaperImages, index));
        thumbImages.push(renderCarouselThumbnail(designImages.wallpaper[selectedSubstrate][wallpaperImageType].icon, designName, false, index));
      });
    } else if (isNotUndefined(solidImages)) {
      Object.keys(solidImages).forEach((solidImageType, index) => {
        index === 0 && mainImages.push(renderDesignDetailImage(
          `${solidUrls.SOLIDS_IMAGE_URL}/${solidImages[solidImageType].desktopZoom}`,
          `${solidUrls.SOLIDS_IMAGE_URL}/${solidImages[solidImageType].desktop}`,
          `${solidUrls.SOLIDS_IMAGE_URL}/${solidImages[solidImageType].mobile}`,
          index));
        index > 0 && mainImages.push(renderCarouselSlide(productTypeAbbreviated, solidImages[solidImageType], index));
        thumbImages.push(renderCarouselThumbnail(`${solidUrls.SOLIDS_IMAGE_URL}/${solidImages[solidImageType].icon}`, designName, false, index));
      });
    } else {
      // set placeholder image
      mainImages.push(renderDesignDetailImage('', getNoImageUrl(imagesHost), '', 1));
      thumbImages.push(renderCarouselThumbnail(getNoImageUrl(imagesHost), '', false, 1));
    }

    return {
      'thumbs': thumbImages,
      'main': mainImages
    };
  };

  const fabricDetailCarouselSlide = (altTextFabricName, smallImage, largeImage, key) => {
    const isMobile = isMobileViewport(viewport);

    return <CarouselSlide
      key={key}
      index={key}
      imageSrcSmall={smallImage}
      imageSrcLarge={largeImage}
      imageHeight={imageHeight || FABRIC_LANDING_IMAGE_SIZE_MAIN}
      imageWidth={imageWidth || FABRIC_LANDING_IMAGE_SIZE_MAIN}
      zoomSize={FABRIC_LANDING_IMAGE_ZOOM_SIZE_MAIN}
      imgAlt={altTextFabricName}
      isProductPage={true}
      isMobile={isMobile}
      designId={designId}
      productType={productType}
    />;
  };

  const getFabricDetailImages = () => {
    const altTextFabricName = !isEmpty(selectedSubstrate) ? selectedSubstrate.includes(STOCK_ITEM) ?
      translate(`stockItems.${selectedSubstrate}.title`) : translate(`fabricShop.${selectedSubstrate}.name`) : '';
    const thumbImages = [];
    const mainImages = [];

    if (!isEmpty(selectedSubstrate)) {
      thumbImages.push(renderCarouselThumbnail(get(carouselImages.ripple.thumbnail, `${[selectedSubstrate]}`, carouselImages.ripple.small[selectedSubstrate]), altTextFabricName, false, 0));
      mainImages.push(renderDesignDetailImage(carouselImages.ripple.large[selectedSubstrate], carouselImages.ripple.large[selectedSubstrate], carouselImages.ripple.small[selectedSubstrate], 0));

      Object.keys(carouselImages.details.small).forEach((carouselImageKey, index) => {
        thumbImages.push(renderCarouselThumbnail(carouselImages.details.small[carouselImageKey], altTextFabricName, false, index + 1));
        mainImages.push(fabricDetailCarouselSlide(altTextFabricName, carouselImages.details.small[carouselImageKey], carouselImages.details.large[carouselImageKey], index + 1));
      });
    }

    return {
      'thumbs': thumbImages,
      'main': mainImages
    };
  };

  const thumbnailImages = hasSquareImages ? getFabricDetailImages().thumbs : getImages().thumbs;
  const mainImages = hasSquareImages ? getFabricDetailImages().main : getImages().main;

  return (
    <SwiperWrapperContainer
      viewport={viewport}
      parentComponent={parentComponent}
      hasSquareImages={hasSquareImages}
      handleSize={handleSize}
      windowWidth={windowWidth}
      productSize={productSize}
      swiperParams={swiperParams}
      thumbnailImages={thumbnailImages}>
      {mainImages}
    </SwiperWrapperContainer>
  );
};

SwiperDesignContainer.propTypes = {
  productTypeAbbreviated: productTypeAbbreviatedShape,
  selectedSubstrate: PropTypes.string,
  measurementSystem: PropTypes.string,
  homeGoodProductSize: PropTypes.string,
  homeGoodType: PropTypes.string,
  parentComponent: PropTypes.string,
  hasSquareImages: PropTypes.bool,
  wallpaperType: PropTypes.string,
  carouselImages: PropTypes.object,
  solidImages: PropTypes.object,

  // Only passed by QuickLook
  designNameOwn: PropTypes.string,
  designIdOwn: PropTypes.number,
  swiperParams: PropTypes.object
};

export default SwiperDesignContainer;
