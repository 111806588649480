import {DATA_HOME_GOOD} from '../constants/DataLayer';

export const spellCorrectionEvent = (event, {originalQuery, alteredQuery, uuid} = {}) => ({
  event,
  originalQuery,
  alteredQuery,
  uuid,
});

export const doNotSellShareEvent = () => (
  {
    event: 'doNotSellShare',
    opt_out: true
  }
);

export const viewItemListEvent = (q, currency, designs, userId, itemListName) => (
  {
    event: 'view_item_list',
    ecommerce: {
      item_list_name: itemListName,
      currencyCode: currency,
      userId,
      totalQuantity: designs?.length,
      search_term: q,
      items: designs
    }
  }
);

export const allProductsImpressionsEvent = (q, currency, designs, userId, itemListName) => (
  {
    event: 'load_section',
    ecommerce: {
      item_list_name: itemListName,
      currencyCode: currency,
      userId,
      totalQuantity: designs?.length,
      search_term: q,
      items: designs
    }
  }
);

export const virtualPageViewEvent = (pageType, page) => (
  {
    event: 'virtual_page_view',
    pageType,
    ecommerce: {
      page_number: page,
    }
  }
);

export const searchFilterChangedEvent = (searchParams, productImpressions) => {
  const nonEmptyFields = Object.keys(searchParams).reduce((filledFields, key) => {
    if (searchParams[key] || searchParams[key] === 0) {
      filledFields[key] = searchParams[key];
    }

    return filledFields;
  }, {});

  return {
    event: 'searchFilterClick',
    ...nonEmptyFields,
    ...productImpressions
  };
};

export const addToFavoritesEvent = (eventData) => {
  const {
    brand,
    category,
    currency,
    department,
    id,
    list,
    name,
    position,
    price,
    search_term,
    variant,
  } = eventData;

  const item_categories = category === DATA_HOME_GOOD ? {
    item_category: category,
    item_category2: department,
    item_category3: name,
  } : {
    item_category: category
  };

  return {
    event: 'add_to_favorites',
    ecommerce: {
      favorite: {
        item_list_name: list,
        search_term,
        items: [{
          item_name: name,
          item_brand: brand,
          item_variant: variant,
          currency,
          department,
          ...item_categories,
          design_id: id,
          index: position,
          item_list_name: list,
          price,
        }],
      }
    }
  };
};

export const removeFromFavoritesEvent = (eventData) => {
  const {
    brand,
    category,
    currency,
    department,
    id,
    list,
    name,
    position,
    price,
    search_term,
    variant,
  } = eventData;

  const item_categories = category === DATA_HOME_GOOD ? {
    item_category: category,
    item_category2: department,
    item_category3: name,
  } : {
    item_category: category
  };

  return {
    event: 'remove_from_favorites',
    ecommerce: {
      removeFavorite: {
        item_list_name: list,
        search_term,
        items: [{
          item_name: name,
          item_brand: brand,
          item_variant: variant,
          currency,
          department,
          ...item_categories,
          design_id: id,
          index: position,
          item_list_name: list,
          price,
        }],
      }
    }
  };
};
