/* eslint-disable complexity */
import classNames from 'classnames';
import get from 'lodash/get';
import React, {useEffect, useState} from 'react';
import {Field} from 'redux-form';

import './_product-form-options.scss';
import {
  DUVET_PRODUCT_SUFFIX, FABRIC, FABRIC_LONGLEAF_SATEEN_GRAND, FABRIC_PERENNIAL_SATEEN_GRAND, HOME_GOOD, HOME_GOOD_DUVET_COVER, HOME_GOOD_SHEET_SET, SHEET_PRODUCT_SUFFIX,
  SOLID, WALLPAPER
} from '../../../../constants/Codes';
import {measurementUnitSpecification, METRIC} from '../../../../constants/Measurements';
import {IS_WALLPAPER, noSizeOption, PRODUCT_NAME_TO_KEY_PRODUCT_MAP, PRODUCT_SIZE, PRODUCT_STYLE,CHOOSE_A_STYLE, CHOOSE_A_SIZE} from '../../../../constants/Products';
import {translate} from '../../../../services';
import {MeasurementType} from '../../../../shapes/measurement';
import {HomeGoodPricing, isHomeGoodPricing, ProductPricingMaps, SimplePricing} from '../../../../shapes/pricing';
import {ProductTypeAbbreviated} from '../../../../shapes/products';
import {defaultBasePrice, getFabricMeasures} from '../../../../utils/fabrics';
import {renderRadioButtons} from '../../../../utils/form';
import {isEmpty, isFunction, isUndefined, isNotUndefined, objectIsNotEmpty} from '../../../../utils/validation';
import Icon from '../../../Reusable/Icon/Icon';


export interface ProductFormOptionsProps {
  productType: string;
  optionType?: string;
  measurementSystem: MeasurementType;
  handleOnChange?: (quantity: number, value: string, fieldName: string) => void;
  currency?: string;
  homeGoodType?: string;
  productTypeAbbreviated?: ProductTypeAbbreviated;
  fabricSizeSizingMap?: Record<string, unknown>;
  productPricingMap?: ProductPricingMaps;
  sizeStyleMap?: Record<string, Record<string, unknown>>;
  productSize?: string;
  quantity?: number;
  sizesAreAvailable?: boolean;
  stylesAreAvailable?: boolean;
  singleFabricSizeAvailable?: boolean;
  setAddition?: (addition: string) => void;
  selectedAddition?: string;
  additionPricing?: Record<string, Record<string, unknown>>;
  additionTotalPriceMap?: SimplePricing;
  solidSize?: string;
  designNotPurchasable?: boolean;
  productAvailable?: boolean;
  openWallpaperCalculatorModal?: () => void;
  stylesArray?: string[];
  selectedSubstrate?: string;
}

const ProductFormOptions = ({
  productType, homeGoodType, optionType, productTypeAbbreviated, fabricSizeSizingMap, productPricingMap, measurementSystem,
  sizeStyleMap, productSize, quantity, handleOnChange, sizesAreAvailable, stylesAreAvailable, singleFabricSizeAvailable,
  setAddition, selectedAddition, additionPricing, additionTotalPriceMap, currency, solidSize, designNotPurchasable, productAvailable,
  openWallpaperCalculatorModal, stylesArray, selectedSubstrate
}: ProductFormOptionsProps): JSX.Element => {
  const [additionOptions, setAdditionOptions] = useState<Record<string, string>[]>([]);
  const [selectedAdditionIndex, setSelectedAdditionIndex] = useState(0);

  /**
   * When "additionOptions" changes, we want to ensure we set the Addition to
   * whatever will be in the position of the previously selected one.
   */
  useEffect(() => {
    const index = additionOptions.findIndex(({value}) => value === selectedAddition);

    /**
     * This means that we found the selected Addition in the options,
     * but it's not what's currently selected.
     *
     * This can happen in two scenarios:
     */
    if (index !== -1 && index !== selectedAdditionIndex) {
      /**
       * Scenario #1:
       * The selected Addition got cleared as a side effect of a User changing the Size.
       * We need to select the Addition that was in the same position position as the previous one.
       */
      if (isEmpty(selectedAddition)) {
        const addition = additionOptions[selectedAdditionIndex]?.value;

        if (isNotUndefined(addition)) {
          setAddition?.(addition);
        }
      /**
       * Scenario #2:
       * A User is Editing an Item in their Cart.
       * We need to correct the selected index.
       */
      } else {
        setSelectedAdditionIndex(index);
      }
    }
  }, [additionOptions]);

  /**
   * We want to maintain the user's selected addition between size changes.
   * To do that, we need to store the addition options as state,
   * so that we can reference their positions later.
   */
  useEffect(() => {
    if (isUndefined(additionTotalPriceMap)) {
      return;
    }

    const defaultOptionLabel = isNotUndefined(homeGoodType) ?
      translate(`homeGood.${homeGoodType}.additionButtons.PLAIN`) :
      '';
    const defaultOption = {
      label: defaultOptionLabel,
      value: '',
    };
    const additionOptions = Object.keys(additionTotalPriceMap).map((key) => {
      const optionLabel = translate(`homeGood.${homeGoodType}.additionButtons.${key}`);
      const price = isNotUndefined(productSize) ?
        additionPricing?.[productSize]?.[key] :
        undefined;
      const label = isNotUndefined(price) ?
        `${optionLabel} ${defaultBasePrice(price, currency)}` :
        optionLabel;

      return {
        label,
        value: key
      };
    });

    setAdditionOptions([
      defaultOption,
      ...additionOptions,
    ]);
  }, [additionTotalPriceMap]);

  const renderAdditionOptions = (): React.ReactNode => {
    const onClickAction = (index: number) => (event: React.MouseEvent<HTMLInputElement>) => {
      /**
       * "MouseEvent<HTMLInputElement>" is correct but there is a bug in the typing
       * that does not intersect "EventTarget" with "HTMLInputElement".
       * Therefore, we need to type assert "target".
       */
      const value = (event?.target as HTMLInputElement)?.value;

      setAddition?.(value);
      setSelectedAdditionIndex(index);
    };

    return additionOptions.map(({label, value}, index) => {
      const selectedValue = additionOptions[selectedAdditionIndex]?.value;
      const isSelectedValue = value === selectedValue;
      const highlightClass = classNames('btn', 'option-btn', {
        'option-btn-active': isSelectedValue
      });

      return (
        <Field
          key={value}
          value={value}
          label={label}
          onClickAction={onClickAction(index)}
          name='homegood-addition'
          component={renderRadioButtons}
          formPrefix='homegood-addition'
          type='radio'
          ariaRequired='true'
          highlightClass={highlightClass}
        />
      );
    });
  };

  const productOptions: string[] = objectIsNotEmpty(fabricSizeSizingMap) ? Object.keys(fabricSizeSizingMap) : objectIsNotEmpty(productPricingMap) ? Object.keys(productPricingMap) : [];
  const isManySizeOptions = productOptions.length > 1;
  const isManyAdditionOptions = additionOptions.length > 1;
  const isManyStyles = stylesArray && stylesArray?.length > 1;
  const fieldName = optionType === PRODUCT_STYLE ? PRODUCT_STYLE : PRODUCT_SIZE;
  const fieldNameTitle = optionType === PRODUCT_STYLE ? (isManyStyles ? CHOOSE_A_STYLE : PRODUCT_STYLE) : (isManySizeOptions ? CHOOSE_A_SIZE : PRODUCT_SIZE);
  const isSize = optionType === PRODUCT_SIZE;
  const isHomeGood = productTypeAbbreviated === HOME_GOOD;
  const isFabric = productTypeAbbreviated === FABRIC;
  const isWallpaper = productTypeAbbreviated === WALLPAPER;
  const isGrandFormatFabric = isFabric && (selectedSubstrate === FABRIC_PERENNIAL_SATEEN_GRAND || selectedSubstrate === FABRIC_LONGLEAF_SATEEN_GRAND);

  const handleSizeOnChange = (value: string | unknown) => (
    (typeof quantity === 'number' && typeof value === 'string' && isFunction(handleOnChange)) &&
    handleOnChange(quantity, value, fieldName)
  );

  const renderFabricSizing = (productSize: string): string => {
    const fabricWidth = getFabricMeasures(fabricSizeSizingMap, productSize, measurementSystem).fabricWidth;
    const fabricHeight = getFabricMeasures(fabricSizeSizingMap, productSize, measurementSystem).fabricHeight;

    return `${fabricWidth}${measurementUnitSpecification(measurementSystem)} x ${fabricHeight}${measurementUnitSpecification(measurementSystem)}`;
  };

  const renderStaticSize = (): string => {
    const productName = isHomeGood ? 'homeGood' : isFabric ? 'fabric' : 'wallpaper';
    const productSizeSuffix = measurementSystem === METRIC && isHomeGood ? '_METRIC' : '';

    return solidSize ? solidSize : translate(
      [productName, 'productSize', productSize + productSizeSuffix].join('.')
    );
  };

  const renderFormPrefix = (): string => (productTypeAbbreviated === HOME_GOOD ?
    PRODUCT_NAME_TO_KEY_PRODUCT_MAP[HOME_GOOD] :
    productTypeAbbreviated === FABRIC ?
      PRODUCT_NAME_TO_KEY_PRODUCT_MAP[FABRIC] : PRODUCT_NAME_TO_KEY_PRODUCT_MAP[WALLPAPER]);

  const renderAdditionalTypeSpecification = (): string => (productType === HOME_GOOD_SHEET_SET ?
    SHEET_PRODUCT_SUFFIX :
    (productType === HOME_GOOD_DUVET_COVER ?
      DUVET_PRODUCT_SUFFIX : ''));

  const renderCalculatorLink = () => (
    <button className='wallpaper-calculator-btn x-non-full-width-mobile-button' onClick={openWallpaperCalculatorModal} type='button'>
      <Icon iconName='ruler-horizontal' extensionClass='header-icon' />
      <span className='wallpaper-calculator-btn-text'>
        <span className='x-emphasis'>{translate('wallpaperCalculator.calculatorButtonTextOne')}</span> {translate('wallpaperCalculator.calculatorButtonTextTwo')}
      </span>
    </button>
  );

  const options: Record<string, string>[] = [];
  let labelText = '';

  if (productType === SOLID) {
    labelText = translate('solidColors.headlines.size');
  }

  if ((isNotUndefined(homeGoodType)) && !isEmpty(productOptions)) {
    productOptions.reverse();
  }

  const getProductOption = (productSizeParam: string) => {
    let option;


    if (isHomeGood && objectIsNotEmpty(productPricingMap) && isHomeGoodPricing(productPricingMap, productSizeParam)) {
      option = (optionType === PRODUCT_STYLE) ? productPricingMap[productSizeParam].style : productPricingMap[productSizeParam].size;
    } else {
      option = '';
    }

    return option;
  };

  const getOptionValue = (productSizeParam: string) => {
    let optionValue;
    const pricingOptionExists = objectIsNotEmpty(productPricingMap) && isHomeGoodPricing(productPricingMap, productSizeParam);

    if (!isHomeGood) {
      optionValue = productSizeParam;
    } else if (isSize) {
      optionValue = (pricingOptionExists ? (productPricingMap as HomeGoodPricing)[productSizeParam].size : '');
    } else {
      optionValue = (pricingOptionExists ? (productPricingMap as HomeGoodPricing)[productSizeParam].style : '');
    }

    return optionValue;
  };

  productOptions.forEach((productSizeParam) => {
    let productOptionLabel = '';
    const productOption = getProductOption(productSizeParam);

    if ((!isHomeGood || (!productOption || !productOptions.includes(productOption)))) {
      if (isNotUndefined(productPricingMap) || isNotUndefined(fabricSizeSizingMap)) {
        switch (productTypeAbbreviated) {
          case WALLPAPER:
            productOptionLabel = translate(`wallpaper.productSize.${measurementSystem}.${productSizeParam}`);
            labelText = translate('fabric.headlines.size');
            break;
          case FABRIC:
            productOptionLabel = `${translate(`common.fabricSize.${productSizeParam}`)} ${renderFabricSizing(productSizeParam)}`;
            labelText = translate('fabric.headlines.size');
            break;
          case HOME_GOOD:
            productOptionLabel = measurementSystem === METRIC ? translate(`homeGood.productSize.${productOption}${renderAdditionalTypeSpecification()}_METRIC`) :
              translate(`homeGood.productSize.${productOption}${renderAdditionalTypeSpecification()}`);
            labelText = isSize ? translate('fabric.headlines.size') : translate('fabric.headlines.style');
            break;
        }
      }

      const optionValue = getOptionValue(productSizeParam);

      options.push({
        label: productOptionLabel,
        value: optionValue || ''
      });

      productOption && productOptions.push(productOption);
    }
  });

  const createOptionButtons = () => {
    const optionButtons = [];
    const reversedOptions = options.reverse();

    for (const option of reversedOptions) {
      let optionButtonClasses = classNames('btn option-btn', {
        'x-fabric-size': isFabric,
        'x-non-full-width-mobile-button': isWallpaper || isHomeGood
      });

      const optionValue = option.value;
      const initialValue = isHomeGood ? initialValueHomeGood : initialValueFabricAndWallpaper;

      if (optionValue === initialValue) {
        optionButtonClasses = classNames('btn option-btn option-btn-active', {
          'x-fabric-size': isFabric,
          'x-non-full-width-mobile-button': isWallpaper || isHomeGood
        });
      }

      const onClickActionHandler = () => handleSizeOnChange(optionValue);

      optionButtons.push(
        <Field
          key={optionValue}
          value={optionValue}
          label={option.label}
          onClickAction={onClickActionHandler}
          name={fieldName}
          component={renderRadioButtons}
          formPrefix={renderFormPrefix()}
          type='radio'
          ariaRequired='true'
          highlightClass={optionButtonClasses}/>
      );
    }

    return optionButtons;
  };

  const renderNonStaticSize = () => {
    let grandFabricSizeNote = '';

    if (isGrandFormatFabric && optionType === PRODUCT_SIZE) {
      const fabricWidth = getFabricMeasures(fabricSizeSizingMap, productSize, measurementSystem).fabricWidth;

      grandFabricSizeNote = ` ${translate(`fabric.headlines.note.${measurementSystem}`, {
        width: fabricWidth
      })}`;
    }

    return (
      <>
        <fieldset>
          <legend className='legend' itemProp={fieldName}>
            {fieldNameTitle}
            {grandFabricSizeNote && <span className='grand-fabric-note'>{grandFabricSizeNote}</span>}
          </legend>
          {createOptionButtons()}
        </fieldset>
        {(IS_WALLPAPER(productTypeAbbreviated) && !designNotPurchasable && productAvailable) && renderCalculatorLink()}
      </>
    );
  };

  const selectedSize = (objectIsNotEmpty(sizeStyleMap) && productSize) && Object.keys(sizeStyleMap[productSize])[0];
  const selectedStyle = (objectIsNotEmpty(sizeStyleMap) && productSize) && Object.values(sizeStyleMap[productSize])[0];
  const initialValueHomeGood = isSize ? get(options.find((option) => option.value === selectedSize), 'value') :
    get(options.find((option) => option.value === selectedStyle), 'value');
  const initialValueFabricAndWallpaper = get(options.find((option) => option.value === productSize), 'value');
  const hasNoSizeOption = noSizeOption.includes(productType);
  const sectionClasses = 'b-product-form-options detail-section';
  const insertLegend = isManyAdditionOptions ? translate('homeGood.content.insertOption') : translate('homeGood.content.productInsert');

  return (
    isNotUndefined(additionTotalPriceMap) ?
      <fieldset className={sectionClasses}>
        <legend className='legend'>{insertLegend}</legend>
        {renderAdditionOptions()}
      </fieldset> :
      <section className={sectionClasses}>
        {!hasNoSizeOption && (!isHomeGood || sizesAreAvailable || stylesAreAvailable) && !singleFabricSizeAvailable ?
          renderNonStaticSize() :
          <React.Fragment>
            {!isEmpty(labelText) && <h3 className='h2'>{labelText}</h3>}
            <h3 className='size-information' itemProp='size'>{renderStaticSize()}</h3>
          </React.Fragment>}
      </section>
  );
};

export default ProductFormOptions;
