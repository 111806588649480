/* eslint-disable max-lines */
import isEqual from 'lodash/isEqual';
import React, {useEffect, useRef, useState, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import browserHistory from 'react-router/lib/browserHistory';

import {setDesign, setSubstrate} from '../../actions/addToCart';
import {setFabricShopSearch} from '../../actions/fabrics';
import {modalClose, pageSetup, pageUpdate} from '../../actions/pageSetup';
import {ErrorBoundary} from '../../components/Reusable/ErrorBoundary/ErrorBoundary';
import {FilterMenuItem} from '../../components/Reusable/Filter/Filter';
import browserHistoryWrapper from '../../components/Reusable/LinkWrapper/browserHistoryWrapper';
import Loading from '../../components/Reusable/Loading/Loading';
import SubstrateShop from '../../components/Reusable/SubstrateShop/SubstrateShop';
import {
  FABRIC,
  WALLPAPER
} from '../../constants/Codes';
import {FABRIC_CODE_COOKIE_NAME, WALLPAPER_CODE_COOKIE_NAME} from '../../constants/Cookies';
import {DATA_FABRIC_SHOP} from '../../constants/DataLayer';
import {measurementSystemToUnit} from '../../constants/Measurements';
import {FABRIC_PARAM, ORDER_ITEM_ID, SIZE} from '../../constants/Parameters';
import {ROUTING_PATH_FABRIC} from '../../constants/Products';
import {ALPHABETICAL_SORTING, PRICE_ASC_SORTING} from '../../constants/Sortings';
import {isDesktop} from '../../constants/Viewports';
import {initWideLayout} from '../../entities/layout/initLayout';
import useOrigin from '../../entities/pageSetup/origin/useOrigin';
import usePreviousValue from '../../redux/hooks/usePreviousValue';
import {MultiLocaleRouter, translate} from '../../services';
import {isDesignResponse} from '../../shapes/design';
import {AvailableStock} from '../../shapes/fabrics';
import {ToggleSlideInComponentFunction} from '../../shapes/functions';
import {WallpaperDimensions} from '../../shapes/products';
import {FabricShopCategory, State} from '../../store/initialState';
import {setSecureCookie} from '../../utils/cookies';
import {cartContentEvent} from '../../utils/dataLayer';
import {getFilteredFabrics} from '../../utils/filterFabrics';
import {removeDuplicateNonHelmetElements} from '../../utils/headHelpers';
import {pdpSelectedSubstrate, queryParamsOverrideUserPreferences} from '../../utils/stateToPropsHelpers';
import {capitalize, snakeCaseFromCamelCase, trimmedLowerString} from '../../utils/string';
import {debounce} from '../../utils/timers';
import {shopHomeUrl, upsertUrlQuery} from '../../utils/url';
import {isEmpty, isNotUndefined, objectIsEmpty} from '../../utils/validation';

import {fetchFabrics} from './api/fetchFabrics';
import {fetchFabricsSlugs} from './api/fetchFabricsSlugs';
import {getAlternateLanguageUrls} from './helpers';
import {initFabricShopTitle} from './pageSetup';


export interface ProductInformation {
  fabricName: string;
  fabricKey: string;
  fabricPrice?: number;
}

export interface FabricShopContainerProps {
  displayFabricsModal?: boolean;
  routingPath?: string;
  fabricOptionsArray?: string[];
  outOfStockArray?: AvailableStock[];
  // from modal
  isStandardFabricModal?: boolean;
  isWallpaper?: boolean;
  wallpaperDimensions?: WallpaperDimensions;
  designImages?: { fabric: { [key: string]: { fabric_specific: Record<string, string> } } };
  params: {
    pageLang: string;
    category?: string;
    designId?: string;
  };
}

export type SortedCategoryType = Record<string, Record<number, FilterMenuItem>>;

const FabricShopContainer = ({params, displayFabricsModal, isStandardFabricModal, routingPath, fabricOptionsArray, outOfStockArray, isWallpaper, wallpaperDimensions, designImages}:
                               FabricShopContainerProps): JSX.Element => {
  const currentOrigin = useOrigin();

  const {
    addToCartDesignId, category, country, currency, designSlugDesignId, fabrics,
    isCategoryPage, locale, locationQuery, measurementSystem, pendingCart, shopBasePath,
    selectedSubstrate, showSlideInComponent, showFilters, viewport, originFromState, categories, pathname
  } = useSelector((state: State) => {
    const {
      routing: {locationBeforeTransitions: {pathname, query: locationQuery}},
      pageSetup: {showFilters, viewport, showSlideInComponent, slideInComponent, origin: originFromState},
      addToCart: {designId: addToCartDesignId},
      fabrics, design, carts: {pending}
    } = state;
    const urlSegments = pathname.split('/');
    const {category, pageLang: locale} = params;
    const isCategoryPage = isNotUndefined(category);
    const substrateChoice = state?.addToCart?.selectedSubstrate || isWallpaper && pdpSelectedSubstrate(state, WALLPAPER) || pdpSelectedSubstrate(state, FABRIC);

    return {
      ...queryParamsOverrideUserPreferences(state), // country, measurementSystem, currency
      locale,
      locationQuery,
      viewport,
      // Note: if path is malformed or null, this can result in the unexpected
      // path string `"//"`.
      shopBasePath: `/${urlSegments?.[1]}/${urlSegments?.[2]}`,
      category,
      categories: fabrics?.categories,
      isCategoryPage,
      fabrics,
      addToCartDesignId,
      designSlugDesignId: isDesignResponse(design) ? design.slug : undefined,
      selectedSubstrate: substrateChoice,
      showSlideInComponent: !!slideInComponent && !!showSlideInComponent,
      showFilters,
      pendingCart: pending,
      originFromState,
      pathname
    };
  }, shallowEqual);

  const newCanonicalUrl = useMemo(() => (currentOrigin + pathname), [currentOrigin, pathname]);

  const alternateLanguageUrls: Record<string, string> | undefined = useMemo(() => {
    if (isCategoryPage && isNotUndefined(categories) && isNotUndefined(locationQuery)) {
      return getAlternateLanguageUrls(categories, locale, category, locationQuery, currentOrigin);
    }
  }, [categories, locale, category, locationQuery, currentOrigin, isCategoryPage]);

  const dispatch = useDispatch();

  const dispatchToggleSlideInComponent: ToggleSlideInComponentFunction = (
    show: boolean,
    currentCategory?: string,
    sortedCategories?: SortedCategoryType,
    clearFilters?: () => void,
    searchQuery?: string
  ) => {
    dispatch(
      pageUpdate({
        showSlideInComponent: show,
        slideInComponent: {
          name: 'SubstrateFilter',
          props: {
            currentCategory,
            sortedCategories,
            clearFilters,
            searchQuery,
          },
        },
      })
    );
  };
  const dispatchSetSubstrate = (fabricString: string) => dispatch(setSubstrate(fabricString));
  const dispatchCloseModal = () => dispatch(modalClose());

  const searchQueryTextInput = useRef<HTMLInputElement | null>(null);
  const [cartContentPushedToGA, setCartContentPushedToGA] = useState<boolean | null>(false);
  const [matchingFabrics, setMatchingFabrics] = useState<unknown[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [sortingCriteria, setSortingCriteria] = useState<string>(ALPHABETICAL_SORTING);
  const [slideInToggled, setSlideInToggled] = useState<boolean>(false);

  const checkIsStandardFabricModal = isNotUndefined(isStandardFabricModal) ? isStandardFabricModal : true;
  const prevMeasurementSystem = usePreviousValue(measurementSystem);
  const prevCountry = usePreviousValue(country);
  const prevCurrency = usePreviousValue(currency);

  const getFabricsNames = (forceRefresh = false): ProductInformation[] => {
    const fabricNames: ProductInformation[] = [];
    const searchQueryIsDefined = !isEmpty(searchQuery) && isNotUndefined(searchQuery);

    const categoriesIsDefined =
      checkIsStandardFabricModal && // Categories can't be defined if it's not a standard fabric modal
      isNotUndefined(locale) && // Null guard so the indexing works on the next line
      isNotUndefined(fabrics?.categories?.[locale]); // Ensure that categories[locale] is defined

    const measureUnit =
      checkIsStandardFabricModal && fabrics?.measurement_system ?
        measurementSystemToUnit[fabrics.measurement_system] :
        false;

    let matchingKeys;

    if (fabrics?.fabrics !== undefined && (forceRefresh || isEmpty(matchingFabrics))) {
      matchingKeys = !checkIsStandardFabricModal ? fabricOptionsArray : Object.keys(fabrics.fabrics);
    } else {
      matchingKeys = matchingFabrics;
    }

    if ((categoriesIsDefined && isNotUndefined(category)) || (searchQueryIsDefined && checkIsStandardFabricModal)) {
      matchingKeys = getFilteredFabrics(fabrics, isCategoryPage, category, searchQuery);
    }

    matchingKeys && setMatchingFabrics(matchingKeys);

    matchingKeys?.forEach((matchingKey: string) => {
      fabricNames.push({
        fabricKey: matchingKey,
        fabricName: translate(`fabricShop.${matchingKey}.name`),
        ...checkIsStandardFabricModal && measureUnit && {
          fabricPrice: fabrics.fabrics[matchingKey].pricing[measureUnit].price_undiscounted
        }
      });
    });

    return fabricNames;
  };

  const sortProductInformation = (fabricNames: ProductInformation[], sortingCriteria: string, infoType: string): ProductInformation[] => {
    const filteredList = fabricNames.filter((fabric) => (
      !fabric.fabricName.includes(capitalize(sortingCriteria)) &&
      translate(`fabricShop.${fabric.fabricKey}.${infoType}`).toLowerCase()
        .includes(sortingCriteria))
    );

    return filteredList.sort((x, y) => (translate(`fabricShop.${x.fabricKey}.${infoType}`).indexOf(sortingCriteria) <
    translate(`fabricShop.${y.fabricKey}.${infoType}`).indexOf(sortingCriteria) ?
      -1 : 1));
  };

  const constructSortFabrics = (fabricNames: ProductInformation[], sortingCriteria: string) => {
    const sortedFabrics: string[] = [];
    const searchTerm = capitalize(sortingCriteria);

    if (sortingCriteria === searchQuery) {
      const filteredTitles = fabricNames.filter((fabric: ProductInformation) => fabric.fabricName.includes(searchTerm));
      const sortTitles = filteredTitles.sort((x, y) => (x.fabricName.indexOf(searchTerm) < y.fabricName.indexOf(searchTerm) ? -1 : 1));
      const sortDesc = sortProductInformation(fabricNames, sortingCriteria, 'description');
      const sortDetails = sortProductInformation(fabricNames, sortingCriteria, 'details');

      fabricNames = [...sortTitles, ...sortDesc, ...sortDetails];
    } else if (sortingCriteria === ALPHABETICAL_SORTING) {
      fabricNames.sort((fabric, nextFabric) => fabric.fabricName.localeCompare(nextFabric.fabricName));
    } else if (sortingCriteria === PRICE_ASC_SORTING) {
      // If `fabric.fabricPrice` is undefined, don't change sort order.
      fabricNames.sort((fabric, nextFabric) => Number(fabric.fabricPrice) - Number(nextFabric.fabricPrice));
    }
    if (selectedSubstrate) {
      const selectedFabricIndex = fabricNames.findIndex((fabric: ProductInformation) => fabric.fabricKey === selectedSubstrate);
      const selectedFabric = fabricNames[selectedFabricIndex];

      if (selectedFabricIndex !== -1) {
        fabricNames.splice(selectedFabricIndex, 1);
        fabricNames.unshift(selectedFabric);
      }
    }

    fabricNames.forEach((fabricName) => {
      fabricName && sortedFabrics.push(fabricName.fabricKey);
    });

    return {
      sortedFabrics,
      fabricNames
    };
  };

  const prevSortingCriteria = usePreviousValue(sortingCriteria);
  const prevSearchQuery = usePreviousValue(searchQuery);

  const sortingState = useMemo(() => {
    const searchWasChanged = prevSearchQuery !== searchQuery;
    const sortBy = prevSortingCriteria !== sortingCriteria ? sortingCriteria : searchWasChanged ? searchQuery : ALPHABETICAL_SORTING;

    return constructSortFabrics(getFabricsNames(searchWasChanged && searchQuery !== null), sortBy);
  }, [categories, searchQuery, sortingCriteria, category, selectedSubstrate]);


  const getCategoryGroup = (category: FabricShopCategory): string => {
    const group = fabrics?.categories_groups?.[category.id];

    return isNotUndefined(group) ? snakeCaseFromCamelCase(group) : 'others';
  };

  const constructCategories = useMemo((): SortedCategoryType => {
    const localeLookup = isNotUndefined(locale) ? fabrics?.categories?.[locale] : undefined;
    const categories = localeLookup && Object.values(localeLookup);

    const newSortedCategories: SortedCategoryType = {
      useCase: {},
      typeMaterial: {},
      property: {},
      others: {}
    };

    if (!categories) return newSortedCategories;

    const baseUrl = process.env.REACT_APP_IS_SERVER ? originFromState : window.location.origin;

    categories.sort((category: FabricShopCategory, nextCategory: FabricShopCategory) => (
      category.name.localeCompare(nextCategory.name)
    ));

    categories.forEach((categoryItem: FabricShopCategory) => {
      const categoryUrl = new URL(`${baseUrl}${shopBasePath}/${categoryItem.slug}`);
      const categoryName = capitalize(categoryItem['name']);
      const categoryGroup = getCategoryGroup(categoryItem);

      // This `as` casting is necessary to overcome an index type error. Since
      // `categoryGroup` is a string, using it to index into `sortedCategories`
      // could result in looking up a nonexistent key.
      //
      // Using this `as` statement forces TS to treat `categoryGroup` as being
      // definitely one of the keys of `sortedCategories`.
      //
      // This could result in a runtime error except that we immediately verify
      // that the lookup *did* return a value rather than `undefined` in the
      // `if` statement that immediately follows.
      //
      // In the future, as we improve the type signatures throughout, we may be
      // able to remove this.
      const categoryAttribute = newSortedCategories[categoryGroup as keyof typeof newSortedCategories];

      if (typeof categoryAttribute === 'object') {
        const index = Object.keys(categoryAttribute).length;

        if (locationQuery?.order_item_id !== undefined) {
          categoryUrl.searchParams.append('order_item_id', locationQuery.order_item_id.toString());
        }

        categoryAttribute[index] = {
          text: categoryName,
          href: categoryUrl.pathname + categoryUrl.search,
          slug: categoryItem['slug']
        };
      }
    });

    return newSortedCategories;
  }, [fabrics?.categories, locationQuery.order_item_id, originFromState, shopBasePath]);

  const initialCategories = useMemo(() => {
    const emptyRes = {
      useCase: {},
      typeMaterial: {},
      property: {},
      others: {}
    };

    if (!objectIsEmpty(fabrics?.categories) && checkIsStandardFabricModal) {
      return constructCategories;
    }

    return emptyRes;
  }, [fabrics?.categories, checkIsStandardFabricModal, locale]);

  const [sortedCategories, setSortedCategories] = useState<SortedCategoryType>(initialCategories);

  useEffect(() => {
    if (locationQuery?.design_id) {
      dispatch(setDesign(parseInt(locationQuery.design_id, 10)));
    }

    if (objectIsEmpty(fabrics?.fabrics) && checkIsStandardFabricModal) {
      dispatch(fetchFabrics());
    }

    if (!displayFabricsModal) {
      dispatchCloseModal();
      dispatch(pageSetup({
        pageTitle: translate('fabric.head.title'),
        contentInnerExtensions: 'x-1294',
      }));
    }

    dispatch(pageUpdate({
      slideInComponent: {
        name: 'SubstrateFilter',
      },
    }));
  }, []);

  const prevCategories = usePreviousValue(categories);

  useEffect(() => {
    if (categories && !isEqual(prevCategories, categories)) {
      categories && setSortedCategories(constructCategories);
    }
  }, [categories]);

  useEffect(() => {
    const countryWasChanged = prevCountry && prevCountry !== country;
    const measurementSystemWasChanged = prevMeasurementSystem && prevMeasurementSystem !== measurementSystem;
    const currencyWasChanged = prevCurrency && prevCurrency !== currency;

    if (!objectIsEmpty(fabrics?.fabrics) && isNotUndefined(country) && isNotUndefined(measurementSystem) && isNotUndefined(currency) &&
      (measurementSystemWasChanged || countryWasChanged || currencyWasChanged)) {
      dispatch(fetchFabrics());
    }
  }, [country, measurementSystem, currency]);

  useEffect(() => {
    const hideSlideInComponentOnRouteChange = isCategoryPage && showSlideInComponent && !slideInToggled;

    if (hideSlideInComponentOnRouteChange) {
      setSlideInToggled(true);
      dispatchToggleSlideInComponent(false);
    }
  }, [isCategoryPage, showSlideInComponent, slideInToggled]);

  useEffect(() => {
    if (showFilters && !isDesktop(viewport) && fabrics.categories !== undefined) {
      // TODO SP-6816: Ensure these types are correct while implementing types
      // for Redux. This `as` casting works in production, but the details of
      // Redux action creator types is out of scope of the current work.
      dispatchToggleSlideInComponent(
        true,
        category,
        sortedCategories,
        clearFilters,
        searchQuery
      );
    }
  }, [showFilters]);

  const prevCategory = usePreviousValue(category);

  useEffect(() => {
    if (!!prevCategory && prevCategory !== category) {
      clearSearch();
      setSlideInToggled(true);
      dispatchToggleSlideInComponent(false);
    }
  }, [category]);

  useEffect(() => {
    if (displayFabricsModal && searchQueryTextInput?.current) {
      searchQueryTextInput.current.focus();
    }
  }, [displayFabricsModal]);

  useEffect(() => {
    const dataLayer = window.dataLayer;

    if (!cartContentPushedToGA && pendingCart?.pendingCartFetched) {
      setCartContentPushedToGA(true);

      dataLayer.push(cartContentEvent(DATA_FABRIC_SHOP, pendingCart));
    }
  }, [cartContentPushedToGA, pendingCart.pendingCartFetched]);

  const updateSubstrate = (substrateString: string): void => {
    const isWallpaper = substrateString && substrateString.includes(WALLPAPER);
    const fabricParam = trimmedLowerString(substrateString, isWallpaper ? 0 : 7, substrateString.length);
    const routerPath = isNotUndefined(routingPath) ? routingPath : ROUTING_PATH_FABRIC;

    dispatchSetSubstrate(substrateString);

    setSecureCookie(isWallpaper ? WALLPAPER_CODE_COOKIE_NAME : FABRIC_CODE_COOKIE_NAME, substrateString);

    if (isNotUndefined(addToCartDesignId) || isNotUndefined(designSlugDesignId)) {
      browserHistory.replace(
        upsertUrlQuery(
          `${MultiLocaleRouter.localePathname(routerPath)}/${addToCartDesignId || designSlugDesignId}`,
          {
            [FABRIC_PARAM]: fabricParam,
            [ORDER_ITEM_ID]: locationQuery?.[ORDER_ITEM_ID],
            [SIZE]: locationQuery?.[SIZE],
          },
          currentOrigin
        )
      );
    } else {
      browserHistory.replace(shopHomeUrl(locale));
    }
    dispatchCloseModal();
  };

  const searchBoxRunSearchWithNewInput = debounce((): void => {
    if (searchQueryTextInput.current?.value !== undefined) {
      let searchWord = searchQueryTextInput.current.value;

      if (searchQueryTextInput.current.value.endsWith(' ')) {
        searchWord = searchWord.substring(0, searchWord.length - 1);
      }

      setSearchQuery(searchWord.toLowerCase());
    }
  }, 300);

  const clearSearch = (): void => {
    setSearchQuery('');
    dispatch(setFabricShopSearch(''));
  };

  const clearFilters = (): void => {
    clearSearch();
    setMatchingFabrics([]);

    if (category !== undefined) {
      browserHistoryWrapper.push(
        upsertUrlQuery(shopBasePath, {
          [ORDER_ITEM_ID]: locationQuery?.[ORDER_ITEM_ID]
        }, currentOrigin)
      );
    }
  };

  const handleSorting = (sortingCriteria: string) => {
    setSortingCriteria(sortingCriteria);
  };

  const handleInputRefCallback = (input: HTMLInputElement) => {
    searchQueryTextInput.current = input;
  };

  const pageTitle = useMemo(() => (isCategoryPage ?
    translate('titles.fabricShopPage.titleCategory', {
      category
    }) : translate('titles.fabricShopPage.title')), [category]);

  if (
    !fabricOptionsArray &&
    (objectIsEmpty(fabrics?.fabrics) || objectIsEmpty(categories)) ||
    isEmpty(sortingState.sortedFabrics) && !searchQuery
  ) {
    return (<Loading message={translate('fabricShop.loading')}/>);
  }

  return (
    <ErrorBoundary>
      <Helmet title={pageTitle}/>
      {!!alternateLanguageUrls && (Object.keys(alternateLanguageUrls).length > 0) &&
        <Helmet onChangeClientState={removeDuplicateNonHelmetElements}
          link={Object.keys(alternateLanguageUrls).map((lang) => ({
            rel: 'alternate',
            hrefLang: lang,
            href: alternateLanguageUrls[lang]
          }))}/>}
      <Helmet onChangeClientState={removeDuplicateNonHelmetElements}
        link={[
          {
            rel: 'canonical', href: newCanonicalUrl
          }
        ]}
      />
      <SubstrateShop
        fabrics={fabrics || {}}
        selectedSubstrate={selectedSubstrate}
        isWallpaper={isWallpaper}
        wallpaperDimensions={wallpaperDimensions}
        locale={locale}
        currency={currency}
        measurementSystem={measurementSystem}
        isCategoryPage={isCategoryPage}
        category={category}
        designId={addToCartDesignId}
        viewport={viewport}
        dispatchToggleSlideInComponent={dispatchToggleSlideInComponent}
        setSubstrate={updateSubstrate}
        inputRefCallback={handleInputRefCallback}
        searchBoxRunSearchWithNewInput={searchBoxRunSearchWithNewInput}
        searchQuery={searchQuery}
        clearSearch={clearSearch}
        clearFilters={clearFilters}
        sortedFabrics={sortingState.sortedFabrics}
        outOfStockArray={outOfStockArray}
        // next line for dev only, mocks wallpaper response
        // sortedFabrics={[WALLPAPER_SMOOTH, WALLPAPER_WOVEN, WALLPAPER_ISOBAR, WALLPAPER_GRASSCLOTH]}
        sortingCriteria={sortingCriteria}
        handleSorting={handleSorting}
        sortedCategories={sortedCategories}
        displayFabricsModal={displayFabricsModal}
        isStandardFabricModal={checkIsStandardFabricModal}
        designImages={designImages}
      />
    </ErrorBoundary>
  );
};

FabricShopContainer.fetchDataOnServer = new Set([fetchFabrics]);
FabricShopContainer.extendInitialStateBeforeDataLoad = new Set([initWideLayout, initFabricShopTitle]);
FabricShopContainer.extendRedirectsConfig = new Set([fetchFabricsSlugs]);
FabricShopContainer.pageKey = 'FabricShopContainer';

export default FabricShopContainer;
