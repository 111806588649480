import {Locale} from './locale';


export interface InternationalTag {
  best_source_id?: number;
  id?: number; // TODO: SP-6851: Return to Default as REQUIRED
  lang?: Locale; // TODO: SP-6851: Return to Default as REQUIRED
  name: string;
  slug?: string; // Not Guaranteed
}

export type DesignTags = {
  [lang in Locale]: InternationalTag[];
}

export type TagsProps = InternationalTag[] | string[];
export type Tag = InternationalTag | string;

export const isTagObjectArray = (input: TagsProps): input is InternationalTag[] => Array.isArray(input) && (input as InternationalTag[])[0]?.name !== undefined;

export const isTagObject = (input: Tag): input is InternationalTag => (input as InternationalTag).name !== undefined;
