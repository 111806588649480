import {translate, TranslateWrapper} from '../services/index';
import {isNotUndefined, isEmpty, isUndefined} from './validation';
import {REGEX_HTML_LIST_FORMATTING} from '../constants/Validation';

export function getFilteredFabrics(fabrics, isCategoryPage, category, searchQuery) {
  let matchingFabrics = [];

  if (isCategoryPage) {
    matchingFabrics = getFabricsWithCategory(fabrics, isCategoryPage, category);
  }

  if (!isEmpty(searchQuery) && isNotUndefined(searchQuery)) {
    matchingFabrics = getFabricsWithSearch(searchQuery, fabrics, matchingFabrics);
  }

  return matchingFabrics;
}

export function getFabricsWithSearch(searchQuery, fabrics, matchingFabrics) {
  if (isEmpty(matchingFabrics)) {
    matchingFabrics = Object.keys(fabrics['fabrics']);
  }
  const fabricsWithSearchTerm = [];

  matchingFabrics.forEach((fabric) => {
    const categoryForFabric = fabrics['fabrics'][fabric]['categories'];

    // Each fabric should have categories, but in an edge case where one does not, prevent the throwing of an error.
    if (isUndefined(categoryForFabric)) {
      return;
    }

    const fabricCategoryCodes = categoryForFabric[TranslateWrapper.langCode];

    if (isUndefined(fabricCategoryCodes)) {
      return;
    }
    const fabricCategoryList = fabrics['categories'][TranslateWrapper.langCode];
    const fabricDetails = translate(`fabricShop.${fabric}.details`).toLowerCase();
    const fabricDescription = translate(`fabricShop.${fabric}.description`).toLowerCase();
    const fabricHeaders = translate(`fabricShop.${fabric}.name`).toLowerCase();
    const fabricDetailsScrubbed = fabricDetails.replace(REGEX_HTML_LIST_FORMATTING,false);
    const fabricDetailsContainsSearchQuery = fabricDetailsScrubbed.includes(searchQuery);
    const fabricDescriptionContainsSearchQuery = fabricDescription.includes(searchQuery);
    const fabricHeadersContainsSearchQuery = fabricHeaders.includes(searchQuery);

    fabricCategoryCodes.forEach((code) => {
      const category = fabricCategoryList[code];
      const categoryName = category.name.toLowerCase();

      if (categoryName.includes(searchQuery)) {
        if (fabricsWithSearchTerm.indexOf(fabric) === -1) {
          fabricsWithSearchTerm.push(fabric);
        }
      }
    });

    if (fabricDescriptionContainsSearchQuery || fabricDetailsContainsSearchQuery || fabricHeadersContainsSearchQuery) {
      if (fabricsWithSearchTerm.indexOf(fabric) === -1) {
        fabricsWithSearchTerm.push(fabric);
      }
    }
  });

  return fabricsWithSearchTerm;
}

export function getFabricsWithCategory(fabrics, isCategoryPage, category) {
  let fabricsWithCategory = Object.keys(fabrics['fabrics']);
  const categorySlug = category.replace(/\s+/g, '-');
  let categoryId;

  Object.keys(fabrics['categories'][TranslateWrapper.langCode]).forEach((categoryItem) => {
    const categorySlugFuzzyMatches = fabrics['categories'][TranslateWrapper.langCode][categoryItem]['slug'].includes(categorySlug);

    if (isCategoryPage && categorySlugFuzzyMatches) {
      categoryId = parseInt(categoryItem, 10);
    }
  });

  if (isCategoryPage) {
    fabricsWithCategory = fabricsWithCategory.filter((fabric) =>
      fabrics['fabrics'][fabric]['categories'] &&
      fabrics['fabrics'][fabric]['categories'][TranslateWrapper.langCode] &&
      fabrics['fabrics'][fabric]['categories'][TranslateWrapper.langCode].includes(categoryId)
    );
  }

  return fabricsWithCategory;
}

export function getCategoryName(category, fabrics) {
  let categoryName;

  Object.values(fabrics.categories[TranslateWrapper.langCode]).forEach((categoryItem) => {
    if (categoryItem.slug === category) {
      categoryName = categoryItem.name;
    }
  });

  return categoryName;
}
