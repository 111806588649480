import React from 'react';

import Collapsible from '../Collapsible/Collapsible';


export interface ConditionalCollapsibleProps {
  children: JSX.Element;
  heading: string;
  isDesktop: boolean;
}

const ConditionalCollapsible = ({children, heading, isDesktop}: ConditionalCollapsibleProps): JSX.Element => (
  isDesktop ? children :
    <Collapsible
      title={heading}
      classname='x-bottom-border'
      sizeClass='x-trigger-large'
      subtitleTextClass='h3 x-flush-left'
      collapsibleOpen={false}
      triggerType={isDesktop ? 'plus' : 'chevron'}
      content={children}/>
);

export default ConditionalCollapsible;
