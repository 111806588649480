import format from 'date-fns/format';
import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';

import {DE_DATE_FORMAT, ES_DATE_FORMAT, FR_DATE_FORMAT, US_DATE_FORMAT} from '../constants/DateFormats';
import {DE, ES, FR} from '../constants/SupportedLanguages';


export const dateFormat = (locale: string | undefined, date: Date): string => {
  let dateLocale = enLocale;
  let dateFormat = US_DATE_FORMAT;

  switch (locale) {
    case DE: {
      dateLocale = deLocale;
      dateFormat = DE_DATE_FORMAT;
      break;
    }
    case ES: {
      dateLocale = esLocale;
      dateFormat = ES_DATE_FORMAT;
      break;
    }
    case FR: {
      dateLocale = frLocale;
      dateFormat = FR_DATE_FORMAT;
      break;
    }

    default:
      dateLocale = enLocale;
      dateFormat = US_DATE_FORMAT;
  }

  return format(date, dateFormat, {
    locale: dateLocale
  });
};
