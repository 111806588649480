import Translate from 'i18n-react';
import React from 'react';

import {EN} from '../../../../constants/SupportedLanguages';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import ImageUrls from '../../../../utils/design';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';
import './_designer-info-box.scss';


export interface DesignerInfoBoxProps {
  designerUserName: string;
  designerDescription?: string;
  isMugshot?: boolean;
  designerInfoImageId?: number;
  designerInfoImageFile?: string;
  isDesktop: boolean;
  locale: Locale;
}

const DesignerInfoBox = ({isMugshot, designerInfoImageId, designerInfoImageFile, designerUserName, designerDescription, isDesktop, locale}: DesignerInfoBoxProps): JSX.Element => {
  const s3host = useApiHost(ApiHostsEnum.s3);
  const gardenHost = useApiHost(ApiHostsEnum.garden);

  const designerAvatar = isMugshot ? ImageUrls.avatarDesignerImageUrl(gardenHost, designerInfoImageId, designerInfoImageFile) :
    ImageUrls.designImageUrl(s3host, designerInfoImageId, designerInfoImageFile, 'thumb').thumbnail;
  const infoText = (designerDescription && locale === EN) ? <p className='designer-info-text'>{designerDescription}</p> : false;

  return (
    <InfoBox heading={translate('fabric.designerInfo', {
      designer: designerUserName
    })}
    infoText={infoText}
    imageUrl={designerAvatar}
    imageAlt={designerUserName}
    imageClass='designer-avatar'
    boxClass='b-designer-info-box'
    isDesktop={isDesktop}
    moreInfo={<LinkWrapper
      className='learn-more-link'
      hrefValue={Translate.translate('xurls.userProfile', { // TODO: SP-8362 Refactor or extend translate utility.
        userName: designerUserName
      })?.toString() || ''}
      title={translate('designs.designerInfo.designerShopTitle', {
        designer: designerUserName
      })}
      contents={translate('designs.designerInfo.designerShopTitle', {
        designer: designerUserName
      })} />}
    />
  );
};

export default DesignerInfoBox;
