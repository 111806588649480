import React from 'react';

import ShippingInfo from '../../components/Product/ShippingInfo/ShippingInfo';
import ConditionalCollapsible from '../../components/Reusable/Content/ConditionalCollapsible/ConditionalCollapsible';
import {translate} from '../../services';
import {Locale} from '../../shapes/locale';


export interface ShippingInfoContainerProps {
  locale: Locale;
  isDesktop: boolean;
}

const ShippingInfoContainer = ({locale, isDesktop}: ShippingInfoContainerProps): JSX.Element => {
  const heading = translate('fabric.shippingInfo.shippingAndReturns');

  return (
    <ConditionalCollapsible heading={heading} isDesktop={isDesktop}>
      <ShippingInfo
        locale={locale}
        heading={heading}
        isDesktop={isDesktop}
      />
    </ConditionalCollapsible>
  );
};

export default ShippingInfoContainer;
