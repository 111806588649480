import xor from 'lodash/xor';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {serverError} from '../../../actions/server';
import request from '../../../application/api/request';
import {EN, supportedLanguages} from '../../../constants/SupportedLanguages';
import {FetchFabricsResponse} from '../../../entities/fabrics/types/FetchFabricsResponse';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {AppLocalities} from '../../../entities/pageSetup/countries/types';
import {
  MultiLocaleRouterConfig,
  SingleRouteConfig
} from '../../../server/bootstrap/loadMultiLocaleRouterConfig/MultiLocaleRouterConfigTypes';
import {FabricShopCategory, State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';
import {queryParamsOverrideUserPreferences} from '../../../utils/stateToPropsHelpers';


const getTranslatedUrl = (fabricShopCategory: FabricShopCategory | undefined, lang: AppLocalities, mlcForPage: SingleRouteConfig) => {
  let res = `${mlcForPage[lang]}`;

  if (fabricShopCategory) res += `/${fabricShopCategory.slug}`;

  return res;
};
const getCategoryId = (el: AppLocalities) => (el === EN ? 'id' : 'best_source_id');

export function fetchFabricsSlugs(): ThunkAction<Promise<MultiLocaleRouterConfig | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const {
      server: {multiLocaleRouterConfig},
      routingData: {routesParams, routesParams: {category}},
      pageSetup: {pageKey}
    } = state;

    const pageLang = routesParams.pageLang as AppLocalities;
    const mlcFabricShop = multiLocaleRouterConfig['shop.fabric'];
    const {country, measurementSystem, currency} = queryParamsOverrideUserPreferences(state);

    if (!category) {
      return Promise.resolve({
        [pageKey]: mlcFabricShop
      });
    }

    return request<FetchFabricsResponse>(state, {
      url: `${selectAlpenroseHost(state)}/fabrics`,
      params: {
        measurement_system: measurementSystem,
        currency,
        shipping_country: country
      },
    })
      .then(({data: dataObj}) => {
        const {categories = {}} = dataObj.data;
        const res: SingleRouteConfig = {};

        const fabricShopCategory = Object.values(categories[pageLang]).find(({slug}) => slug === category);

        if (!fabricShopCategory) {
          // eslint-disable-next-line no-console
          console.log('fetchFabricsSlugs object for slug not found, this is an error');
        }
        res[pageLang] = getTranslatedUrl(fabricShopCategory, pageLang, mlcFabricShop);
        const categoryId = fabricShopCategory && fabricShopCategory[getCategoryId(pageLang)];
        const otherLangs = xor(supportedLanguages, [pageLang]) as AppLocalities[];

        otherLangs.forEach((lang) => {
          const category = categoryId ? Object.values(categories[lang]).find((el) => el[getCategoryId(lang)] === categoryId) : undefined;

          res[lang] = getTranslatedUrl(category, lang as AppLocalities, mlcFabricShop);
        });

        return {
          [pageKey]: res
        };
      })
      .catch((error: RequestError) => {
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch(serverError(error));
        } else {
          console.log('Error caught in the `fetchFabricsSlugs` function', error); // eslint-disable-line
        }
      });
  };
}


