import React, {SyntheticEvent} from 'react';

import {SPOONFLOWER} from '../../../constants/Common';
import {FABRIC_PARAM, ORDER_ITEM_ID, COLOR_PARAM} from '../../../constants/Parameters';
import {ROUTING_PATH_SOLID} from '../../../constants/Products';
import {ApiHostsEnum} from '../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter} from '../../../services';
import {setPlaceholderImage} from '../../../utils/design';
import {upsertUrlQuery} from '../../../utils/url';
import LazyImage from '../LazyImage/LazyImage';
import LinkWrapper from '../LinkWrapper/LinkWrapper';


export interface ColorItemPdpProps {
  colorId: number;
  colorTitle: string;
  imageUrl: string;
  pushProductClickToGTM?: (colorId: number, designerName: string, index: number, productLink: string, userId?: number) => void;
  index: number;
  orderItemId?: string; // SP-8943 Solids edit mode
  fabricQuery?: string;
  colorQuery?: string;
  userId?: number;
}

const ColorItemPdp = ({
  imageUrl, colorTitle, colorId, pushProductClickToGTM, index,
  colorQuery, orderItemId, fabricQuery, userId
}: ColorItemPdpProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);
  const currentOrigin = useOrigin();

  const productLinkParam = {
    [ORDER_ITEM_ID]: orderItemId,
    [COLOR_PARAM]: colorQuery,
    [FABRIC_PARAM]: fabricQuery
  };
  const productLink = upsertUrlQuery(`${MultiLocaleRouter.localePathname(ROUTING_PATH_SOLID)}`, productLinkParam, currentOrigin);

  const pushProductOnClick = () => pushProductClickToGTM && pushProductClickToGTM(colorId, SPOONFLOWER, index, productLink, userId);
  const displayImageSource = `${imagesHost}/solids/${imageUrl}`;

  const onErrorFunc = (e: SyntheticEvent<HTMLImageElement, Event>) => setPlaceholderImage(e, imagesHost);

  const image = <LazyImage
    aspectRatio={true}
    className='item-pic'
    src={displayImageSource}
    alt={colorTitle}
    aria-label={colorTitle}
    itemProp='image'
    onError={onErrorFunc}
    height='294'
    width='294'/>;

  return (
    <div
      className='b-design-item x-condensed'
      itemProp='isRelatedTo'
      itemScope itemType='https://schema.org/Product'
      onClick={pushProductOnClick}>
      <LinkWrapper hrefValue={productLink} contents={image} title={colorTitle} className='design-link' />
      <div className='design-item-text'>
        <span className='visuallyhidden' itemProp='name'>{colorTitle}</span>
        <span className='visuallyhidden' itemProp='colorID'>{colorId}</span>
        <p>{colorTitle}</p>
      </div>
    </div>
  );
};

export default ColorItemPdp;
