import React, {useEffect, useState} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';

import {clearAllProducts} from '../../actions/allProducts';
import AllProductsList, {AllProductDesign} from '../../components/Product/AllProductsList/AllProductsList';
import Loading from '../../components/Reusable/Loading/Loading';
import {
  HOME_GOOD_COCKTAIL_NAPKIN,
  HOME_GOOD_DINNER_NAPKIN,
  HOME_GOOD_RECTANGULAR_TABLE_CLOTH,
  HOME_GOOD_ROUND_TABLE_CLOTH,
  HOME_GOOD_TABLE_RUNNER,
  HOME_GOOD_TEA_TOWEL,
  SIZE_16_BY_72,
  SIZE_70_ROUND,
  SIZE_70_SQUARE,
  SIZE_STANDARD,
  STYLE_STANDARD,
  WALLPAPER_SMOOTH,
  HOME_GOOD_PLACEMAT,
  HOME_GOOD_WALL_HANGING,
  SIZE_16_BY_24,
  SIZE_50_BY_84,
  HOME_GOOD_CURTAIN_PANEL,
  HOME_GOOD_LUMBAR_THROW_PILLOW,
  STYLE_KNIFE_EDGED,
  SIZE_18,
  HOME_GOOD_THROW_BLANKET,
  HOME_GOOD_SQUARE_THROW_PILLOW,
  HOME_GOOD_DUVET_COVER,
  SIZE_KING,
  HOME_GOOD_STANDARD_PILLOW_SHAM,
  HOME_GOOD_EURO_PILLOW_SHAM,
  HOME_GOOD_SHEET_SET,
  HOME_GOOD_LONG_LUMBAR_THROW_PILLOW,
  WALLPAPER,
  FABRIC,
} from '../../constants/Codes';
import {DATA_ALL_PRODUCTS_LIST_TYPE, DATA_BEDDING, DATA_DINING, DATA_FABRIC, DATA_HOME_GOOD, DATA_LIVING, DATA_WALLPAPER} from '../../constants/DataLayer';
import {IS_HOME_GOOD} from '../../constants/Products';
import {ApiHostsEnum} from '../../entities/pageSetup/apiHosts';
import useApiHost from '../../entities/pageSetup/apiHosts/useApiHost';
import useOrigin from '../../entities/pageSetup/origin/useOrigin';
import {MultiLocaleRouter, translate} from '../../services';
import {DesignResponse} from '../../shapes/design';
import {Locale} from '../../shapes/locale';
import {ProductTypeAbbreviated} from '../../shapes/products';
import {State} from '../../store/initialState';
import {itemNameMask, productClickEvent} from '../../utils/dataLayer';
import {allProductsImpressionsEvent} from '../../utils/dataLayerEvents';
import ImageUrls, {productTypeAbbreviate} from '../../utils/design';
import {pdpSelectedSubstrate, selectedHomeGoodFabric} from '../../utils/stateToPropsHelpers';
import {upsertUrlQuery} from '../../utils/url';
import {isEmpty, isNotUndefined, objectIsNotEmpty} from '../../utils/validation';


export interface AllProductListContainerProps {
  productTypeAbbreviated: ProductTypeAbbreviated;
  designId?: number;
  locale: Locale;
  selectedSubstrate: string;
  productType: string;
  productSize?: string;
  sizeQuery?: string;
  homeGoodType?: string;
  rawItemPrice?: number;
  quantity?: number;
  colorCode?: string;
  queryParams: Record<string, string>;
  userId?: number;
}


const AllProductListContainer = ({
  designId, productType, productSize, rawItemPrice, quantity,
  productTypeAbbreviated, homeGoodType, locale, sizeQuery, colorCode, queryParams,
  userId
}: AllProductListContainerProps): JSX.Element | null => {
  const currentOrigin = useOrigin();
  const gardenHost = useApiHost(ApiHostsEnum.garden);
  const {viewport, allProducts, currency, fabricSelectedSubstrate, wallpaperSelectedSubstrate, homeGoodSelectedSubstrate} = useSelector((state: State) => {
    const fabricSelectedSubstrate = pdpSelectedSubstrate(state, FABRIC);
    const wallpaperSelectedSubstrate = pdpSelectedSubstrate(state, WALLPAPER);
    const homeGoodSelectedSubstrate = selectedHomeGoodFabric(state);

    return ({
      viewport: state.pageSetup.viewport,
      allProducts: state.allProducts,
      currency: state.user.preferences.currency,
      fabricSelectedSubstrate,
      wallpaperSelectedSubstrate,
      homeGoodSelectedSubstrate
    });
  }, shallowEqual);

  const design = allProducts.design as DesignResponse;
  const dispatch = useDispatch();
  const [productImpressionsPushedtoGA, setProductImpressionsPushedtoGA] = useState(false);
  const [productClickPushedtoGA, setProductClickPushedtoGA] = useState(false);

  useEffect(() => () => {
    dispatch(clearAllProducts());
  }, []);

  if (allProducts.isFetching || !design) {
    return (
      <article className={'b-all-products-list'}>
        <h3 className='h3'>{translate('designs.alsoAvailableAs')}</h3>
        <Loading message={translate('common.loading')} />
      </article>
    );
  }

  const [isForSaleOnFabric, isForSaleOnWallpaper, isForSaleOnHomeGood, isRotatedFatQuarter] = [
    design.for_sale_as_fabric, design.for_sale_as_wallpaper, design.for_sale_as_home_good, design.for_sale_as_tea_towel
  ];
  const displayHomeGoods = isForSaleOnHomeGood && !isRotatedFatQuarter;

  const {images} = design;
  const wallpaperImages = images.wallpaper;
  const homeGoodImages = images.home_good;
  const designSlugLocales = design.all_locale_slugs;

  const rippleImage = objectIsNotEmpty(images) && ImageUrls.gardenSimpleRippleFQ(gardenHost, designId, images);

  const createHomeGoodItem = (productType: string, department: string, productSize = SIZE_STANDARD, productStyle = STYLE_STANDARD) => ({
    designTitle: translate(`homeGood.${productType}.name`),
    imageSrc: ImageUrls.gardenProductImageUrl(homeGoodImages, productType, productSize, productStyle).mobile,
    productLink: `${MultiLocaleRouter.localePathname(productType)}/${designSlugLocales[locale]}`,
    productType,
    productSize,
    // for dataLayer
    itemCategories: {
      item_category: DATA_HOME_GOOD,
      item_category2: department,
      item_category3: translate(`homeGood.${productType}.name`),
    },
    item_name: homeGoodSelectedSubstrate
  });

  const createKitchenDiningProducts = () => {
    if (!isForSaleOnHomeGood) {
      return [];
    }

    let kitchenDining = [createHomeGoodItem(HOME_GOOD_TEA_TOWEL, DATA_DINING)];

    if (!isRotatedFatQuarter) {
      kitchenDining = [
        createHomeGoodItem(HOME_GOOD_RECTANGULAR_TABLE_CLOTH, DATA_DINING, SIZE_70_SQUARE),
        createHomeGoodItem(HOME_GOOD_ROUND_TABLE_CLOTH, DATA_DINING, SIZE_70_ROUND),
        createHomeGoodItem(HOME_GOOD_TABLE_RUNNER, DATA_DINING, SIZE_16_BY_72),
        createHomeGoodItem(HOME_GOOD_COCKTAIL_NAPKIN, DATA_DINING),
        createHomeGoodItem(HOME_GOOD_DINNER_NAPKIN, DATA_DINING),
        createHomeGoodItem(HOME_GOOD_PLACEMAT, DATA_DINING)].concat(kitchenDining);
    }

    return kitchenDining;
  };

  const createDecorLivingProducts = () => {
    if (!isForSaleOnHomeGood) {
      return [];
    }

    let decorLiving = [createHomeGoodItem(HOME_GOOD_WALL_HANGING, DATA_LIVING, SIZE_16_BY_24)];

    if (!isRotatedFatQuarter) {
      decorLiving = [
        createHomeGoodItem(HOME_GOOD_CURTAIN_PANEL, DATA_LIVING, SIZE_50_BY_84),
        createHomeGoodItem(HOME_GOOD_SQUARE_THROW_PILLOW, DATA_LIVING, SIZE_18, STYLE_KNIFE_EDGED),
        createHomeGoodItem(HOME_GOOD_LUMBAR_THROW_PILLOW, DATA_LIVING, SIZE_STANDARD, STYLE_KNIFE_EDGED),
        createHomeGoodItem(HOME_GOOD_THROW_BLANKET, DATA_LIVING)].concat(decorLiving);
    }

    return decorLiving;
  };

  const createBeddingProducts = () => (displayHomeGoods ? [
    createHomeGoodItem(HOME_GOOD_DUVET_COVER, DATA_BEDDING, SIZE_KING),
    createHomeGoodItem(HOME_GOOD_STANDARD_PILLOW_SHAM, DATA_BEDDING, SIZE_STANDARD, STYLE_KNIFE_EDGED),
    createHomeGoodItem(HOME_GOOD_EURO_PILLOW_SHAM, DATA_BEDDING, SIZE_STANDARD, STYLE_KNIFE_EDGED),
    createHomeGoodItem(HOME_GOOD_SHEET_SET, DATA_BEDDING, SIZE_KING),
    createHomeGoodItem(HOME_GOOD_LONG_LUMBAR_THROW_PILLOW, DATA_BEDDING, SIZE_STANDARD, STYLE_KNIFE_EDGED)
  ] : []);

  const createWallpaperProduct = () => (isForSaleOnWallpaper ? [
    {
      designTitle: translate('designs.productsOverview.wallpaper'),
      productType: WALLPAPER,
      productLink: upsertUrlQuery(`${MultiLocaleRouter.localePathname('wallpaper.page')}/${designId}`, Object.assign({}, {}), currentOrigin),
      imageSrc: wallpaperImages[WALLPAPER_SMOOTH] && ImageUrls.gardenWallpaperImageUrl(wallpaperImages[WALLPAPER_SMOOTH], 'wallpaper_smooth_1').mobile,
      // for dataLayer
      itemCategories: {
        item_category: DATA_WALLPAPER
      },
      item_name: wallpaperSelectedSubstrate
    }
  ] : []);

  const createFabricProduct = () => (isForSaleOnFabric ? [
    {
      designTitle: translate('designs.productsOverview.fabric'),
      productType: FABRIC,
      productLink: upsertUrlQuery(`${MultiLocaleRouter.localePathname('fabric.page')}/${designId}`, Object.assign({}, {}), currentOrigin),
      imageSrc: rippleImage,
      // for dataLayer
      itemCategories: {
        item_category: DATA_FABRIC
      },
      item_name: fabricSelectedSubstrate
    }
  ] : []);

  const kitchenDiningProducts = createKitchenDiningProducts();
  const decorLivingProducts = createDecorLivingProducts();
  const beddingProducts = createBeddingProducts();
  const wallpaperProduct = createWallpaperProduct();
  const fabricProduct = createFabricProduct();

  const allProductsList = [
    ...fabricProduct,
    ...wallpaperProduct,
    ...kitchenDiningProducts,
    ...decorLivingProducts,
    ...beddingProducts
  ];

  const filteredProductsList = allProductsList.filter((product) => product.productType !== productType);

  const getCrossSellDesignData = (product?: AllProductDesign) => {
    if (!product) {
      return {
        department: undefined,
        category: undefined
      };
    }

    const productTypeAbbreviated = productTypeAbbreviate(product.productType);
    const isHomeGood = IS_HOME_GOOD(productTypeAbbreviated);
    const category = product.itemCategories.item_category;
    const designDepartment = isHomeGood ? product.itemCategories.item_category2 : category;

    return {
      department: designDepartment,
      category
    };
  };

  const pushProductClickToGTM = (itemId: number, designerUserName: string, index: number, productLink: string, designTitle: string) => {
    const position = index + 1;
    const dataLayer = window.dataLayer;

    const product = filteredProductsList.find((el) => el.designTitle === designTitle);
    const {department, category} = getCrossSellDesignData(product);

    if (!productClickPushedtoGA && rawItemPrice && product) {
      setProductClickPushedtoGA(true);
      const eventData = {
        brand: designerUserName,
        category,
        currency,
        department,
        id: itemId,
        list: DATA_ALL_PRODUCTS_LIST_TYPE,
        name: product.designTitle,
        position,
        price: rawItemPrice,
        product_link: productLink,
        search_term: queryParams['q'],
        userId,
      };

      isNotUndefined(designId) && dataLayer.push(productClickEvent(eventData));
    }
  };

  const pushProductImpressionsToGTM = () => {
    const designs = !isEmpty(filteredProductsList) ?
      filteredProductsList.map((product, index) => {
        const {department} = getCrossSellDesignData(product);

        return (
          {
            item_name: itemNameMask(translate(`fabricShop.${product.item_name}.name`)),
            design_id: design.id,
            price: rawItemPrice,
            quantity,
            department,
            item_brand: design.designer.screen_name,
            item_list_name: DATA_ALL_PRODUCTS_LIST_TYPE,
            index: index + 1,
            ...product.itemCategories
          }
        );
      }) : [];

    const dataLayer = window.dataLayer;

    if (!productImpressionsPushedtoGA && !isEmpty(designs) && rawItemPrice) {
      setProductImpressionsPushedtoGA(true);
      // Clear the previous ecommerce object.
      dataLayer.push({
        ecommerce: {
          item_list_name: null, items: null
        }
      });
      dataLayer.push(allProductsImpressionsEvent('', currency, designs, userId, DATA_ALL_PRODUCTS_LIST_TYPE));
    }
  };

  !process.env.REACT_APP_IS_SERVER && pushProductImpressionsToGTM();

  return !isEmpty(filteredProductsList) ? <AllProductsList
    viewport={viewport}
    allProductsList={filteredProductsList}
    pushProductClickToGTM={pushProductClickToGTM}
    userId={userId}
    allProductsDesign={design}
  /> : null;
};

export default AllProductListContainer;
