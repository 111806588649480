import React from 'react';

import './_recommended-fabric.scss';
import {translate} from '../../../../services';
import {MeasurementType} from '../../../../shapes/measurement';


interface RecommendedFabricProps {
  measurementSystem: MeasurementType;
  recommendedFabricCode: string;
  recommendedFabricWidth: string;
}

const RecommendedFabric = ({measurementSystem, recommendedFabricCode, recommendedFabricWidth}: RecommendedFabricProps): JSX.Element => (
  <section className='b-recommended-fabric detail-section'>{translate(`fabric.content.fabricRecommendation.${measurementSystem}`, {
    fabric: translate(`fabricShop.${recommendedFabricCode}.name`),
    width: recommendedFabricWidth
  })}
  </section>
);

export default RecommendedFabric;
