import Translate from 'i18n-react/dist/i18n-react';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';

import {clearCrossSell} from '../../actions/crossSell';
import DesignCrossSellList from '../../components/Product/DesignCrossSellList/DesignCrossSellList';
import {SPOONFLOWER} from '../../constants/Common';
import {DATA_HOME_GOOD, DATA_FABRIC, DATA_WALLPAPER, DATA_CROSS_SELL_LIST_TYPE, DATA_SOLID} from '../../constants/DataLayer';
import {LAZY_OFFSET} from '../../constants/Design';
import {ORDER_ITEM_ID} from '../../constants/Parameters';
import {IS_FABRIC, IS_HOME_GOOD, IS_SOLID, IS_WALLPAPER} from '../../constants/Products';
import {translate} from '../../services';
import {crossSellExists, isCrossSellDesign} from '../../shapes/crossSell';
import {Locale} from '../../shapes/locale';
import {ProductTypeAbbreviated} from '../../shapes/products';
import {State} from '../../store/initialState';
import {productClickEvent} from '../../utils/dataLayer';
import {allProductsImpressionsEvent} from '../../utils/dataLayerEvents';
import {fetchColorCrossSellDesigns} from '../../utils/fetch';
import {getSolidSize} from '../../utils/formattingHelpers';
import {getHomeGoodDepartment} from '../../utils/orderItemData';
import {isEmpty, isNotUndefined} from '../../utils/validation';
import fetchCrossSellDesigns from '../Product/api/fetchCrossSellDesigns';


export interface DesignCrossSellListContainerProps {
  productTypeAbbreviated: ProductTypeAbbreviated;
  designId?: number;
  locale: Locale;
  selectedSubstrate: string;
  productType: string;
  productSize?: string;
  sizeQuery?: string;
  homeGoodType?: string;
  rawItemPrice?: number;
  quantity?: number;
  colorCode?: string;
  queryParams: Record<string, string>;
  userId?: number;
}


const DesignCrossSellListContainer = ({
  designId, selectedSubstrate, productType, productSize, rawItemPrice, quantity,
  productTypeAbbreviated, homeGoodType, locale, sizeQuery, colorCode, queryParams,
  userId
}: DesignCrossSellListContainerProps): JSX.Element | null => {
  const {
    orderItemId, viewport, windowPosition, windowHeight, crossSellDesigns, crossSellIsFetching,
    crossSellFetchingIsReady, crossSellFetchingHasFailed, currency, measurementSystem, solidsWidth, solidsLength
  } = useSelector((state: State) => {
    const crossSell = state.crossSell;

    return {
      orderItemId: state.routing?.locationBeforeTransitions.query[ORDER_ITEM_ID],
      viewport: state.pageSetup.viewport,
      windowPosition: state.pageSetup.windowPosition,
      windowHeight: state.pageSetup.windowHeight || 0,
      crossSellDesigns: crossSell.designs,
      crossSellIsFetching: crossSell.isFetching,
      crossSellFetchingIsReady: crossSellExists(crossSell) ? crossSell.fetchingIsReady : undefined,
      crossSellFetchingHasFailed: crossSellExists(crossSell) ? crossSell.hasFailed : undefined,
      currency: state.user.preferences.currency,
      solidsWidth: state.solids.width,
      solidsLength: state.solids.length,
      measurementSystem: state.user.preferences.measurement_system
    };
  }, shallowEqual);

  const dispatch = useDispatch();
  const dispatchColorCrossSellDesigns = (colorParam: string, fabricParam: string) => dispatch(fetchColorCrossSellDesigns(colorParam, fabricParam));

  const [crossSellPosition, setCrossSellPosition] = useState(0);
  const [productImpressionsPushedtoGA, setProductImpressionsPushedtoGA] = useState(false);
  const [productClickPushedtoGA, setProductClickPushedtoGA] = useState(false);

  const fetchingIsReady = isNotUndefined(crossSellFetchingIsReady) && crossSellFetchingIsReady;
  const solidParams = colorCode && queryParams;

  useEffect(() => () => {
    dispatch(clearCrossSell());
  }, []);

  useEffect(() => {
    if (isEmpty(crossSellDesigns) && !crossSellIsFetching && crossSellPosition !== 0) {
      if (crossSellPosition < windowHeight) {
        designId && dispatch(fetchCrossSellDesigns());
        solidParams && dispatchColorCrossSellDesigns(queryParams.color, queryParams.fabric);
      } else if ((windowHeight > crossSellPosition) && windowPosition !== 0 && !fetchingIsReady) {
        designId && dispatch(fetchCrossSellDesigns());
        solidParams && dispatchColorCrossSellDesigns(queryParams.color, queryParams.fabric);
      }
    }
  }, [crossSellPosition, windowPosition]);

  useEffect(() => {
    if (!isEmpty(crossSellDesigns) && fetchingIsReady) {
      solidParams && dispatchColorCrossSellDesigns(queryParams.color, queryParams.fabric);
    }
  }, [queryParams?.color, queryParams?.fabric]);

  const getPosition = (element: HTMLElement | null) => setCrossSellPosition(element ? (element.getBoundingClientRect()['top'] + LAZY_OFFSET) : 0);

  const getCrossSellDesignData = () => {
    const isSolid = IS_SOLID(productTypeAbbreviated);
    const isHomeGood = IS_HOME_GOOD(productTypeAbbreviated);
    const isFabric = IS_FABRIC(productTypeAbbreviated);
    const isWallpaper = IS_WALLPAPER(productTypeAbbreviated);
    const homeGoodOrSubstrateName = isSolid ? translate(`solidColors.${colorCode}.name`) :
      isHomeGood ? Translate.translate(`en.homeGood.${homeGoodType}`) : Translate.translate(`en.fabricShop.${selectedSubstrate}`);
    const designDepartment = isHomeGood && homeGoodType ? getHomeGoodDepartment(homeGoodType) : isFabric || isSolid ? DATA_FABRIC : isWallpaper ? DATA_WALLPAPER : undefined;
    const designCategory = isHomeGood ? DATA_HOME_GOOD :
      isFabric ? DATA_FABRIC :
        isWallpaper ? DATA_WALLPAPER :
          isSolid ? DATA_SOLID : undefined;
    const designVariant = isNotUndefined(productSize) &&
      (isHomeGood ? Translate.translate(`en.homeGood.cart.productSize.${productSize}`) :
        (isFabric || isWallpaper) ? Translate.translate(`en.cart.cart.${productSize}`) :
          isSolid && solidsWidth && solidsLength ? getSolidSize('en', solidsWidth, solidsLength, measurementSystem) : undefined);

    return {
      name: homeGoodOrSubstrateName,
      department: designDepartment,
      category: designCategory,
      variant: designVariant
    };
  };

  const pushProductImpressionsToGTM = () => {
    const {name, department, category, variant} = getCrossSellDesignData();

    const designs = !isEmpty(crossSellDesigns) ? isCrossSellDesign(crossSellDesigns) ?
      crossSellDesigns.map((design, index) => (
        {
          item_name: name,
          design_id: design.id,
          price: rawItemPrice,
          quantity,
          department,
          item_brand: design.designer.screen_name,
          item_category: category,
          item_variant: variant,
          item_list_name: DATA_CROSS_SELL_LIST_TYPE,
          index: index + 1
        }
      )): crossSellDesigns.map((color, index) => (
        {
          item_name: translate(`solidFabrics.${color.fabric_code}.name`),
          color: translate(`solidColors.${color.color_code}.name`),
          design_id: color.id,
          price: rawItemPrice,
          quantity,
          department,
          item_brand: SPOONFLOWER,
          item_category: category,
          item_variant: variant,
          item_list_name: DATA_CROSS_SELL_LIST_TYPE,
          index: index + 1
        })) : [];

    const dataLayer = window.dataLayer;

    if (!productImpressionsPushedtoGA && !isEmpty(designs) && rawItemPrice) {
      setProductImpressionsPushedtoGA(true);
      // Clear the previous ecommerce object.
      dataLayer.push({
        ecommerce: {
          item_list_name: null, items: null
        }
      });
      dataLayer.push(allProductsImpressionsEvent('',currency, designs, userId, DATA_CROSS_SELL_LIST_TYPE));
    }
  };

  const pushProductClickToGTM = (itemId: number, designerUserName: string, index: number, productLink: string, userId?: number) => {
    const isSolid = IS_SOLID(productTypeAbbreviated);
    const {name, department, category, variant} = getCrossSellDesignData();
    const position = index + 1;
    const dataLayer = window.dataLayer;

    if (!productClickPushedtoGA && rawItemPrice) {
      setProductClickPushedtoGA(true);
      const eventData = {
        category,
        currency,
        brand: designerUserName,
        department,
        list: DATA_CROSS_SELL_LIST_TYPE,
        id: itemId,
        name,
        position,
        price: rawItemPrice,
        product_link: productLink,
        search_term: queryParams['q'],
        variant,
        userId
      };

      (isNotUndefined(designId) || isSolid) && dataLayer.push(productClickEvent(eventData));
    }
  };

  if (crossSellFetchingHasFailed) {
    return null;
  }

  !process.env.REACT_APP_IS_SERVER && pushProductImpressionsToGTM();

  return (
    <DesignCrossSellList
      locale={locale}
      productTypeAbbreviated={productTypeAbbreviated}
      orderItemId={orderItemId}
      selectedSubstrate={selectedSubstrate}
      viewport={viewport}
      crossSellDesigns={crossSellDesigns}
      productType={productType}
      sizeQuery={sizeQuery}
      getPosition={getPosition}
      pushProductClickToGTM={pushProductClickToGTM}
      userId={userId}/>
  );
};

export default DesignCrossSellListContainer;
