import React, {MutableRefObject} from 'react';

import './_product-form-fabric-details.scss';
import {TEST_SWATCH} from '../../../../constants/Codes';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {RefScrollIntoView} from '../../../../shapes/refScroll';
import {getMeasurement} from '../../../../utils/fabrics';
import {isFunction, objectIsNotEmpty} from '../../../../utils/validation';
import Icon from '../../../Reusable/Icon/Icon';


interface ProductFormFabricDetailsProps {
  locale: Locale;
  selectedSubstrate: string;
  fabricUnit: string;
  handleShopClick?: (pathName?: string) => void;
  handleSwatchClick?: (size: string) => void;
  productInfoRef?: MutableRefObject<HTMLButtonElement & RefScrollIntoView | null>;
  swatchButtonText?: string;
}

const ProductFormFabricDetails = ({selectedSubstrate, fabricUnit, locale, productInfoRef, handleShopClick, swatchButtonText, handleSwatchClick}: ProductFormFabricDetailsProps): JSX.Element => {
  function handleMoreClick() {
    objectIsNotEmpty(productInfoRef) && productInfoRef.current && productInfoRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }

  function navigateToShopPage() {
    isFunction(handleShopClick) && handleShopClick();
  }

  function navigateToFabricPage() {
    isFunction(handleShopClick) && handleShopClick('shop.fabric');
  }

  function handleSelectSwatch() {
    isFunction(handleSwatchClick) && handleSwatchClick(TEST_SWATCH);
  }

  return (
    <article className='b-product-form-fabric-details'>
      <section className='detail-section'>
        <h2 className='fabric-detail-headline'>{translate('fabric.fabricDetail.printableWidth')}</h2>
        <p className='fabric-detail-description'>{translate(`fabricShop.SIZE_WIDTH.${fabricUnit}`, {
          width: getMeasurement(selectedSubstrate, fabricUnit, 'printableWidth', locale)
        })}</p>
      </section>
      <section className='detail-section'>
        <h2 className='fabric-detail-headline'>{translate('fabric.fabricDetail.weight')}</h2>
        <p className='fabric-detail-description'>{translate(`fabricShop.SIZE_WEIGHT.${fabricUnit}`, {
          weight: getMeasurement(selectedSubstrate, fabricUnit, 'weight', locale)
        })}</p>
      </section>
      <section className='detail-section'>
        <h2 className='fabric-detail-headline'>{translate('fabric.fabricDetail.fabricContent')}</h2>
        <p className='fabric-detail-description'>{translate(`fabricShop.${selectedSubstrate}.fabricContent`)}</p>
      </section>
      <section className='detail-section'>
        <h2 className='fabric-detail-headline'>{translate('fabric.fabricDetail.fabricConstruction')}</h2>
        <p className='fabric-detail-description'>{translate(`fabricShop.${selectedSubstrate}.fabricConstruction`)}</p>
      </section>
      <button
        className='link-button btn-more-info x-non-full-width-mobile-button'
        type='button'
        onClick={handleMoreClick}
        aria-label={translate('fabric.fabricDetail.moreButtonTitle')}
        title={translate('fabric.fabricDetail.moreButtonTitle')}>
        <Icon iconName='arrow-down'/> {translate('fabricShop.collapsibleText')}
      </button>
      <button
        className='btn black-button big-button btn-shop-designs'
        type='button'
        onClick={navigateToShopPage}
        title={translate('checkout.confirmation.gotoShopPage')}>
        {translate('fabric.fabricDetail.shopDesigns')}
      </button>
      <button
        className='btn grey-button btn-select-different'
        type='button'
        onClick={navigateToFabricPage}>
        {translate('fabric.fabricDetail.selectDifferentFabric')}
      </button>
      {swatchButtonText &&
      <button
        type='button'
        className='link-button btn-swatch-price'
        onClick={handleSelectSwatch}
        title={translate('checkout.confirmation.gotoShopPage')}>
        {swatchButtonText}
      </button>}
    </article>
  );
};

export default ProductFormFabricDetails;
