import {FABRIC_DETAIL_IMAGE_URL_PREFIX} from '../constants/Assets';
import {hasThreeDetailImages, hasFiveDetailImages} from '../constants/Products';


export function numberOfDetailImages(fabric: string): number {
  if (hasThreeDetailImages.includes(fabric)) {
    return 3;
  } else if (hasFiveDetailImages.includes(fabric)) {
    return 5;
  } else {
    return 4;
  }
}

export const makeDetailsObject = (imagesHost: string, fabric: string, pixels: number): Record<string, string> => (
  [...Array(numberOfDetailImages(fabric))].reduce((details, _, index) => {
    const key = index + 1;

    details[key] = `${imagesHost}/${FABRIC_DETAIL_IMAGE_URL_PREFIX}/${fabric}_carousel_${key}_${pixels}.jpg`;

    return details;
  }, {})
);

export const makeCarouselImages = (imagesHost: string, fabric: string): Record<string, unknown> => (
  {
    ripple: {
      small: {
        [fabric]: `${imagesHost}/${FABRIC_DETAIL_IMAGE_URL_PREFIX}/${fabric}_ripple_square_700.jpg`
      },
      large: {
        [fabric]: `${imagesHost}/${FABRIC_DETAIL_IMAGE_URL_PREFIX}/${fabric}_ripple_square_1024.jpg`
      },
    },
    details: {
      small: makeDetailsObject(imagesHost, fabric, 700),
      large: makeDetailsObject(imagesHost, fabric, 1024),
    }
  }
);

export const handleImageTypes = (measure: string | number | undefined): number => (
  // if measure is a string, it was probably meant to be 'auto', it also can be undefined
  // for calculation purposes that doesn't work, so return 0
  typeof measure === 'number' ? measure : 0
);
