import React from 'react';
import './_select-fabric.scss';

import {translate} from '../../../../services';
import Icon from '../../../Reusable/Icon/Icon';


export interface SelectFabricProps {
  fabricList: string[];
  selectedSubstrate: string;
  openFabricPreferencesModal?: () => void;
  designNotPurchasable?: boolean;
  productAvailable?: boolean;
  outOfStock?: boolean;
}

const SelectFabric = ({fabricList, openFabricPreferencesModal, selectedSubstrate, outOfStock}: SelectFabricProps): JSX.Element => {
  const allowFabricEdit = fabricList.length > 1;

  return (
    <section className='b-select-fabric detail-section'>
      <h3 className='h2' itemProp='category'>{(allowFabricEdit ? translate('fabric.head.chooseFabric') : translate('fabric.head.title'))}</h3>
      {allowFabricEdit ?
        <React.Fragment>
          <button className={`substrate-link ${outOfStock ? 'out-of-stock-dropdown' : null}`} onClick={openFabricPreferencesModal} type='button'>
            <span className='substrate-name' itemProp='material'>{translate(`fabricShop.${selectedSubstrate}.name`)}</span>
            <Icon iconName='drawer-arrow-left' iconTitle={translate('fabricShop.changeSubstrate')}/>
          </button>
          {outOfStock && <p className='out-of-stock-message'>{translate('fabric.outOfStock.message')}</p>}
        </React.Fragment> :
        <React.Fragment>
          <h3 className='substrate-name' itemProp='material'>
            {translate(`fabricShop.${selectedSubstrate}.name`)}
          </h3>
          {outOfStock && <p className='out-of-stock-message'>{translate('fabric.outOfStock.messageShort')}</p>}
        </React.Fragment>
      }
    </section>
  );
};

export default SelectFabric;
