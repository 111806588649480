import {AxiosResponse} from 'axios';
import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {allProductsFail, allProductsFetching, allProductsReceived} from '../../../actions/allProducts';
import {serverError} from '../../../actions/server';
import renderNotFound from '../../../actions/shouldRenderErrorPage';
import request from '../../../application/api/request';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {DesignResponse} from '../../../shapes/design';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';

import {FetchAllProductsResponse} from './fetchAllProductsResponse';


export default function fetchAllProducts(): ThunkAction<Promise<DesignResponse | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const designId = parseInt(state.routingData?.routesParams?.designId, 10);

    dispatch(allProductsFetching());

    return request<AxiosResponse<FetchAllProductsResponse>>(state, {
      url: `${selectAlpenroseHost(state)}/products/${designId}`
    })
      .then(({data}) => {
        dispatch(allProductsReceived(data));
      })
      .catch((error: RequestError) => {
        dispatch(allProductsFail());
        if (process.env.REACT_APP_IS_SERVER) {
          dispatch((error.status === 404 || error.status === 401) ? renderNotFound() : serverError(error));
        } else {
          (error.status === 404 || error.status === 401) && dispatch(renderNotFound());
          // eslint-disable-next-line no-console
          console.log('Error caught in the `fetchAllProducts` function ', error);
        }
      });
  };
}
