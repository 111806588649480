import * as types from '../constants/ActionTypes';

export function allProductsReceived(payload) {
  return {
    type: types.ALL_PRODUCTS_GET + types.SUCCESS,
    payload
  };
}

export function allProductsFetching() {
  return {
    type: types.ALL_PRODUCTS_GET + types.FETCHING,
  };
}

export function allProductsFail() {
  return {
    type: types.ALL_PRODUCTS_GET + types.FAIL
  };
}

export function clearAllProducts() {
  return {
    type: types.ALL_PRODUCTS_GET + types.SUCCESS,
    payload: {
      isFetching: false
    }
  };
}
