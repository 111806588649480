import React from 'react';

import LazyImage from '../components/Reusable/LazyImage/LazyImage';
import {translate} from '../services';

import {defaultBasePrice} from './fabrics';


export const getAfterpayLogo = (imagesHost: string): JSX.Element => <LazyImage
  className='afterpay-messaging-logo'
  src={`${imagesHost}/afterpay_logo_small.png`}
  alt={translate('afterpay.logo')}
  width='70'
  height='13'/>;

export function afterpayInstallmentInfo(imagesHost: string, showInstallments: boolean, installment: number, currency: string, minValue: number, maxValue: number, isPayment: boolean): string {
  if (showInstallments) {
    const eligibleWithPriceText = isPayment ? 'afterpay.messaging.paymentEligibleWithPrice' : 'afterpay.messaging.eligibleWithPrice';

    return translate(eligibleWithPriceText, {
      amount: defaultBasePrice(installment, currency),
      afterpay: getAfterpayLogo(imagesHost)
    });
  } else {
    return translate('afterpay.messaging.eligibleNoThreshold', {
      minPrice: defaultBasePrice(minValue, currency),
      maxPrice: defaultBasePrice(maxValue, currency),
      afterpay: getAfterpayLogo(imagesHost)
    });
  }
}
