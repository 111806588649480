import {AnyAction} from 'redux';
import {ThunkAction} from 'redux-thunk';

import {crossSellFailed, crossSellFetching, crossSellReceived} from '../../../actions/crossSell';
import request from '../../../application/api/request';
import selectAlpenroseHost from '../../../entities/pageSetup/apiHosts/selectors/selectAlpenroseHost';
import {Design} from '../../../shapes/design';
import {State} from '../../../store/initialState';
import {RequestError} from '../../../utils/errors';


interface CrossSellDesignsData {
  data: Design[];
}

interface CrossSellDesignsParams {
  product_type: string;
}

export default function fetchCrossSellDesigns(): ThunkAction<Promise<CrossSellDesignsData | void>, State, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();

    const {routingData: {routesProps: {productType}, routesParams}} = state;

    dispatch(crossSellFetching());

    const params: CrossSellDesignsParams = {
      product_type: productType
    };

    const designId = parseInt(routesParams?.designId, 10);

    return request<CrossSellDesignsData>(state, {
      url: `${selectAlpenroseHost(state)}/design/${designId}/cross_sells`,
      params
    })
      .then(({data}) => {
        dispatch(crossSellReceived(data));
      })
      .catch((error: RequestError) => {
        dispatch(crossSellFailed());
        console.log('Error caught in the `fetchCrossSellDesigns` function', error); // eslint-disable-line
      });
  };
}
