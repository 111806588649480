import classNames from 'classnames';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/thumbs/thumbs.scss';
import 'swiper/components/lazy/lazy.scss';
import get from 'lodash/get';
import React, {useEffect, useState} from 'react';
import SwiperCore, {Navigation, Pagination, A11y, Thumbs, Lazy} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import {NavigationOptions} from 'swiper/types';
import {PaginationOptions} from 'swiper/types/components/pagination';
import SwiperClass from 'swiper/types/swiper-class';
import {SwiperOptions} from 'swiper/types/swiper-options';

import Icon from '../../components/Reusable/Icon/Icon';
import Loading from '../../components/Reusable/Loading/Loading';
import {CAROUSEL_THUMB_NAIL_SIZE} from '../../constants/Design';
import {isMobileViewport, VIEWPORT_DESKTOP} from '../../constants/Viewports';
import {translate} from '../../services';
import {ViewportType} from '../../shapes/viewport';
import {onClickPreventPropagation} from '../../utils/events';
import {isEmpty, isFunction, isNotUndefined} from '../../utils/validation';


// CSS for components needs to be added via Gruntfile.js in sass_globbing
SwiperCore.use([Navigation, Pagination, A11y, Thumbs, Lazy]);

interface SwiperWrapperContainerProps {
  viewport: ViewportType;
  children: JSX.Element[];
  parentComponent?: string;
  swiperParams?: Record<string, unknown>;
  navigationClasses?: string;
  handleSwiperSlideChange?: (index: number) => void;
  handleSize?: (height: string, width: string) => void;
  windowWidth?: number;
  productSize?: string;
  mountTimer?: boolean;
  hasSquareImages?: boolean;
  thumbnailImages?: JSX.Element[];
  onClickFunction?: () => void;
}

const SwiperWrapperContainer = ({
  children, swiperParams, navigationClasses, parentComponent, viewport, handleSwiperSlideChange,
  handleSize, mountTimer, windowWidth, productSize, hasSquareImages, thumbnailImages, onClickFunction
}: SwiperWrapperContainerProps): JSX.Element => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);
  const [mainSwiper, setMainSwiper] = useState<SwiperClass | null>(null);

  const isDesktop = viewport === VIEWPORT_DESKTOP;
  const isMobile = isMobileViewport(viewport);
  const isProductPage = parentComponent === 'productForm';
  const isQuickLook = parentComponent === 'quickLook';

  const prevButtonClassNames = classNames('button-prev', navigationClasses);
  const nextButtonClassNames = classNames('button-next', navigationClasses);
  const containerClass = classNames('b-swiper', 'swiper-container-horizontal', {
    'x-fabric-page-swiper': isProductPage && !hasSquareImages,
    'x-square-image-page-swiper': hasSquareImages,
    'x-shop-page-swiper': parentComponent === 'quickLook'
  });
  const thumbnailImagesClasses = classNames('b-thumbnails', 'swiper-container-vertical', 'swiper-container-css-mode', {
    'x-square-image-page': hasSquareImages,
    'x-spacing-wide': !hasSquareImages
  });

  // Swiper Options
  const navigationOptions: NavigationOptions = {
    nextEl: '.button-next',
    prevEl: '.button-prev'
  };
  const paginationOptions: PaginationOptions = {
    type: 'bullets',
    clickable: true,
    renderBullet: (index, className) => (
      `<span class="${className}" aria-label="${translate('designs.carouselGoTo', {
        number: index + 1
      })}" title="${translate('designs.carouselGoTo', {
        number: index + 1
      })}"></span>`
    )
  };
  const thumbsOptions: SwiperOptions = {
    direction: 'vertical',
    cssMode: true,
    spaceBetween: 10,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    height: CAROUSEL_THUMB_NAIL_SIZE
  };
  const standardOptions: SwiperOptions = {
    allowTouchMove: !isDesktop,
    spaceBetween: 0,
    roundLengths: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    observer: true,
    preloadImages: false,
    ...(!isMobile && {
      navigation: navigationOptions
    }),
    pagination: isProductPage && paginationOptions,
    thumbs: isProductPage ? {
      swiper: thumbsSwiper
    } : undefined
  };

  useEffect(() => {
    if (mainSwiper !== null && isNotUndefined(handleSwiperSlideChange)) {
      // update comments under User Photos
      mainSwiper.on('slideChange', () => {
        if (mainSwiper.previousIndex < mainSwiper.activeIndex) {
          // update to photo on the right
          handleSwiperSlideChange(1);
        } else {
          // update to photo on the left
          handleSwiperSlideChange(0);
        }
      });
    }
  }, [handleSwiperSlideChange, mainSwiper]);

  useEffect(() => {
    if (mainSwiper !== null) {
      mainSwiper.slideToLoop(0, 100);
    }
  }, [productSize]);

  useEffect(() => {
    const updateCondition = (isProductPage || isQuickLook) && !hasSquareImages;

    mainSwiper !== null && updateSwiperDimensions(updateCondition);
  }, [mainSwiper, windowWidth, productSize]);

  useEffect(() => {
    mainSwiper !== null && updateSwiperDimensions(!!hasSquareImages);
  }, [windowWidth, mainSwiper]);

  useEffect(() => {
    if (mainSwiper !== null) {
      if (isMobileViewport(viewport)) {
        // make sure current slide is loaded when switching from zoom to no-zoom with lazy loading
        mainSwiper.lazy.load();
      }

      mainSwiper.update();
    }
  }, [viewport]);

  useEffect(() => {
    // User submitted photos
    let setTimeoutForSwiper: ReturnType<typeof setTimeout>;

    if (mainSwiper !== null && mountTimer) {
      setTimeoutForSwiper = setTimeout(() => mainSwiper.update(), 300);
    }

    return () => {
      if (isFunction(setTimeoutForSwiper) && mountTimer) {
        clearTimeout(setTimeoutForSwiper);
      }
    };
  }, [mainSwiper, mountTimer]);

  const updateSwiperDimensions = (condition: boolean) => {
    if (isNotUndefined(handleSize) && condition) {
      const height = get(mainSwiper?.slides[0], 'firstElementChild.offsetWidth', 'auto');
      const width = get(mainSwiper?.slides[0], 'firstElementChild.offsetWidth', 'auto');

      handleSize(height, width);
    }
    mainSwiper !== null && mainSwiper.update();
  };

  if (isEmpty(children)) {
    return <Loading message={translate('common.loading')} />;
  }

  return (
    <React.Fragment>
      {(isProductPage && !isEmpty(thumbnailImages)) &&
      <Swiper
        onSwiper={setThumbsSwiper}
        className={thumbnailImagesClasses}
        {...thumbsOptions}>
        {thumbnailImages && thumbnailImages.map((thumb, index) => (
          <SwiperSlide key={index}>{thumb}</SwiperSlide>
        ))}
      </Swiper>}
      <Swiper
        onSwiper={setMainSwiper}
        className={containerClass}
        {...Object.assign({}, standardOptions, swiperParams)}>
        {children.map((child, index) => (
          <SwiperSlide
            key={index}
            {...(isNotUndefined(onClickFunction) ? {
              onClick: () => onClickFunction(),
              tabIndex: 0,
              role: 'button',
            } : {})}>
            {child}
          </SwiperSlide>
        ))}
        {!isMobile &&
        <>
          <button
            onClick={onClickPreventPropagation}
            type='button'
            className={prevButtonClassNames}
            aria-label={translate('designs.carouselPrevious')}
            title={translate('designs.carouselPrevious')}>
            <Icon iconName='chevron-left' iconTitle={translate('designs.carouselPrevious')}/>
          </button>
          <button
            onClick={onClickPreventPropagation}
            type='button'
            className={nextButtonClassNames}
            aria-label={translate('designs.carouselNext')}
            title={translate('designs.carouselNext')}>
            <Icon iconName='chevron-right' iconTitle={translate('designs.carouselNext')}/>
          </button>
        </>}
      </Swiper>
    </React.Fragment>
  );
};

export default SwiperWrapperContainer;
