import React from 'react';

import './_spoonflower-info-box.scss';
import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


export interface SpoonflowerInfoBoxProps {
  infoText: JSX.Element;
  isDesktop: boolean;
}

const SpoonflowerInfoBox = ({infoText, isDesktop}: SpoonflowerInfoBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    <InfoBox
      isDesktop={isDesktop}
      boxClass='b-spoonflower-info-box'
      heading={translate('navigation.about.text')}
      infoText={infoText}
      imageUrl={`${imagesHost}/logo-flower.svg`}
      imageAlt='spoonflower-logo'
      moreInfo={<LinkWrapper
        className='learn-more-link'
        hrefValue={translate('navigation.about.href')}
        title={translate('navigation.about.text')}
        contents={translate('fabric.aboutSpoonflower.learnMore')}
      />}
    />
  );
};

export default SpoonflowerInfoBox;
