import {useEffect, useRef} from 'react';


const usePreviousValue = <TPrevious extends unknown>(value: TPrevious): TPrevious | undefined => {
  const ref = useRef<TPrevious>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePreviousValue;
