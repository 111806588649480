export interface EmptyCrossSell {
  isFetching: boolean;
  designs: never[];
}

export interface CrossSellLoaded {
  isFetching: boolean;
  fetchingIsReady: boolean;
  hasFailed?: boolean;
  designs: CrossSellDesign[] | SolidCrossSellItem[];
}

export interface SolidCrossSellItem {
  code: string;
  color: string;
  color_code: string;
  fabric: string;
  fabric_code: string;
  id: number;
  priority: number;
  thumbnail_path: string;
}

export interface CrossSellCollection {
  collection_slug: string;
  collection_name: string;
  thumbnail_url: string;
  search_params?: string;
}

export interface CrossSellDesign {
  all_locale_slugs: Record<string, unknown>;
  collection_slug: string;
  collection_name?: string;
  design_thumbnail: string;
  designer: {
    designer_image: {
      mugshot: string;
      id: number;
      filename: string;
    };
    id: number;
    public_designs: number;
    screen_name: string;
  };
  favorite_count: number;
  filename: string;
  for_sale: boolean;
  for_sale_as_fabric: boolean;
  for_sale_as_wallpaper: boolean;
  for_sale_as_home_good: boolean;
  for_sale_as_tea_towel: boolean;
  id: number;
  name: string;
  slug: string;
  search_params?: string;
  thumbnail_url: string;
  user_favorite: boolean;
}

export type CrossSell = EmptyCrossSell | CrossSellLoaded

export const crossSellExists = (input: CrossSell): input is CrossSellLoaded => (input as CrossSellLoaded).designs.length > 0;
export const isCrossSellSolid = (input: CrossSellDesign[] | CrossSellCollection[] | SolidCrossSellItem[]): input is SolidCrossSellItem[] => (input as SolidCrossSellItem[])[0].code !== undefined;
export const isCrossSellDesign = (input: CrossSellDesign[] | SolidCrossSellItem[] | never[]): input is CrossSellDesign[] =>
  (input as CrossSellDesign[]).length > 0 && (input as CrossSellDesign[])[0].name !== undefined;
