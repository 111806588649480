import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import './_select-wallpaper.scss';
import {Field} from 'redux-form';

import {translate} from '../../../../services';
import {renderRadioButtons} from '../../../../utils/form';


export interface SelectWallpaperProps {
  allowWallpaperEdit?: boolean;
  optionArray: string[];
  selectedSubstrate: string;
  openFabricPreferencesModal?: () => void;
  designNotPurchasable?: boolean;
  productAvailable?: boolean;
  outOfStock?: boolean;
  setWallpaper: (newWallpaper: string) => void;
}

interface CreateOptionButtonsParams {
  optionArray: string[];
  currentSelectedSubstrate: string;
  handleSelectionOnChange: (option: string) => void;
}

const SelectWallpaper = ({
  optionArray,
  openFabricPreferencesModal,
  selectedSubstrate,
  outOfStock,
  setWallpaper,
}: SelectWallpaperProps): JSX.Element => {
  const allowWallpaperEdit = optionArray.length > 1;
  const [currentSelectedSubstrate, setCurrentSelectedSubstrate] = useState(selectedSubstrate);

  const createOptionButtons = ({optionArray, currentSelectedSubstrate, handleSelectionOnChange}: CreateOptionButtonsParams) => {
    const orderMap = {
      'WALLPAPER_PEEL_AND_STICK': 1,
      'WALLPAPER_SMOOTH': 2,
      'WALLPAPER_ISOBAR': 3,
      'WALLPAPER_GRASSCLOTH': 4,
      'WALLPAPER_SILVER_METALLIC': 5,
      'WALLPAPER_GOLD_METALLIC': 6,
    };
    const orderedOptionArray = optionArray.sort((a, b) => {
      const keyA = a as keyof typeof orderMap;
      const keyB = b as keyof typeof orderMap;

      return orderMap[keyA] - orderMap[keyB];
    });

    return (
      <>
        <fieldset>
          {orderedOptionArray.map((option) => {
            const optionButtonClasses = classNames('btn option-btn x-non-full-width-mobile-button', {
              'option-btn-active': option === currentSelectedSubstrate,
            });

            return (
              <Field
                key={option}
                value={option}
                label={translate(`fabricShop.${option}.buttonName`)}
                onClickAction={() => handleSelectionOnChange(option)}
                name='substrate'
                component={renderRadioButtons}
                formPrefix='wallpaper'
                type='radio'
                ariaRequired='true'
                highlightClass={optionButtonClasses}
              />
            );
          })}
        </fieldset>
      </>
    );
  };

  useEffect(() => {
    setCurrentSelectedSubstrate(selectedSubstrate);
  }, [selectedSubstrate]);

  const handleSelectionOnChange = (value: string) => {
    setWallpaper(value);
  };

  return (
    <section className='b-select-wallpaper detail-section'>
      <h3 className='h2' itemProp='category'>
        {allowWallpaperEdit ? translate('wallpaper.head.chooseWallpaper') : translate('wallpaper.head.title')}
        <span className='label-subtitle' itemProp='material'>
          {translate(`fabricShop.${selectedSubstrate}.subtitle`)}
        </span>
      </h3>
      {createOptionButtons({
        optionArray, currentSelectedSubstrate, handleSelectionOnChange,
      })}
      {allowWallpaperEdit ? (
        <React.Fragment>
          <button
            className={`preference-modal-link ${outOfStock ? 'out-of-stock-dropdown' : ''}`}
            onClick={openFabricPreferencesModal}
            type='button'
          >
            <span className='button-text'>{translate('wallpaper.head.buttonText')}</span>
            <span>{translate('wallpaper.head.buttonTextAfter')}</span>
          </button>
          {outOfStock && <p className='out-of-stock-message'>{translate('wallpaper.outOfStock.message')}</p>}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h3 className='substrate-name' itemProp='material'>
            {translate(`fabricShop.${selectedSubstrate}.name`)}
          </h3>
          {outOfStock && <p className='out-of-stock-message'>{translate('wallpaper.outOfStock.messageShort')}</p>}
        </React.Fragment>
      )}
    </section>
  );
};

export default SelectWallpaper;
