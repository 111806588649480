import React from 'react';

import './_select-color.scss';
import {translate} from '../../../../services';
import Icon from '../../../Reusable/Icon/Icon';


export interface SelectColorProps {
  solidHexCode: string;
  solidColorCode: string;
  openSelectColorModal?: (event: {preventDefault: () => void}) => void;
}

const SelectColor = ({solidHexCode, solidColorCode, openSelectColorModal}: SelectColorProps): JSX.Element => (
  <section className='b-select-color detail-section'>
    <h3 className='h2' itemProp='category'>{translate('solidColors.headlines.color')}</h3>
    <button className='dropdown-button' type='button' onClick={openSelectColorModal}>
      <span className='color-indicator' title={`#${solidHexCode}`} style={{
        background: `#${solidHexCode}`
      }}>{solidHexCode}</span>
      <span className='dropdown-name'>{translate(`solidColors.${solidColorCode}.name`)}</span>
      <Icon iconName='chevron-down' iconTitle={translate('solidColors.changeColor')}/>
    </button>
  </section>
);

export default SelectColor;
