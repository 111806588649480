import {isUndefined, isEmpty} from './validation';

const buildSelector = (element, attributes) => (
  attributes.reduce((selector, attribute) => {
    const attributeValue = element[attribute];

    if (isUndefined(attributeValue) || isEmpty(attributeValue)) {
      return selector;
    }

    return `${selector}[${attribute}="${attributeValue}"]`;
  }, '')
);

// Helmet prevents duplicate elements in <head>, but only for its own generated elements. Those created without Helmet, such as
// Rails-generated headers must be removed manually.
export const removeDuplicateNonHelmetElements = (newState, addedTags) => {
  if (isUndefined(addedTags.linkTags)) {
    return;
  }
  addedTags.linkTags.forEach((linkTag) => {
    const duplicates = window.document.querySelectorAll(`link${buildSelector(linkTag, ['rel', 'hreflang'])}:not([data-react-helmet="true"])`);

    // Avoid calling .forEach directly on the results of querySelectorAll, which is a NodeList. This is for IE-compatibility.
    Array.prototype.forEach.call(duplicates, (duplicate) => {
      // Avoid calling .remove() directly on a DOM node, because of IE.
      duplicate.parentNode.removeChild(duplicate);
    });
  });
};
