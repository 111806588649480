import {MeasurementSystem} from '../constants/Measurements';
import {USD, AUD, EUR, GBP, CAD} from '../constants/Payment';


export type Currency =
  | typeof USD
  | typeof AUD
  | typeof EUR
  | typeof GBP
  | typeof CAD;

export interface AddToCartPricing {
  [productSize: string]: {
    price?: number;
    price_undiscounted?: number;
    discounts_applied?: boolean;
    size?: string;
    style?: string;
    home_goods_additions?: Record<string, unknown>;
  };
}

export type SimplePricing = {
  [productSize: string]: {
    discounts_applied: boolean;
    price: number;
    price_undiscounted: number;
  };
}

export type HomeGoodPricing = {
  [productSize: string]: {
    discounts_applied: boolean;
    home_goods_additions?: SimplePricing;
    price: number;
    price_undiscounted: number;
    size: string;
    style: string;
  };
}

export type OrderItemBasicPricing = {
  currency: Currency;
  discounts_applied: boolean;
  raw_price: number;
  raw_price_undiscounted: number;
  raw_price_undiscounted_usd: number;
  raw_price_usd: number;
  tax_applicable: boolean;
  vat_exempt: boolean;
}

export interface OrderItemPricePerUnit extends OrderItemBasicPricing {
  price_per: {
    raw_unit: number;
    raw_unit_leader: number;
    raw_unit_leader_undiscounted: number;
    raw_unit_undiscounted: number;
    unit_type: MeasurementSystem;
  };
}

export interface OrderItemPricePerFoot extends OrderItemBasicPricing { // Wallpaper Pricing Structure
  price_per: {
    raw_foot: number;
    raw_foot_undiscounted: number;
    raw_foot_usd: number;
    raw_foot_usd_undiscounted: number;
  };
}

export interface OrderItemPricePerYard extends OrderItemBasicPricing {
  price_per: {
    raw_yard: number;
    raw_yard_undiscounted: number;
    raw_yard_usd: number;
    raw_yard_usd_undiscounted: number;
  };
}

export interface AfterPay {
  available: boolean;
  config: {
    min_value: number;
    max_value: number;
    currency: string;
  };
  currency_valid: boolean;
  eligible: boolean;
  show_afterpay: boolean;
  installments: {
    [key: string]: {
      summary: number;
      show_installments: boolean;
    };
  };
}

export type ProductPricingMaps = SimplePricing | HomeGoodPricing;

export const isProductWithAdditions = (input: ProductPricingMaps, productSize: string): input is HomeGoodPricing => (input as HomeGoodPricing)[productSize].home_goods_additions !== undefined;
export const isHomeGoodPricing = (input: ProductPricingMaps, productSize: string): input is HomeGoodPricing => (input as HomeGoodPricing)[productSize].size !== undefined;

