import classNames from 'classnames';
import React from 'react';
import ReactImageMagnify from 'react-image-magnify';
import './_design-detail-image.scss';

import {breakMobileMax, breakTabletMax, breakDesktopMin, breakTabletMin} from '../../../../constants/Viewports';
import {translate} from '../../../../services';
import {handleImageTypes} from '../../../../utils/carouselHelpers';
import {onClickPreventDefault} from '../../../../utils/events';
import {isEmpty} from '../../../../utils/validation';
import Loading from '../../Loading/Loading';


interface DesignDetailImageProps {
  imgSrcLarge?: string;
  imgSrc: string;
  imageAlternateText: string;
  mobileImage?: string;
  tabletImage?: string;
  extensionClasses?: string;
  imageHeight?: string | number;
  imageWidth?: string | number;
  parentComponent?: string;
}

const DesignDetailImage = ({
  imgSrcLarge, imgSrc, imageAlternateText, tabletImage, mobileImage, extensionClasses, imageHeight,
  imageWidth, parentComponent
}: DesignDetailImageProps): JSX.Element => {
  const isProductPage = parentComponent === 'productForm';
  const imageDetailClasses = classNames('b-design-detail-image', extensionClasses);

  return (
    <div className={imageDetailClasses}>
      {imgSrc ?
        <div className='wrapper-positioning'>
          <picture className='image' onContextMenu={onClickPreventDefault}>
            {mobileImage && <source media={`(max-width: ${breakMobileMax}px)`} srcSet={mobileImage}/>}
            {(tabletImage && !mobileImage) && <source media={`(max-width: ${breakTabletMax}px)`} srcSet={tabletImage}/>}
            {(mobileImage && tabletImage) && <source media={`(min-width:${breakTabletMin}px) and (max-width: ${breakTabletMax}px)`} srcSet={tabletImage}/>}
            <source media={`(min-width: ${breakDesktopMin}px)`} srcSet={imgSrc}/>
            {isProductPage && !isEmpty(imgSrcLarge) ?
              <ReactImageMagnify {...{
                enlargedImagePosition: 'over',
                className: 'fallback-image-wrapper',
                imageClassName: 'fallback-image small-carousel-image',
                enlargedImageClassName: 'large-carousel-image',
                hoverDelayInMs: 250,
                isEnlargedImagePortalEnabledForTouch: true,
                smallImage: {
                  alt: imageAlternateText,
                  src: imgSrc,
                  width: handleImageTypes(imageWidth),
                  height: handleImageTypes(imageHeight),
                  isFluidWidth: false,
                },
                largeImage: {
                  alt: imageAlternateText,
                  src: imgSrcLarge ? imgSrcLarge : '',
                  width: handleImageTypes(imageWidth) * 2,
                  height: handleImageTypes(imageHeight) * 2
                }
              }}/> :
              <img
                alt={imageAlternateText}
                className='fallback-image'
                src={imgSrc}
                width={imageWidth}
                height={imageHeight} />}
          </picture>
        </div> : <Loading message={translate('common.loading')}/>}
    </div>
  );
};

export default DesignDetailImage;
