import Translate from 'i18n-react';
import React from 'react';

import './_design-heading.scss';
import {IS_SOLID} from '../../../../constants/Products';
import {FIRST} from '../../../../constants/Reviews';
import {EN} from '../../../../constants/SupportedLanguages';
import {registeredUser} from '../../../../constants/userTypes';
import FavoriteDesignContainer from '../../../../containers/Design/FavoriteDesignContainer';
import UserCollectionsContainer from '../../../../containers/Design/UserCollectionsContainer';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {ProductTypeAbbreviated} from '../../../../shapes/products';
import {InternationalTag} from '../../../../shapes/tags';
import {UserType} from '../../../../shapes/user';
import {getStarListItems} from '../../../../utils/reviews';
import {isNotUndefined} from '../../../../utils/validation';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


interface DesignHeadingProps {
  locale: Locale;
  designId?: number;
  designTitle?: string;
  designerUserName?: string;
  designThumbnail?: string;
  userId?: number;
  userType?: UserType;
  userIsDesigner?: boolean;
  reviewSummary?: Record<string, number>[];
  tags?: InternationalTag[];
  productFormTitle?: string;
  productTypeAbbreviated?: ProductTypeAbbreviated;
}

const DesignHeading = (
  {designId, designTitle, designerUserName, userId, userType, userIsDesigner, reviewSummary, tags, locale, productFormTitle, designThumbnail, productTypeAbbreviated}: DesignHeadingProps):
  JSX.Element => {
  const renderEditDesignLink = () => {
    if (userIsDesigner) {
      return <LinkWrapper
        className='edit-design-link'
        hrefValue={Translate.translate('xurls.design.edit', { // TODO: SP-8362 Refactor or extend translate utility.
          id: designId
        })?.toString() || ''}
        contents={translate('designs.edit')}/>;
    }
  };

  const renderTags = () => {
    if (tags) {
      // These tags are rendered as a single, comma-separated string in a single HTML element to accommodate Schema.org
      // microformatting. By representing them as multiple spans, they are seen by Google as one continuous word. See
      // SP-1864.
      return (
        <span className='design-tags'>{tags.map((tag) => tag.name).join(', ')}</span>
      );
    }
  };

  const ratingAmount: number = (Array.isArray(reviewSummary) && reviewSummary.length > 0 && reviewSummary[FIRST]) ? reviewSummary[FIRST].ratings : 0;
  const averageRating = (Array.isArray(reviewSummary) && reviewSummary.length > 0) && (reviewSummary[FIRST] && reviewSummary[FIRST].averageRating);
  const ratingsAvailable = ratingAmount > 0;
  const averageRatingStarList = getStarListItems(averageRating);
  const designerNameWithSchemaMarkup = <span itemProp='brand'>{designerUserName}</span>;
  const isRegisteredUser = userType && registeredUser(userType);
  const showUserIcons = (isNotUndefined(userId) && isRegisteredUser);
  const isSolid = IS_SOLID(productTypeAbbreviated);

  return (
    <section className='b-design-heading detail-section'>
      {(designerUserName && designTitle && designId && designThumbnail && Array.isArray(tags)) &&
        <div className='heading-user'>
          <FavoriteDesignContainer
            designId={designId}
            parentName='productForm'
            redirectToLogin={!showUserIcons}
          />
          <UserCollectionsContainer
            designerUserName={designerUserName}
            designTitle={designTitle}
            designId={designId}
            imageUrl={designThumbnail}
            tags={tags}
            redirectToLogin={!showUserIcons}
          />
        </div>}
      <div className='heading-container'>
        <div className='heading-title'>
          {/*
          The spaces below are significant to the schema.org microdata. There needs to be a space between the title
          and the tags, or else the microdata will be malformed. See SP-1864.
          */}
          <h1 className='h3 change-design' itemProp='name'>
            {(locale === EN && designTitle) ?
              <span className='design-title'>{designTitle}</span> :
              renderTags()} {productFormTitle} <span className='designer'>
              <span className='by'>{translate('fabric.content.by')}</span>
              {isSolid ? designerNameWithSchemaMarkup : <LinkWrapper
                hrefValue={Translate.translate('xurls.userProfile', { // TODO: SP-8362 Refactor or extend translate utility.
                  userName: designerUserName
                })?.toString() || ''}
                contents={designerNameWithSchemaMarkup} className='designer-link'/>}</span>
          </h1>
        </div>
      </div>
      {renderEditDesignLink()}
      <div className='sub-heading-container'>
        {ratingsAvailable &&
          <LinkWrapper
            className='rating-star-list flex-fixed'
            hrefValue='#reviews-section'
            contents={
              <React.Fragment>
                <span className='rating-amount'>{ratingAmount}</span>
                <ul className='star-list'>{averageRatingStarList}</ul>
              </React.Fragment>
            }/>}
      </div>
    </section>
  );
};

export default DesignHeading;
