import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';

import FeaturedInCollectionContainer from '../../../containers/Design/FeaturedInCollectionContainer';
import {Locale} from '../../../shapes/locale';
import {ProductTypeAbbreviated} from '../../../shapes/products';
import {isNotUndefined} from '../../../utils/validation';
import {ErrorBoundary} from '../../Reusable/ErrorBoundary/ErrorBoundary';

import AvailableOnProducts from './AvailableOnProducts/AvailableOnProducts';
import AvailableSubstrates from './AvailableSubstrates/AvailableSubstrates';
import DesignerDetails from './DesignerDetails/DesignerDetails';
import './_designer-section.scss';


export interface DesignerSectionProps {
  productTypeAbbreviated: ProductTypeAbbreviated;
  productType: string;
  locale: Locale;
  designerUserName?: string;
  designerPublicDesigns?: number;
  isMugshot?: boolean;
  designerInfoImageId?: number;
  designerInfoImageFile?: string;
  isAvailableOnWallpaper?: boolean;
  isAvailableOnHomeGood?: boolean;
  isAvailableOnFabric?: boolean;
  isRotatedFatQuarter?: boolean;
  homeGoodType?: string;
  designId?: number;
  designerId?: number;
  designFileName?: string;
  wallpaperAvailableSubstrateThumbnail?: string;
  crossSellBarItems?: {[key: string]: {imageSrc: string; name: string; link: string}[]};
  selectedSubstrate: string;
}

const DesignerSection = ({
  designerUserName, designerPublicDesigns, isMugshot, designerInfoImageId, designerInfoImageFile,
  isAvailableOnWallpaper, isAvailableOnHomeGood, designId, designerId, designFileName,
  isAvailableOnFabric, wallpaperAvailableSubstrateThumbnail, productTypeAbbreviated, productType, locale,
  crossSellBarItems, selectedSubstrate, isRotatedFatQuarter, homeGoodType
}: DesignerSectionProps): JSX.Element => {
  const navbarClasses = classNames('b-designer-navbar', {
    'x-fabric-available': isNotUndefined(crossSellBarItems)
  });

  return (
    <article className={navbarClasses}>
      {isNotUndefined(get(crossSellBarItems, `${selectedSubstrate}`)) &&
        <ErrorBoundary>
          <AvailableOnProducts
            crossSellBarItems={crossSellBarItems}
            selectedSubstrate={selectedSubstrate}
          />
        </ErrorBoundary>}
      {designerId &&
        <ErrorBoundary>
          <DesignerDetails
            designerUserName={designerUserName}
            designerPublicDesigns={designerPublicDesigns}
            isMugshot={isMugshot}
            designerInfoImageId={designerInfoImageId}
            designerInfoImageFile={designerInfoImageFile}
          />
        </ErrorBoundary>}
      {designId &&
      <ErrorBoundary>
        <FeaturedInCollectionContainer
          locale={locale}
          designId={designId}
          designFilename={designFileName}
          productType={productType}
          productTypeAbbreviated={productTypeAbbreviated}
        />
      </ErrorBoundary>}
      {(isAvailableOnWallpaper || isAvailableOnHomeGood || isAvailableOnFabric) &&
        <ErrorBoundary>
          <AvailableSubstrates
            isAvailableOnWallpaper={isAvailableOnWallpaper}
            isAvailableOnHomeGood={isAvailableOnHomeGood}
            isAvailableOnFabric={isAvailableOnFabric}
            isRotatedFatQuarter={isRotatedFatQuarter}
            homeGoodType={homeGoodType}
            designId={designId}
            designFileName={designFileName}
            wallpaperAvailableSubstrateThumbnail={wallpaperAvailableSubstrateThumbnail}
            productTypeAbbreviated={productTypeAbbreviated}
          />
        </ErrorBoundary>}
    </article>
  );
};

export default DesignerSection;
