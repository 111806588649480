import React, {MouseEvent} from 'react';

import './_user-submitted-photos.scss';
import {isTabletDown} from '../../../constants/Viewports';
import SwiperWrapperContainer from '../../../containers/Design/SwiperWrapperContainer';
import {translate} from '../../../services';
import {CommentPhotoData, PhotoData, ReviewPhotoData} from '../../../shapes/uploadedPhotos';
import {ViewportType} from '../../../shapes/viewport';
import Collapsible from '../../Reusable/Content/Collapsible/Collapsible';


export interface UserSubmittedPhotosProps {
  userSubmittedPhotoData: PhotoData[];
  isDesktop?: boolean;
  viewport: ViewportType;
  openUserUploadedImagesModal: (event: MouseEvent<HTMLButtonElement>, photoDataIndex: number, photoDataList: (CommentPhotoData | ReviewPhotoData)[]) => void;
}

const UserSubmittedPhotos = ({userSubmittedPhotoData, isDesktop, viewport, openUserUploadedImagesModal}: UserSubmittedPhotosProps): JSX.Element => {
  const displayPhotos = (photoDataIndex: number) => {
    const photoDataList = [...userSubmittedPhotoData] as (CommentPhotoData | ReviewPhotoData)[];
    const photoData = photoDataList[photoDataIndex];

    const handleOpenPhotoEvent = (event: MouseEvent<HTMLButtonElement>) => {
      openUserUploadedImagesModal(event, photoDataIndex, photoDataList);
    };

    return (
      <button
        key={photoDataIndex}
        onClick={handleOpenPhotoEvent}
        type='button'
        aria-label={photoData.caption}
        title={photoData.caption}
        className='btn user-submitted-photo-btn'>
        <img
          className='user-submitted-photo'
          src={photoData.thumbnailPhoto}
          alt={photoData.caption}
          width='100'
          height='100' />
      </button>
    );
  };

  const heading = translate('fabric.userSubmittedPhotos.sectionHeading');
  const mergedPhotoDataList = [...userSubmittedPhotoData];
  const swiperParams = {
    slidesPerView: 'auto',
    cssMode: true
  };
  const isMobile = isTabletDown(viewport);

  // dummy is a throwaway variable
  const userPhotos = (
    <article className='b-info-box b-user-submitted-photos'>
      {!isMobile && <h3 className='h3'>{heading}</h3>}
      <SwiperWrapperContainer
        viewport={viewport}
        swiperParams={swiperParams}
        navigationClasses='x-on-top-of-slides'>
        {mergedPhotoDataList.map((dummy, index) => (
          displayPhotos(index)
        ))}
      </SwiperWrapperContainer>
    </article>
  );
  // collapsible needs to be open to initialize swiper
  const userPhotosCollapsible = (
    <Collapsible
      title={heading}
      classname='x-bottom-border x-user-photos'
      sizeClass='x-trigger-large'
      subtitleTextClass='h3 x-flush-left'
      collapsibleOpen={mergedPhotoDataList.length > 3}
      triggerType='chevron'
      content={userPhotos} />
  );

  return isDesktop ? userPhotos : userPhotosCollapsible;
};

export default UserSubmittedPhotos;
