import classNames from 'classnames';
import React from 'react';

import './_design-info-box.scss';
import {SHORT_DESCRIPTION, LONG_DESCRIPTION, MAX_LENGTH_DESIGNER_DESCRIPTION} from '../../../../constants/Design';
import {EN} from '../../../../constants/SupportedLanguages';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import {TagsProps, isTagObjectArray} from '../../../../shapes/tags';
import {isEmpty} from '../../../../utils/validation';
import Collapsible from '../../../Reusable/Content/Collapsible/Collapsible';
import TagsList from '../../../Reusable/Content/TagsList/TagsList';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';


export interface DesignInfoBoxProps {
  shortDescription?: string;
  longDescription?: string;
  collapsibleOpen: boolean;
  designName?: string;
  tags?: TagsProps;
  orderItemId?: number;
  isDesktop: boolean;
  locale: Locale;
  boxClassExtension?: string;
  designThumbnailURL: string;
  collapsibleShowTrigger?: boolean;
}

const DesignInfoBox = ({
  shortDescription, longDescription, designName, isDesktop, locale, collapsibleOpen, boxClassExtension,
  collapsibleShowTrigger, designThumbnailURL, tags, orderItemId
}: DesignInfoBoxProps): JSX.Element | null => {
  const evaluateShortenedDescription = (description: string | undefined, maxLength: number) => (
    description ? description.length > maxLength : false
  );
  const getContinuationIndicator = (shortDescriptionIsShortened: boolean, longDescriptionIsShortened: boolean) => (
    !collapsibleOpen && (shortDescriptionIsShortened || longDescriptionIsShortened)
  );
  const createDesignDescription = (description: string, maxLengthDescription: number, shortDescriptionIsShortened: boolean, longDescriptionIsShortened: boolean, descriptionType: string) => {
    const shortDescriptionAvailable = shortDescription;
    const isLongDescription = descriptionType === LONG_DESCRIPTION;
    const longDescriptionIsHidden = isLongDescription && shortDescriptionIsShortened;
    const longDescriptionFollowsShortDescription = isLongDescription && shortDescriptionAvailable;
    const longDescriptionExceedsLimit = !shortDescriptionIsShortened && description.length > maxLengthDescription;
    const displayedDescriptionTextFragment = description.substring(0, description.lastIndexOf(' ', maxLengthDescription));
    const hiddenDescriptionTextFragment = description.substring(description.lastIndexOf(' ', maxLengthDescription), description.length);
    let displayDescriptionText: string | JSX.Element = description;
    let hiddenDescriptionText = '';
    const displayContinuationIndicator = getContinuationIndicator(shortDescriptionIsShortened, longDescriptionIsShortened);
    const continuationIndicator = '…';

    const descriptionText = description;

    if (longDescriptionExceedsLimit || longDescriptionFollowsShortDescription) {
      displayDescriptionText = !isEmpty(displayedDescriptionTextFragment) ?
        <React.Fragment>
          {displayedDescriptionTextFragment}{displayContinuationIndicator &&
          <span className='continuation-indicator'>{continuationIndicator}</span>}
        </React.Fragment> : '';
      hiddenDescriptionText = hiddenDescriptionTextFragment;
    } else if (description && longDescriptionIsHidden) {
      displayDescriptionText = '';
      hiddenDescriptionText = descriptionText;
    } else {
      displayDescriptionText = descriptionText;
    }

    return {
      displayDescription: displayDescriptionText,
      hiddenDescription: hiddenDescriptionText
    };
  };

  const createInfoText = (shortDescription: string | JSX.Element | undefined, longDescription: string | JSX.Element | undefined, isHiddenDescription: boolean) => {
    const localeIsEnglish = locale === EN;
    const tagNames = tags && isTagObjectArray(tags) ? tags.map((tag) => tag.name.toLowerCase()) : [];

    return <>
      {!isHiddenDescription && tags && isTagObjectArray(tags) && !isEmpty(tags) &&
          <TagsList
            tagNames={tagNames}
            tags={tags}
            orderItemId={orderItemId}
            isBlocked={true}
            locale={locale}
          />
      }
      {(shortDescription && localeIsEnglish) && <p className='design-info-text' data-testid='short-paragraph'>{shortDescription}</p>}
      {(longDescription && localeIsEnglish) && <p className='design-info-text' data-testid='long-paragraph'>{longDescription}</p>}
    </>;
  };

  const localeIsEnglish = locale === EN;
  const maxLengthShortDescription = MAX_LENGTH_DESIGNER_DESCRIPTION;
  const maxLengthLongDescription = 0;
  const shortDescriptionIsShortened = evaluateShortenedDescription(shortDescription, maxLengthShortDescription);
  const longDescriptionIsShortened = evaluateShortenedDescription(longDescription, maxLengthLongDescription);

  const displayShortDescription = shortDescription &&
    createDesignDescription(shortDescription, maxLengthShortDescription, false, longDescriptionIsShortened, SHORT_DESCRIPTION)
      .displayDescription;
  const hiddenShortDescription = shortDescription &&
    createDesignDescription(shortDescription, maxLengthShortDescription, false,longDescriptionIsShortened, SHORT_DESCRIPTION)
      .hiddenDescription;
  const displayLongDescription = longDescription &&
    createDesignDescription(longDescription, maxLengthLongDescription, shortDescriptionIsShortened, false, LONG_DESCRIPTION)
      .displayDescription;
  const hiddenLongDescription = longDescription &&
    createDesignDescription(longDescription, maxLengthLongDescription, shortDescriptionIsShortened, false, LONG_DESCRIPTION)
      .hiddenDescription;

  const checkTypeOfdisplayShortDescription = (typeof displayShortDescription === 'string' || typeof displayShortDescription === 'object' || typeof displayShortDescription === 'undefined');
  const checkTypeOfdisplayLongDescription = (typeof displayLongDescription === 'string' || typeof displayLongDescription === 'object' || typeof displayLongDescription === 'undefined');

  const infoText =
    isDesktop &&
    checkTypeOfdisplayShortDescription &&
    checkTypeOfdisplayLongDescription ?
      createInfoText(displayShortDescription, displayLongDescription, false) :
      createInfoText(shortDescription, longDescription, false);
  const hiddenInfoText = createInfoText(hiddenShortDescription, hiddenLongDescription, true);
  const hiddenShortDescriptionIsAvailable = !isEmpty(hiddenShortDescription) && hiddenShortDescription !== ' ';
  const hiddenLongDescriptionIsAvailable = !isEmpty(hiddenLongDescription) && hiddenLongDescription !== ' ';
  const displayCollapsible = hiddenInfoText && (hiddenShortDescriptionIsAvailable || hiddenLongDescriptionIsAvailable);

  return (
    <InfoBox
      isDesktop={isDesktop}
      heading={translate('designs.designerInfo.designHeading')}
      imageUrl={designThumbnailURL}
      imageAlt={designName ? designName : ''}
      infoText={infoText}
      boxClass={classNames('b-design-info-box', boxClassExtension)}
      hasNoRadius={true}
      moreInfo={isDesktop && displayCollapsible && localeIsEnglish &&
      <Collapsible
        title={translate('designs.designerInfo.more')}
        secondaryTitle={translate('designs.designerInfo.less')}
        sizeClass='x-trigger-extra-small'
        collapsibleOpen={collapsibleOpen}
        classname='more-details'
        triggerType='plus'
        content={hiddenInfoText}
        isDesignerInfo={true}
        collapsibleShowTrigger={collapsibleShowTrigger}
      />}
    />
  );
};

export default DesignInfoBox;
