import PropTypes from 'prop-types';

// TODO SP-6816: Remove once all files are using interfaces below
export const basicAfterpayAvailabilityShape = {
  available: PropTypes.bool.isRequired,
  currencyValid: PropTypes.bool,
  eligible: PropTypes.bool,
  config: PropTypes.shape({
    currency: PropTypes.string,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
  })
};

export const detailedAfterpayPaymentShape = {
  ...basicAfterpayAvailabilityShape,
  priceValid: PropTypes.bool,
  installments: PropTypes.shape({
    all: PropTypes.arrayOf(PropTypes.number),
    summary: PropTypes.number
  })
};

export interface BasicAfterpayAvailabilityTrue {
  available: true;
  currencyValid: boolean;
  eligible: boolean;
  config: {
    currency: string;
    maxValue: number;
    minValue: number;
  };
}

export interface BasicAfterpayAvailabilityFalse {
  available: false;
}

export type BasicAfterpayAvailability =
  | BasicAfterpayAvailabilityTrue
  | BasicAfterpayAvailabilityFalse;

export interface PdpAfterpayTrue extends BasicAfterpayAvailabilityTrue {
  showAfterpay: boolean;
  installments: {
    summary: number;
    showInstallments: boolean;
  };
}

export interface CheckoutAfterpayTrue extends BasicAfterpayAvailabilityTrue {
  priceValid: boolean;
  installments: {
    all: [number, number, number, number];
    summary: number;
  };
}

export function isPdpTrue(input: PdpAfterpayTrue | CheckoutAfterpayTrue): input is PdpAfterpayTrue {
  return (input as PdpAfterpayTrue).installments.showInstallments !== undefined;
}
