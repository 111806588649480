import * as codes from './Codes';

export const FABRIC_DETAIL_IMAGE_URL_PREFIX = 'fabric_detail';
export const STOCK_ITEM_IMAGE_URL_PREFIX = 'stock_items';
export const DEFAULT_USER_IMAGE = 'generic_no_avatar.jpeg';
export const SOLIDS_IMAGE_URL_PREFIX = 'solids';

export const getOrderItemThumbnailUrls = (imagesHost, assetsHost) => ({
  STOCK_ITEM_IMAGE_URL: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}`,
  [codes.STOCK_ITEM_SPOONFLOWER_HANDBOOK]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_SPOONFLOWER_HANDBOOK-1-100.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_SEW_BOOK]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_SPOONFLOWER_SEW_BOOK-1-100.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_SPOONFLOWER_BOOK_BUNDLE-1-100.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_SPOONFLOWER_SAMPLE_PACK-1-100.jpg`,
  [codes.STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_PETAL_SOLID_SAMPLE_PACK-4-100.jpg`,
  [codes.STOCK_ITEM_WELCOME_PACK_ARTISTS]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_WELCOME_PACK_ARTISTS-1-100.jpg`,
  [codes.STOCK_ITEM_WELCOME_PACK_MAKERS]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_WELCOME_PACK_MAKERS-1-100.jpg`,
  [codes.STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK]: `${imagesHost}/${STOCK_ITEM_IMAGE_URL_PREFIX}/STOCK_ITEM_GRASSCLOTH_SAMPLE_PACK-1-100.jpg`,

  [codes.SERVICE_ITEM_TYPE_GIFT_CERTIFICATE]: `${assetsHost}/item_icons/gift_certificate.png`,
  [codes.SERVICE_ITEM_TYPE_PRO_PURCHASE]: `${assetsHost}/item_icons/pro_purchase.png`,
  [codes.SERVICE_ITEM_TYPE_COLOR_GUIDE]: `${assetsHost}/item_icons/color_map.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_COLOR_GUIDE]: `${assetsHost}/item_icons/color_guide.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_PERENNIAL_SATEEN_GRAND_COLOR_MAP]: `${assetsHost}/item_icons/color_map.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_LONGLEAF_SATEEN_GRAND_COLOR_MAP]: `${assetsHost}/item_icons/color_map.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_COLOR_GUIDE]: `${assetsHost}/item_icons/color_guide.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_GRAND_SATEEN_COLOR_MAP]: `${assetsHost}/item_icons/color_map.jpg`,

  [codes.KIT_SUB_TYPE_FAT_QUARTER_BUNDLE]: `${assetsHost}/item_icons/bundle.jpg`,
  [codes.KIT_SUB_TYPE_SMALL_SAMPLER]: `${assetsHost}/item_icons/sampler.jpg`,

  // TODO: SP-4079 these appear to be redundant by now and should be removed (once verified that they are not used anymore in any part of Baerlauch)
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_HANDBOOK]: `${assetsHost}/item_icons/handbook.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_SEW_BOOK]: `${assetsHost}/item_icons/quick_sew_project_book.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_BOOK_BUNDLE]: `${assetsHost}/item_icons/book_bundle.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_SAMPLE_PACK]: `${assetsHost}/item_icons/swatch_book.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_S]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_M]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_L]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XL]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.PRODUCT_SUB_TYPE_SPOONFLOWER_TSHIRT_SIZE_XXL]: `${assetsHost}/item_icons/tshirt.jpg`,

  [codes.STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_S]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_M]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_L]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XL]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.STOCK_ITEM_SPOONFLOWER_TSHIRT_SIZE_XXL]: `${assetsHost}/item_icons/tshirt.jpg`,
  [codes.SERVICE_ITEM_TYPE_WELCOME_PACK]: `${assetsHost}/item_icons/welcome_pack.jpg`,
});

// Solids - SP-7561 or SP-7508 Temporary Assets for SolidsNavbar
export const getSolidUrls = (imagesHost) => ({
  SOLIDS_IMAGE_URL: `${imagesHost}/${SOLIDS_IMAGE_URL_PREFIX}`,
  SOLIDS_SHOP_COLLECTIONS_TOP: `${imagesHost}/${SOLIDS_IMAGE_URL_PREFIX}/GENERIC/SOLIDS_SHOP_COLLECTIONS_TOP.jpg`,
  SOLIDS_SHOP_COLLECTIONS_BOTTOM: `${imagesHost}/${SOLIDS_IMAGE_URL_PREFIX}/GENERIC/SOLIDS_SHOP_COLLECTIONS_BOTTOM.jpg`,
  SOLIDS_SHOP_FABRICS: `${imagesHost}/${SOLIDS_IMAGE_URL_PREFIX}/GENERIC/SOLIDS_SHOP_FABRICS.jpg`,
});
