import Translate from 'i18n-react';
import React from 'react';

import {ApiHostsEnum} from '../../../../entities/pageSetup/apiHosts';
import useApiHost from '../../../../entities/pageSetup/apiHosts/useApiHost';
import {translate} from '../../../../services';
import {Locale} from '../../../../shapes/locale';
import InfoBox from '../../../Reusable/InfoBox/InfoBox';
import LinkWrapper from '../../../Reusable/LinkWrapper/LinkWrapper';


export interface HappinessGuaranteeInfoBoxProps {
  infoText: JSX.Element;
  isDesktop: boolean;
  locale: Locale;
}

const HappinessGuaranteeInfoBox = ({infoText, isDesktop, locale}: HappinessGuaranteeInfoBoxProps): JSX.Element => {
  const imagesHost = useApiHost(ApiHostsEnum.images);

  return (
    <InfoBox
      isDesktop={isDesktop}
      boxClass='b-happiness-guarantee-info-box'
      heading={translate('fabric.happinessGuarantee.title')}
      infoText={infoText}
      imageUrl={`${imagesHost}/happiness-guarantee.svg`}
      imageAlt={translate('fabric.happinessGuarantee.title')}
      moreInfo={<LinkWrapper
        className='learn-more-link'
        hrefValue={Translate.translate(`xurls.happinessGuarantee.${locale}`)?.toString() || ''} // TODO: SP-8362 Refactor or extend translate utility.
        title={translate('fabric.happinessGuarantee.title')}
        contents={translate('fabric.happinessGuarantee.learnMore')}
      />}
    />
  );
};

export default HappinessGuaranteeInfoBox;
