import React from 'react';

import {FABRIC_CODE_COOKIE_NAME} from '../../../../constants/Cookies';
import {translate} from '../../../../services';
import {setSecureCookie} from '../../../../utils/cookies';
import LazyImage from '../../../Reusable/LazyImage/LazyImage';
import browserHistoryWrapper from '../../../Reusable/LinkWrapper/browserHistoryWrapper';
import './_available-on-products.scss';


export interface AvailableOnProductsProps {
  crossSellBarItems?: {[key: string]: {imageSrc: string; name: string; link: string}[]};
  selectedSubstrate: string;
}

export interface CrossSellBarItem {
  imageSrc: string;
  link: string;
  name: string;
}

const AvailableOnProducts = ({crossSellBarItems, selectedSubstrate}: AvailableOnProductsProps): JSX.Element => {
  const handleOnClickEvent = (item: CrossSellBarItem) => () => {
    setSecureCookie(FABRIC_CODE_COOKIE_NAME, selectedSubstrate);
    browserHistoryWrapper.push(item.link);
  };

  return (
    <React.Fragment>{ // needed to wrap this for type checking to work https://stackoverflow.com/a/49371829/3396324
      crossSellBarItems?.[selectedSubstrate]?.map((item: CrossSellBarItem, index: number) => (
        <section className='b-available-on-products' key={index}>
          <button
            type='button'
            aria-label={translate('designs.productsOverview.goToSpoonflower')}
            title={translate('designs.productsOverview.goToSpoonflower')}
            className='link-button x-non-full-width-mobile-button'
            onClick={handleOnClickEvent(item)}>
            <LazyImage
              className='product-image'
              src={item.imageSrc}
              alt={item.name}
              width='220'
              height='165'/>
          </button>
          <h2 className='product-wrapper'>
            <button
              type='button'
              className='fabric-title-btn x-non-full-width-mobile-button'
              onClick={handleOnClickEvent(item)}>
              <em className='fabric-title'>{item.name}</em>
              <span className='link'>{translate('search.substrateOptions.preposition.fabric')} {translate(`fabricShop.${selectedSubstrate}.name`)}</span>
            </button>
          </h2>
        </section>))
    }</React.Fragment>
  );
};

export default AvailableOnProducts;
